import { Button,FormControl, FormErrorMessage,Input,InputGroup,InputLeftElement,InputRightElement,Text,useToast } from "@chakra-ui/react"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"
import { loginUser } from "src/view-models/LoginViewModel"
import { LockIcon, EmailIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons"
import { useState } from "react"
import { Link } from "react-router-dom"

const formSchema = z.object({
    email: z.string()
    .refine((value) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value), {
      message: 'Adresse e-mail invalide',
    }),
    password: z.string().min(8, {
      message: "Le mot de passe doit contenir au moins 8 caractères",
    }),
  })
  
  type LoginFormInputs = {
    email: string;
    password: string;
  };

const LoginForm = () => {
  const toast = useToast()

  const [isVisible,setIsVisible] = useState(false)

  const { register, handleSubmit,formState: { errors, isSubmitting ,isValid},} = useForm<LoginFormInputs>({
    resolver: zodResolver(formSchema),
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    await loginUser(values,toast)
  }

  const switchVisble = () => {
    setIsVisible((prev)=>!prev)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
            <FormControl isInvalid={!!errors.email}>
              <InputGroup>
                <InputLeftElement pointerEvents='none'>
                  <EmailIcon color='gray.300' />
                </InputLeftElement>
                <Input
                  disabled={isSubmitting}
                  rounded='20px'
                  type='email'
                  size='md'
                  id='email'
                  placeholder='email@federation.sa'
                  {...register("email")}
                />
              </InputGroup>
              <FormErrorMessage>
                {errors.email && errors.email.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.password}>
              <InputGroup>
                <InputLeftElement pointerEvents='none'>
                  <LockIcon color='gray.300' />
                </InputLeftElement>
                <Input
                  type={isVisible ? 'text' : 'password'}
                  disabled={isSubmitting}
                  rounded='20px'
                  size='md'
                  id='password'
                  placeholder='Votre mot de passe'
                  {...register('password')}
                />
                <InputRightElement cursor='pointer' onClick={switchVisble}>
                  {isVisible ? (
                    <ViewOffIcon color='gray.300'  fontSize={20} />
                  ) : (
                    <ViewIcon color='gray.300'  fontSize={20} />
                  )}
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage>
                {errors.password && errors.password.message}
              </FormErrorMessage>
            </FormControl>
            <Link aria-disabled={isSubmitting} to='/auth/forget-password'>
              <Text mt={2}  color='blue.500' cursor='pointer' className="underline w-fit" >Mot de passe oublié ?</Text>
            </Link>
            
            <Button
              type='submit'
              onClick={handleSubmit(onSubmit)}
              isLoading={isSubmitting}
              p='4'
              rounded='20px'
              mt='6'
              w='100%'
              colorScheme='blue'
              isDisabled={!!errors.email || !!errors.password || !isValid}
            >
              Se connecter
            </Button>
          </form>
  )
}

export default LoginForm