import { Box,Grid } from "@chakra-ui/react"
import BasicInfo from "./BasicInfo"

type Props = {
    data:any
}

const AdminCard = ({data}:Props) => {
  return (
    <Box px={4} py={2} backgroundColor='white' rounded={10} boxShadow='md' >
        <Grid templateColumns={{base:'repeat(1,1fr)',md:'repeat(2,1fr)',lg:'repeat(3,1fr)'}} gap={4} >
            <BasicInfo info1="Nom" info2={data?.first_name} />
            <BasicInfo info1="Prénom " info2={data?.last_name} />
            <BasicInfo info1="Téléphone" info2={data?.phone} />
            <BasicInfo info1="Email" info2={data?.email} />
        </Grid>
    </Box>
  )
}

export default AdminCard