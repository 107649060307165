import axios, { AxiosError, AxiosResponse } from "axios"

class HttpClient {

    private readonly access_token = JSON.parse(localStorage.getItem("access_token")!)
    public static version ='/api/v1'
    public static host =`${process.env.REACT_APP_HOST}`

    public get postConfig(){
        return {
            headers:{
                'Accept':'application/json',
                'Content-Type':'application/json',
            },
        }
    }

    public get postConfigWithLogin(){
        return {
            headers:{
                'Accept':'application/json',
                'Content-Type':'application/json',
                'Authorization':`Bearer ${this.access_token}`
            },
        }
    }

    public static post = async (apiUrl:string,body:any) => {
        const httpClient = new HttpClient()
        const fullUrl = HttpClient.host + HttpClient.version + apiUrl

        return await axios.post(fullUrl,body,httpClient.postConfig)
            .then((response : AxiosResponse)=>response)
            .catch((error : AxiosError)=>{
                return {
                    status:error?.response?.status,
                    message:error?.response?.data
                }
            }
            )
    }

    public static postWithLogin = async (apiUrl:string,body:any) => {

        const httpClient = new HttpClient()
        const fullUrl = HttpClient.host + HttpClient.version + apiUrl

        return await axios.post(fullUrl,body,httpClient.postConfigWithLogin)
            .then((response : AxiosResponse)=>response)
            .catch((error : AxiosError)=>{
                return {
                    status:error?.response?.status,
                    message:error?.response?.data
                }
            }
            )
    }

    public static getWithLogin = async (apiUrl:string) => {
        const httpClient = new HttpClient()
        const fullUrl = HttpClient.host + HttpClient.version + apiUrl

        return await axios.get(fullUrl,httpClient.postConfigWithLogin)
            .then((response : AxiosResponse)=>response)
            .catch((error : AxiosError)=>{
                return {
                    status:error?.response?.status,
                    message:error?.response?.data
                }
            }
            )
    }

    public static deleteWithLogin = async (apiUrl:string) => {

        const httpClient = new HttpClient()
        const fullUrl = HttpClient.host + HttpClient.version + apiUrl

        return await axios.delete(fullUrl,httpClient.postConfigWithLogin)
            .then((response : AxiosResponse)=>response)
            .catch((error : AxiosError)=>{
                return {
                    status:error?.response?.status,
                    message:error?.response?.data
                }
            }
            )
    }
}

export default HttpClient