import { Button,Flex,FormControl, FormErrorMessage,FormLabel,Grid,HStack,Input,useToast } from "@chakra-ui/react"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"
import { useNavigate } from "react-router-dom"
import Select from 'react-select';

import { createAdmin } from "src/view-models/Admin"
import { useAppSelector } from "src/redux/hooks"
import { useEffect, useState } from "react"
import { getRoles } from "src/view-models/Role"
import { useDispatch } from "react-redux"
import { RiLoader2Fill } from "react-icons/ri"

const formSchema = z.object({
    phone : z.string().min(1,{
        message:"Le téléphone est obligatoire",
    }),
    first_name : z.string().min(1,{
        message:"Le nom est obligatoire",
    }),
    last_name : z.string().min(1,{
        message:"Le prénom est obligatoire",
    }),
    email: z.string()
    .refine((value) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value), {
      message: 'Adresse e-mail invalide',
    }),
    password : z.string().min(1,{
        message:"Le mot de passe est obligatoire",
    }),
    c_password : z.string().min(1,{
        message:"Le confirm mot de passe est obligatoire",
    }),
    roles : z.array(z.string()).min(1,{
      message:"Vous devez sélectionner au moins 1 organisation"
  }),
  })
  
  type NewAdmFormInputs = {
    phone : string
    email : string
    first_name : string
    last_name: string
    password:string
    c_password :string
    roles:string[]
  };

const NewAdmForm = () => {
  const dispatch = useDispatch()
  const toast = useToast()
  const {roles} = useAppSelector((state)=>state.role)
  const [rolesOptions,setRolesOptions] = useState<any[]>([])
  const [isLoading,setIsLaoding] = useState(true)
  const navigation = useNavigate()
  const { register,setValue,handleSubmit,formState: { errors, isSubmitting},} = useForm<NewAdmFormInputs>({
    resolver: zodResolver(formSchema),
  });
  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    const theValues = {...values,active:1}
    const response : any = await createAdmin(theValues,toast)
    if(response.status === true){
      navigation("/admin/admins")
    }else{
      return null
    }
  }

  useEffect(()=>{
    const options = roles?.map((o)=>({
      value:o.name,
      label:o.display_name
    }))
    setRolesOptions(options)
  },[roles])

  useEffect(() => {
    const fetchRoles = async () => {
     await getRoles(dispatch)
     setIsLaoding(false)
    }
    fetchRoles()
  }, [])


  const handleChange2 = (options : any) => {
    const values = options?.map((o:any)=>(o.value.toString()))
    setValue('roles',values)
  }

  return (
    <form  className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
        <Grid templateColumns={{base:'repeat(1,1fr)',md:'repeat(2,1fr)',lg:'repeat(3,1fr)'}} gap={4} >
            <FormControl isRequired isInvalid={!!errors.first_name}>
              <FormLabel>Nom</FormLabel>
              <Input
                boxShadow='sm'
                disabled={isSubmitting}
                rounded='5px'
                type='text'
                size='md'
                id='first_name'
                placeholder='first_name'
                {...register("first_name")}
              />
              <FormErrorMessage>
                {errors.first_name && errors.first_name.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!errors.last_name}>
              <FormLabel >Prénom</FormLabel>
              <Input
                boxShadow='sm'
                disabled={isSubmitting}
                rounded='5px'
                type='text'
                size='md'
                id='last_name'
                placeholder='last_name'
                {...register("last_name")}
              />
              <FormErrorMessage>
                {errors.last_name && errors.last_name.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!errors.phone}>
              <FormLabel>Téléphone</FormLabel>
              <Input
                boxShadow='sm'
                disabled={isSubmitting}
                rounded='5px'
                type='tel'
                size='md'
                id='phone'
                placeholder='Téléphone'
                {...register("phone")}
              />
              <FormErrorMessage>
                {errors.phone && errors.phone.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!errors.email}>
              <FormLabel>Email</FormLabel>
              <Input
                boxShadow='sm'
                disabled={isSubmitting}
                rounded='5px'
                type='email'
                size='md'
                id='email'
                placeholder='email'
                {...register("email")}
              />
              <FormErrorMessage>
                {errors.email && errors.email.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isRequired  isInvalid={!!errors.password}>
                <FormLabel>Mot de passe</FormLabel>
                <Input
                boxShadow='sm'
                disabled={isSubmitting}
                rounded='5px'
                type='text'
                size='md'
                id='id'
                placeholder='password'
                {...register("password")}
                />
            <FormErrorMessage>
                {errors.password && errors.password.message}
            </FormErrorMessage>
            </FormControl>
            <FormControl isRequired  isInvalid={!!errors.c_password}>
                <FormLabel>Confirmer mot de passe</FormLabel>
                <Input
                boxShadow='sm'
                disabled={isSubmitting}
                rounded='5px'
                type='text'
                size='md'
                id='id'
                placeholder='c_password'
                {...register("c_password")}
                />
                <FormErrorMessage>
                    {errors.c_password && errors.c_password.message}
                </FormErrorMessage>
            </FormControl>
            {isLoading ? (
              <Flex justifyContent='center' alignItems='center' >
                <RiLoader2Fill size={30} className='animate-spin' />
              </Flex>
            ) : (
              <FormControl  isRequired isInvalid={!!errors.roles}>
                <FormLabel>roles</FormLabel>
                <Select
                    placeholder='Sélectionner' 
                    isMulti
                    onChange={handleChange2}
                    options={rolesOptions}  
                  />
                  <FormErrorMessage>
                      {errors.roles && errors.roles.message}
                  </FormErrorMessage>
              </FormControl>
            )}
            </Grid>
        <HStack mt={10}>
          <Button
            type='submit'
            onClick={handleSubmit(onSubmit)}
            isLoading={isSubmitting}
            p='4'
            rounded='20px'
            mx='auto'           
            w='40%'
            colorScheme='blue'
            variant='solid'           
          >
            Ajouter
            </Button>
        </HStack>                 
          </form>
  )
}

export default NewAdmForm