import { Box, Button, Flex, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { getPractitioner } from 'src/view-models/Practitioner'
import DoctorCard from './components/DoctorCard'
import EditDoctor from './components/EditDoctor'
import Loading from 'src/components/loading/Loading'
import NotFound from 'src/components/not-found/NotFound'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'

const DoctorDetails = () => {
  const dispatch = useAppDispatch()
  const {pathname} = useLocation()
  const {practitioner:doctor} = useAppSelector((state)=>state.practitioner)
  const id = pathname.split("/")[3]
  const [isEdit,setIdEdit] = useState(false)
  const [isLoading,setIsLoading] = useState(true)

  useEffect(()=>{
    const fetchDoctor = async () => {
      await getPractitioner(id,dispatch)
      setIsLoading(false)
    }
    fetchDoctor()
  },[id,isEdit])

  if(isLoading){
    return <Loading />
  }

  if(!doctor && !isLoading){
    return <NotFound page='doctor' />
  }

  return (
    <Box px={4} py={2} rounded={4}>
      <Flex mb={4} flexDirection={{base:'column',md:'row'}} alignItems={{base:'flex-start',md:'center'}} justifyContent='space-between' gap={4} >
        <Box>
          <Text color='#4E5D78' fontWeight={700} fontSize={30} >{doctor?.first_name}</Text>
          <Text color='#4E5D78'>Sur cette page, vous pouvez gérer tous les aspects concernant {doctor?.name} Médecins </Text>
        </Box>
        <Button colorScheme='blue' onClick={()=>setIdEdit((prev)=>!prev)} >{isEdit ? 'Annuler la modification':'Modifier le médecins'}</Button>
      </Flex>
      {isEdit ? (
        <Box backgroundColor='white' p={8} boxShadow='md' >
          <EditDoctor  setIsEdit={setIdEdit} data={doctor} />
        </Box>
      ) : (
        <DoctorCard data={doctor} />
      )}
    </Box>
  )
}

export default DoctorDetails