import { motion} from "framer-motion";
import { useInView } from 'react-intersection-observer'
import { useEffect, useState } from 'react';
import { Box } from "@chakra-ui/react";
const MotionBox = motion(Box);

const Wrapper = ({children}:{children:any}) => {
    const { ref, inView } = useInView({threshold:0.1});
    const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (inView) {
      setIsVisible(true);
    }
  }, [inView]);

  return (
    <MotionBox
    ref={ref} 
    initial={{ scale: 0.8, opacity: 0 }}
    animate={{ scale: inView ? 1 : 0.8, opacity: inView ? 1 : 0 }}
    transition={{ duration: 1 }}
    >
        {children}

    </MotionBox>
  )
}

export default Wrapper