import { Flex, Text, VStack } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import Loading from 'src/components/loading/Loading'
import { useAppSelector } from 'src/redux/hooks'

const Welcome = () => {
    const {profile,isLoading} = useAppSelector((state)=>state.profile)

    if(!profile && isLoading){
        return <Loading />
    }

  return (
    <Flex w='100%' h='90vh' justifyContent='center' alignItems='center' >
        <VStack>
            <Text>Welcome <span className='font-bold' >{profile?.first_name}</span> to Admin dashboard!</Text>
            <Link className='underline' to='/admin/dashboard' >Accédez au Dashboard pour gérer users!</Link>
        </VStack>
    </Flex>
  )
}

export default Welcome