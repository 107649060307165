import { Box, Button, Flex, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { getAdmin } from 'src/view-models/Admin'
import AdminCard from './components/AdminCard'
import EditAdmin from './components/EditAdmin'
import Loading from 'src/components/loading/Loading'
import NotFound from 'src/components/not-found/NotFound'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'

const AdminDetails = () => {
  const dispatch = useAppDispatch()
  const {pathname} = useLocation()
  const id = pathname.split("/")[3]
  const {admin} = useAppSelector((state)=>state.admin)
  const [isEdit,setIdEdit] = useState(false)
  const [isLoading,setIsLoading] = useState(true)

  useEffect(()=>{
    const fetchDoctor = async () => {
      await getAdmin(id,dispatch)
      setIsLoading(false)
    }
    fetchDoctor()
  },[id,isEdit])

  if(isLoading){
    return <Loading />
  }

  if(!admin && !isLoading){
    return <NotFound page='admin' />
  }

  return (
    <Box px={4} py={2} rounded={4}>
      <Flex mb={4} flexDirection={{base:'column',md:'row'}} alignItems={{base:'flex-start',md:'center'}} justifyContent='space-between' gap={4} >
        <Box>
          <Text color='#4E5D78' fontWeight={700} fontSize={30} >{admin?.name}</Text>
          <Text color='#4E5D78'>Sur cette page, vous pouvez gérer tous les aspects concernant <span className='font-bold' >{admin?.last_name}</span> Admin</Text>
        </Box>
        <Button colorScheme='blue' onClick={()=>setIdEdit((prev)=>!prev)} >{isEdit ? 'Annuler la modification':"Modifier l'Admin"}</Button>
      </Flex>
      {isEdit ? (
        <Box backgroundColor='white' p={8} boxShadow='md' >
            <EditAdmin  setIsEdit={setIdEdit}  data={admin} />
        </Box>
      ) : (
        <AdminCard data={admin} />
      )}
    </Box>
  )
}

export default AdminDetails