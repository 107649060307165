import { Box, Button,HStack } from '@chakra-ui/react'
import { useState } from 'react'
import ProfileCard from './components/ProfileCard'
import Loading from 'src/components/loading/Loading'
import {  useAppSelector } from 'src/redux/hooks'
import EditPassword from './components/EditPassword'

const Settings = () => {
  const [isEditPassword,setIdEditPassword] = useState(false)
  const {profile,isLoading} = useAppSelector((state)=>state.profile)

  if(!profile && isLoading){
    return <Loading />
  }

  return (
    <Box px={4} py={2} rounded={4}>
      <HStack>
        <Button mb={4} colorScheme='blue' onClick={()=>setIdEditPassword((prev)=>!prev)} >{isEditPassword ? 'Annuler la modification':'modifier le mot de passe'}</Button>
      </HStack>
        { isEditPassword ? (
          <EditPassword setIsEditPassword={setIdEditPassword} />
        ) : (
          <ProfileCard data={profile} />
        )}
    </Box>
  )
}

export default Settings