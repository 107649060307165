import { Button,FormControl, FormErrorMessage,FormLabel,Input,useToast } from "@chakra-ui/react"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"
import { useNavigate } from "react-router-dom"
import { updatePofile } from "src/view-models/Admin"
import { useAppDispatch } from "src/redux/hooks"

const formSchema = z.object({
    phone : z.string().min(1,{
        message:"Le Téléphone est obligatoire",
    }),
    first_name : z.string().min(1,{
        message:"Le nom est obligatoire",
    }),
    last_name : z.string().min(1,{
        message:"Le prénom est obligatoire",
    }),
    email: z.string()
    .refine((value) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value), {
      message: 'Adresse e-mail invalide',
    }),
  })
  
  type EditProfileInputs = {
    phone : string
    email : string
    first_name : string
    last_name: string
  };

const EditProfile = ({data}:any) => {
  const dispatch = useAppDispatch()
  const toast = useToast()
  const navigation = useNavigate()
  const id = data?.id
  const { register,handleSubmit,formState: { errors, isSubmitting ,isValid},} = useForm<EditProfileInputs>({
    resolver: zodResolver(formSchema),
    defaultValues:{
      email:data?.email,
      first_name:data?.first_name,
      last_name:data?.last_name,
      phone:data?.phone
    }
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    const theValues = {...values,active:1}
    const response : any = await updatePofile(id,theValues,toast,dispatch)
    if(response.status === true){
      navigation("/admin/admins")
    }else{
      return null
    }
  }

  return (
    <form  className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
            <FormControl isRequired isInvalid={!!errors.first_name}>
              <FormLabel>Nom</FormLabel>
              <Input
                boxShadow='sm'
                disabled={isSubmitting}
                rounded='5px'
                type='text'
                size='md'
                id='first_name'
                placeholder='first_name'
                {...register("first_name")}
              />
              <FormErrorMessage>
                {errors.first_name && errors.first_name.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!errors.last_name}>
              <FormLabel >Prénom</FormLabel>
              <Input
                boxShadow='sm'
                disabled={isSubmitting}
                rounded='5px'
                type='text'
                size='md'
                id='last_name'
                placeholder='last_name'
                {...register("last_name")}
              />
              <FormErrorMessage>
                {errors.last_name && errors.last_name.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!errors.phone}>
              <FormLabel>Téléphone</FormLabel>
              <Input
                boxShadow='sm'
                disabled={isSubmitting}
                rounded='5px'
                type='tel'
                size='md'
                id='phone'
                placeholder='Téléphone'
                {...register("phone")}
              />
              <FormErrorMessage>
                {errors.phone && errors.phone.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!errors.email}>
              <FormLabel>Email</FormLabel>
              <Input
                boxShadow='sm'
                disabled={isSubmitting}
                rounded='5px'
                type='email'
                size='md'
                id='email'
                placeholder='email'
                {...register("email")}
              />
              <FormErrorMessage>
                {errors.email && errors.email.message}
              </FormErrorMessage>
            </FormControl>
        <Button
          type='submit'
          onClick={handleSubmit(onSubmit)}
          isLoading={isSubmitting}
          p='4'
          rounded='20px'
          mt='6'
          w='100%'
          colorScheme='blue'
          variant='solid'
          isDisabled={!isValid}
          
        >
          Modifier
          </Button>                 
          </form>
  )
}

export default EditProfile