import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react'
import { closeMedecinQuestions } from 'src/redux/features/question/questionSlice'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import QuestionsForm from './QuestionsForm'

const MedecinFormModal = () => {
    const dispatch = useAppDispatch()
    const {isQuestionsMedecin,medecinCategoriesQuestions:questions,medecinCategory:category} = useAppSelector((state)=>state.question)

    const handleClose = () => {
        dispatch(closeMedecinQuestions())
    }
    
    
  return (
    <Modal size='4xl' scrollBehavior='inside' isOpen={isQuestionsMedecin && questions?.length >0} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent h='35rem' >
          <ModalHeader>
            <Text textAlign='center' >{questions?.length === 0 ? 'There is no questions!' : category}</Text>
            <Text color='#4E5D78' fontSize={13} textAlign='center' >Commencer le {category} pour compléter la ficher CPA</Text>
          </ModalHeader>
          <ModalCloseButton />
          
            <ModalBody h='100%'>
              {questions?.length > 0 ? (
                <QuestionsForm questions={questions} />
                ):null}
            </ModalBody>
          
        </ModalContent>
      </Modal>
  )
}

export default MedecinFormModal