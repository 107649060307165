import HttpClient from "src/services/httpclient"

export type OrganizationInfo = {
    type : string
    identifier : string
    organization_type : string
    numero_siret : string
    phone : string
    name : string
    country : string
    state : string
    city: string
    email: string
    postalCode : string
    active:number
}


class Organization {
    public static createOrganization = (organizationInfos:OrganizationInfo) => {
        return HttpClient.postWithLogin('/organization/store',organizationInfos)
    }
    public static updateOrganization = (id:string,organizationInfos:OrganizationInfo) => {
        return HttpClient.postWithLogin(`/organization/update/${id}`,organizationInfos)
    }
    public static getOrganizations = () => {
        return HttpClient.getWithLogin('/organization/list?per_page=all')
    }
    public static getOrganization = (id : string) => {
        return HttpClient.getWithLogin(`/organization/show/${id}`)
    }
    public static getArchivedOrganizations = () => {
        return HttpClient.getWithLogin('/organization/archive/list?per_page=all')
    }
    public static deleteOrganization = (id:string) => {
        return HttpClient.deleteWithLogin(`/organization/delete/${id}`)
    }
}

export default Organization