import { Box, Button, Checkbox, Flex, FormControl, FormLabel,Input, Radio, Stack, VStack, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "src/redux/hooks";
import { modefyMedecinQuestionsToPatientAndSagefemme } from "src/view-models/Question";

const QuestionsForm = ({category,questions,setIsEdit}:{category : string,questions:any,setIsEdit:any}) => {
    const dispatch = useAppDispatch()
    const toast = useToast()
    const {id} = useParams()
    const [responses,setRespones] = useState<any>([])
    const [isLoading,setIsLoading] = useState(false)
    const handleChange = (e:any,index:number,type:string,answerId:number | null,questionId:number,multipleType:string|null) => {
        const theQuestion = questions?.find((q:any)=>multipleType === 'gestite' ? q?.question_id === questionId - 1 : multipleType === 'parite' ? q?.question_id === questionId - 2 : q?.question_id === questionId)
        const question = responses?.find((r:any)=>r.id === (multipleType === 'gestite' ? questionId - 1 : multipleType === 'parite' ? questionId -2 : questionId))
        if(type === 'comment'){
          setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,comment:e.target.value} : item))
        }
        else if(type === 'input'){
          setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,answers:[{id:null,answer:e.target.value}]} : item))
     }else if(type === 'radio'){
      setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,answers:[{id:answerId,answer:e.target.value}]} : item))
     }else if (type === 'checkbox'){
      if(e.target.checked){
        setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,answers:[...item.answers,{id:answerId,answer:e.target.value}]} : item))
      }else{
        setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,answers:item.answers.filter((a:{id:number,answer:string})=>a.answer!== e.target.value)} : item))
      }
     }
     else if (type ='multipleText'){
      if(multipleType === 'gestite'){
          const newResponses = responses?.map((item:any,index1:number)=>index1===index ? {...item,answers:e.target.value ? item.answers?.map((i:any)=>i?.id === questionId ? {id:questionId ,answer:e.target.value}:i) : item.answers.filter((a:any)=>a.id !== questionId)} : item)
          const isThereResponse = newResponses?.some((item:any,index1:number)=>index1===index && item.answers?.some((i:any)=>i.id === questionId))        
          if(!isThereResponse){
            if(e.target.value !== ''){
              setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,answers:[...item.answers,{id:questionId,answer:e.target.value}]} : item))
            }else {
              setRespones(newResponses)
            }
          }else{
            setRespones(newResponses)
          }  
      }else if(multipleType === 'parite'){
          const newResponses = responses?.map((item:any,index1:number)=>index1===index ? {...item,answers:e.target.value ? item.answers?.map((i:any)=>i?.id === questionId ? {id:questionId ,answer:e.target.value}:i) : item.answers.filter((a:any)=>a.id !== questionId)} : item)
          const isThereResponse = newResponses?.some((item:any,index1:number)=>index1===index && item.answers?.some((i:any)=>i.id === questionId))        
          if(!isThereResponse){
            if(e.target.value !== ''){
              setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,answers:[...item.answers,{id:questionId,answer:e.target.value}]} : item))
            }else {
              setRespones(newResponses)
            }
          }else{
            setRespones(newResponses)
          }      
      }
    }
    
    if(!multipleType){
      if(theQuestion?.flag_reponse){
        if(type !== "checkbox"){
          if(theQuestion?.operator_flag_reponse === ">"){
            if(Number(e.target.value) > theQuestion?.condition_flag_reponse){
              setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,flag_response:theQuestion?.flag_reponse} : item))
            }else {
              setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,flag_response:null} : item))
            }
          }else if (theQuestion?.operator_flag_reponse === "<"){
            if(Number(e.target.value) < theQuestion?.condition_flag_reponse){
              setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,flag_response:theQuestion?.flag_reponse} : item))
            }else {
              setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,flag_response:null} : item))
            }
          }else{
            if(e.target.value == theQuestion?.condition_flag_reponse){
              setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,flag_response:theQuestion?.flag_reponse} : item))
            }else {
              setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,flag_response:null} : item))
            }
          }
        }else {
          if(e.target.checked){
            if(theQuestion?.operator_flag_reponse === ">"){
              if(question?.answers?.length + 1 > theQuestion?.condition_flag_reponse){
                setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,flag_response:theQuestion?.flag_reponse} : item))
              }else {
                setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,flag_response:null} : item))
              }
            }else if (theQuestion?.operator_flag_reponse === "<"){
              if(question?.answers?.length + 1 < theQuestion?.condition_flag_reponse){
                setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,flag_response:theQuestion?.flag_reponse} : item))
              }else {
                setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,flag_response:null} : item))
              }
            }else{
              if(question?.answers?.length + 1 == theQuestion?.condition_flag_reponse){
                setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,flag_response:theQuestion?.flag_reponse} : item))
              }else {
                setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,flag_response:null} : item))
              }
            }
          }else {
            if(theQuestion?.operator_flag_reponse === ">"){
              if(question?.answers?.length - 1 > theQuestion?.condition_flag_reponse){
                setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,flag_response:theQuestion?.flag_reponse} : item))
              }else {
                setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,flag_response:null} : item))
              }
            }else if (theQuestion?.operator_flag_reponse === "<"){
              if(question?.answers?.length - 1 < theQuestion?.condition_flag_reponse){
                setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,flag_response:theQuestion?.flag_reponse} : item))
              }else {
                setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,flag_response:null} : item))
              }
            }else{
              if(question?.answers?.length - 1 == theQuestion?.condition_flag_reponse){
                setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,flag_response:theQuestion?.flag_reponse} : item))
              }else {
                setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,flag_response:null} : item))
              }
            }
          }
        }
      }
      if(theQuestion?.bilan_bilogique){
        if(type !== "checkbox"){
          if(theQuestion?.operator_bilan_bilogique === ">"){
            if(Number(e.target.value) > theQuestion?.condition_bilan_bilogique){
              setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,bilan_bilogique:theQuestion?.bilan_bilogique} : item))
            }else {
              setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,bilan_bilogique:null} : item))
            }
          }else if (theQuestion?.operator_bilan_bilogique === "<"){
            if(Number(e.target.value) < theQuestion?.condition_bilan_bilogique){
              setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,bilan_bilogique:theQuestion?.bilan_bilogique} : item))
            }else {
              setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,bilan_bilogique:null} : item))
            }
          }else{
            if(e.target.value == theQuestion?.condition_bilan_bilogique){
              setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,bilan_bilogique:theQuestion?.bilan_bilogique} : item))
            }else {
              setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,bilan_bilogique:null} : item))
            }
          }
        }else {
          if(e.target.checked){
            if(theQuestion?.operator_bilan_bilogique === ">"){
              if(question?.answers?.length + 1 > theQuestion?.condition_bilan_bilogique){
                setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,bilan_bilogique:theQuestion?.bilan_bilogique} : item))
              }else {
                setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,bilan_bilogique:null} : item))
              }
            }else if (theQuestion?.operator_bilan_bilogique === "<"){
              if(question?.answers?.length + 1 < theQuestion?.condition_bilan_bilogique){
                setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,bilan_bilogique:theQuestion?.bilan_bilogique} : item))
              }else {
                setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,bilan_bilogique:null} : item))
              }
            }else{
              if(question?.answers?.length + 1 == theQuestion?.condition_bilan_bilogique){
                setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,bilan_bilogique:theQuestion?.bilan_bilogique} : item))
              }else {
                setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,bilan_bilogique:null} : item))
              }
            }
          }else {
            if(theQuestion?.operator_bilan_bilogique === ">"){
              if(question?.answers?.length - 1 > theQuestion?.condition_bilan_bilogique){
                setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,bilan_bilogique:theQuestion?.bilan_bilogique} : item))
              }else {
                setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,bilan_bilogique:null} : item))
              }
            }else if (theQuestion?.operator_bilan_bilogique === "<"){
              if(question?.answers?.length - 1 < theQuestion?.condition_bilan_bilogique){
                setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,bilan_bilogique:theQuestion?.bilan_bilogique} : item))
              }else {
                setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,bilan_bilogique:null} : item))
              }
            }else{
              if(question?.answers?.length - 1 == theQuestion?.condition_bilan_bilogique){
                setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,bilan_bilogique:theQuestion?.bilan_bilogique} : item))
              }else {
                setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,bilan_bilogique:null} : item))
              }
            }
          }
        }
      }
    }else{
      if(theQuestion.flag_reponse && ((questionId === 5 && multipleType === 'parite') ||(questionId === 7 && multipleType === 'gestite') || (questionId === 6 && multipleType ==='gestite') || (questionId === 7 && multipleType === 'parite') )){
        if(theQuestion.operator_flag_reponse === ">"){
          if( (questionId === 5 && multipleType === 'parite')  && Number(e.target.value)  > Number(theQuestion.condition_flag_reponse)){
            setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,flag_response:theQuestion.flag_reponse} : item))
          } else if((questionId === 6  && multipleType === 'gestite' && Number(e.target.value)>140) || (questionId === 7 && multipleType === 'parite' && Number(e.target.value)>90)){
            setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,flag_response:theQuestion.flag_reponse} : item))
          }
          else {
            setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,flag_response:null} : item))
          }
        }else if (theQuestion.operator_flag_reponse === "<"){
          if( (questionId === 7 && multipleType === 'gestite') && Number(e.target.value) < Number(theQuestion.condition_flag_reponse)){
            setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,flag_response:theQuestion.flag_reponse} : item))
          }else {
            setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,flag_response:null} : item))
          }
        }else{
          if(((questionId === 5 && multipleType === 'parite') || (questionId === 7 && multipleType === 'gestite')) && e.target.value == theQuestion.condition_flag_reponse){
            setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,flag_response:theQuestion.flag_reponse} : item))
          }else {
            setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,flag_response:null} : item))
          }
        }
      }
      if(theQuestion.bilan_bilogique  && questionId === 7 && multipleType === 'gestite'){

        if(theQuestion.operator_bilan_bilogique === ">"){
          if( Number(e.target.value) > Number(theQuestion.condition_bilan_bilogique)){
            setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,bilan_bilogique:theQuestion.bilan_bilogique} : item))
          }else {
            setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,bilan_bilogique:null} : item))
          }
        }else if (theQuestion.operator_bilan_bilogique === "<"){
          if((questionId === 7 && multipleType === 'gestite') && Number(e.target.value) < Number(theQuestion.condition_bilan_bilogique)){
            setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,bilan_bilogique:theQuestion.bilan_bilogique} : item))
          }else {
            setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,bilan_bilogique:null} : item))
          }
        }else{
          if(e.target.value == theQuestion.condition_bilan_bilogique){
            setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,bilan_bilogique:theQuestion.bilan_bilogique} : item))
          }else {
            setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,bilan_bilogique:null} : item))
          }
        }
      }
    }

    }
       const handleSubmit = async (e:any) => {
            e.preventDefault()
            
            let responses1 =[]
            
            if(category === "Antécédent médicaux préalables à la grossesse"){
              responses1 = responses?.map((r:any)=>r?.id === 35 ? 
              (r?.answers?.length  > 0 ? 
                r?.answers?.find((a:any)=>a?.answer?.toLowerCase() === 'non') ? {...r,flag_response:null,type_flag:[]} : 
                r?.answers?.length === 2 ? {...r,flag_response:'Diabète préalable à la grossesse,Dysthyroïdie préalable à la grossesse'} 
                : {...r,flag_response:r?.answers[0]?.answer?.includes('diabète') ? 'Diabète préalable à la grossesse' : 'Dysthyroïdie préalable à la grossesse'}
                : {...r,flag_response:null,type_flag:[]})
            : r
            )
            }

            if(category === "Antécédents obstétricaux"){
              responses1 = responses?.map((r:any)=>r?.id === 11 ? 
      (r?.answers?.length  > 0 ? 
        r?.answers?.find((a:any)=>a?.answer?.toLowerCase() === 'non') ? {...r,flag_response:null,type_flag:[]} : 
        r?.answers?.length === 2 ? {...r,flag_response:`${r?.answers[0]?.answer},${r?.answers[1]?.answer}`} 
        : {...r,flag_response:r?.answers[0]?.answer}
        : {...r,flag_response:null,type_flag:[]})
    : r
    )
            }
   


            const theValues = {
                id,
                query:category === "Antécédent médicaux préalables à la grossesse" ? responses1 : category === "Antécédents obstétricaux"  ? responses1 : responses
            }
            try {
                setIsLoading(true)
                await modefyMedecinQuestionsToPatientAndSagefemme(theValues,id!,setIsEdit,toast,dispatch)
            } catch (error) {
            }finally{
                setIsLoading(false)
            }
       }    

    useEffect(()=>{
        const values = questions?.map((q:any)=>({
          id:q.question_id,
          value:q.medecin_text,
          type:q.type,
          answers:q.answer,
          type_flag:q?.type_flag?.map((t:any)=>t.type),
          comment:q?.comment,
          flag_response:
        q?.operator_flag_reponse === ">" ? q?.question_id === 3 ? (q?.answer[0]?.id === 5 ? Number(q.answer[0]?.answer) > q?.condition_flag_reponse ? q.flag_reponse : null : Number(q.answer[1]?.answer) > q?.condition_flag_reponse ? q.flag_reponse : null)
         :
         q?.question_id === 5 ? (q?.answer[0]?.id === 6 ? (Number(q?.answer[0]?.answer) > 140 || Number(q?.answer[1]?.answer) > 90 ? q?.flag_reponse : null) : (Number(q?.answer[0]?.answer) > 90 || Number(q?.answer[1]?.answer) > 140 ? q?.flag_reponse : null) ) :
        (Number(q.answer[0]?.answer) > q?.condition_flag_reponse ? q.flag_reponse : null) :
        q?.operator_flag_reponse === "<" ?  q?.question_id === 6 ? (q?.answer[0]?.id === 7 ? Number(q.answer[0]?.answer) < q?.condition_flag_reponse ? q.flag_reponse : null : Number(q.answer[1]?.answer) < q?.condition_flag_reponse ? q.flag_reponse : null) :
        (Number(q.answer[0]?.answer) < q?.condition_flag_reponse ? q.flag_reponse : null) :
        q?.operator_flag_reponse === "=" ? (q.answer[0]?.answer == q?.condition_flag_reponse ? q.flag_reponse : null ) : null,
      bilan_bilogique:
        q?.operator_bilan_bilogique === ">" ? Number(q.answer[0]?.answer) > q?.condition_bilan_bilogique ? q.bilan_bilogique : null :
        q?.operator_bilan_bilogique === "<" ? q?.question_id === 6 ? (q?.answer[0]?.id === 7 ? Number(q.answer[0]?.answer) < q?.condition_bilan_bilogique ? q.bilan_bilogique : null : Number(q.answer[1]?.answer) < q?.condition_bilan_bilogique ? q.bilan_bilogique : null) :  (Number(q.answer[0]?.answer) < q?.condition_bilan_bilogique ? q.bilan_bilogique : null ) : 
        q?.operator_bilan_bilogique === "=" ? (q.answer[0]?.answer == q?.condition_bilan_bilogique ? q.bilan_bilogique : null ) : null,
        }))
        setRespones(values)
      },[questions])
  return (
    <Box>
        {questions?.map((question:any,index:number)=>(
            <VStack  key={index} alignItems='start' >
                <FormControl  >
                  <VStack  alignItems='start' >
                    <FormLabel mt={2}>{question.medecin_text}</FormLabel>
                    <Box w='100%' >
                    {question.type === 'text' || question.type === 'number' || question.type === 'date' ? (
                    <Input
                    boxShadow='sm'
                    isDisabled={isLoading}
                    rounded='5px'
                    type={question.type === 'date' ? 'date' : question.type === 'text' ? 'text' : 'number'}
                    size='md'
                    value={responses[index]?.answers[0]?.answer ? responses[index]?.answers[0]?.answer : ''}
                    placeholder='Reponse...'
                    onChange={(e)=>handleChange(e,index,'input',null,question?.question_id,null)}
                    />
                    ) : question?.type === 'multipletText' ? (
                      <Stack alignItems='center' direction={{base:'column',sm:'row'}} >
                        <Box flex={1} >
                          <FormLabel>{question?.question_id === 3 ? 'Gestite' : question?.question_id === 5 ? 'Pression artérielle systolique (mmHG)' : 'Plaquettes (G/L)'}</FormLabel>
                          <Input
                            boxShadow='sm'
                            rounded='5px'
                            type='number'
                            size='md'
                            value={responses[index]?.answers[0]?.id===question?.question_id + 1 ? responses[index]?.answers[0]?.answer :responses[index]?.answers[1]?.id===question?.question_id + 1 ? responses[index]?.answers[1]?.answer : '' }
                            placeholder='Reponse...'
                            onChange={(e)=>handleChange(e,index,'multipleText',null,question?.question_id + 1,'gestite')}
                          />
                        </Box>
                        <Box flex={1} >
                          <FormLabel>{question?.question_id === 3 ? 'Parité' : question?.question_id === 5 ? 'Pression artérielle diastolique (mmHG)' : 'Hémoglobine (g/dL)'}</FormLabel>
                          <Input
                            boxShadow='sm'
                            rounded='5px'
                            type='number'
                            size='md'
                            value={responses[index]?.answers[0]?.id===question?.question_id + 2 ? responses[index]?.answers[0]?.answer :responses[index]?.answers[1]?.id===question?.question_id + 2 ? responses[index]?.answers[1]?.answer : '' }
                            placeholder='Reponse...'
                            onChange={(e)=>handleChange(e,index,'multipleText',null,question?.question_id + 2,'parite')}
                          />
                        </Box>
                      </Stack>
                  ): question.type === 'selectbox' ? (
                        <Flex  gap={2} flexWrap='wrap'>
                        {question?.possibles_answers?.map((answer : {id:number,answer:string},index2 : any)=>(
                            <Flex  key={index2} justifyContent='center' flexWrap='wrap' gap={2} >
                                <Radio isChecked={responses[index]?.answers[0]?.answer === answer.answer} onChange={(e)=>handleChange(e,index,'radio',answer.id,question?.question_id,null)} value={answer.answer} key={index2} >
                                {answer.answer}
                            </Radio>
                            </Flex>
                        ))}
                        </Flex>
                    ):(
                        <Flex alignItems='center' gap={4} flexWrap='wrap' >
                        {question.possibles_answers?.map((answer:{id:number,answer:string},index2:number)=>(
                            <Checkbox  colorScheme='blue' isChecked={responses[index]?.answers?.some((s:any)=>s.answer === answer.answer)}  onChange={(e)=>handleChange(e,index,'checkbox',answer.id,question?.question_id,null)} value={answer.answer} key={index2} >{answer.answer}</Checkbox>
                        ))}
                        </Flex>
                    )
                    }
                    </Box>

                </VStack>
            </FormControl>
            {question?.to_comented === 1 ? (
                <FormControl flex={1}>
                  <Input
                  isDisabled={isLoading}
                  rounded='8px'
                  type='text'
                  size='md'
                  placeholder='commenter ici'
                  value={responses[index]?.comment ? responses[index]?.comment : ''}
                  onChange={(e)=>handleChange(e,index,'comment',null,question?.question_id,null)}
                  />
            </FormControl>
                // <CommentInput comment={question?.comment} questionId={question?.question_id}  />
            ) : null}

            </VStack>

            ))}
            <Button mt={4} isDisabled={isLoading} type='button'  px={10} colorScheme='blue'  onClick={handleSubmit} >Modifier</Button>

    </Box>
  )
}

export default QuestionsForm