import { Box, Container, Grid, Image, Text, VStack } from "@chakra-ui/react"
import { items } from "./constants"
import { motion} from "framer-motion";
import { useInView } from 'react-intersection-observer'
import { useEffect, useState } from 'react';
import SugguestionComp from "./Item";
const MotionBox = motion(Box);

const Suggestion = () => {
    const { ref, inView } = useInView({threshold:0.1});
    const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (inView) {
      setIsVisible(true);
    }
  }, [inView]);
  return (
    <Box pt={'8rem'} pb={'4rem'} bg={'white'} id="#functions">
        <Container maxW={{base:'90%',lg:'80%'}} w={{ lg: '1200px' }}>
        <MotionBox
            ref={ref} 
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: inView ? 1 : 0.8, opacity: inView ? 1 : 0 }}
            transition={{ duration: 0.5 }}
        >
            <Text fontFamily={'mybody'} mb={16} color={'#235EE8'} fontWeight={700} fontSize={22} textAlign={'center'} textTransform={'uppercase'} >Les bénéfices de Focus Care</Text>
        </MotionBox>
            <Grid templateColumns={{base:'repeat(1,1fr)',md:'repeat(2,1fr)',lg:'repeat(4,1fr)'}} gap={8} >
                {items?.map((item,index)=>(
                    <Box key={index} >
                        <SugguestionComp index={index} img={item.img} title={item.title} text={item.text} />
                    </Box>
                ))}
            </Grid>
        </Container>
    </Box>
  )
}

export default Suggestion