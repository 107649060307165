import { Box, Button, Flex} from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { openValidCPA } from 'src/redux/features/practitioner/practitionerSlice'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'

const ValiderCPA = () => {
  const {id} = useParams()

  const dispatch = useAppDispatch()
  const {isValidateCPA} = useAppSelector((state)=>state.question)
  
  const handleOpenValid = () => {
    dispatch(openValidCPA())
  }

  const handleGetPDF = async () => {
    window.open(`${process.env.REACT_APP_HOST}/api/medecin/patient/get-pdf-cpa/${id}`,'_blank')
  }

  return (
    <Box backgroundColor='white' w='full' bottom={10} py={6} px={4} zIndex={100} my={4} boxShadow='md' >
        <Flex justifyContent='flex-end' gap={4} >
            {isValidateCPA === 1 ? (
              <Button  px={10} variant='solid'  colorScheme='blue' onClick={handleGetPDF} >Exporter la fiche de synthèse au format PDF</Button>
            ) : (
              <Button  px={10} variant='solid'  colorScheme='blue' onClick={handleOpenValid} >Valider CPA</Button>
            )}
        </Flex>
    </Box>
  )
}

export default ValiderCPA