import { Box } from '@chakra-ui/react'
import NewDocForm from './components/NewAssistantForm'

const NewAssistant = () => {

  return (
    <Box px={4} py={2} rounded={4}>
        <Box backgroundColor='white' p={8} boxShadow='md' >
          <NewDocForm />
        </Box>
    </Box>
  )
}

export default NewAssistant