import { Button,FormControl, FormErrorMessage,FormLabel,Input,useToast } from "@chakra-ui/react"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"
import { updatePassword } from "src/view-models/Profile"


const formSchema = z.object({
    password : z.string().min(8,{
        message:"Le password est obligatoire",
    }),
    c_password : z.string().min(8,{
        message:"Le c_password est obligatoire",
    }),
  })
  
  type EditPasswordInputs = {
    password : string
    c_password : string
  };

const EditPassword = ({setIsEditPassword}:any) => {  const toast = useToast()
  const { register,handleSubmit,formState: { errors, isSubmitting ,isValid},} = useForm<EditPasswordInputs>({
    resolver: zodResolver(formSchema),
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    if(values.password !== values.c_password){
        return toast({
            description: `Les mots de passe doivent être les mêmes !`,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position:'top'
          })
    }
    const response : any = await updatePassword('sage-femme',values,toast)
    if(response.status === true){
        setIsEditPassword(null)
    }else{
      return null
    }
  }

  return (
    <form  className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
            <FormControl isRequired isInvalid={!!errors.password}>
              <FormLabel>password</FormLabel>
              <Input
                boxShadow='sm'
                disabled={isSubmitting}
                rounded='5px'
                type='text'
                size='md'
                id='password'
                placeholder='password'
                {...register("password")}
              />
              <FormErrorMessage>
                {errors.password && errors.password.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!errors.c_password}>
              <FormLabel >Confirm Password</FormLabel>
              <Input
                boxShadow='sm'
                disabled={isSubmitting}
                rounded='5px'
                type='text'
                size='md'
                id='c_password'
                placeholder='c_password'
                {...register("c_password")}
              />
              <FormErrorMessage>
                {errors.c_password && errors.c_password.message}
              </FormErrorMessage>
            </FormControl>
        <Button
          type='submit'
          onClick={handleSubmit(onSubmit)}
          isLoading={isSubmitting}
          backgroundColor='blue.500'
          p='4'
          rounded='20px'
          mt='6'
          w='100%'
          colorScheme='blue'
          variant='solid'
          isDisabled={!isValid}
        >
          Edit
          </Button>                 
          </form>
  )
}

export default EditPassword