import { ArrowForwardIcon } from '@chakra-ui/icons'
import { Button,} from '@chakra-ui/react'
import { setPatientStep } from 'src/redux/features/question/questionSlice'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'

const Categorie = ({categorie,index}:{categorie:any,index:number}) => {
    const dispatch = useAppDispatch()
    const {patientStep} = useAppSelector((state)=>state.question)
    
    const setStep = () => {
        dispatch(setPatientStep({
            step:index,
            category:categorie.categ
        }))
    }

  return (
    <Button 
        rightIcon={<ArrowForwardIcon />}
        colorScheme={patientStep === index ? 'blue' : 'gray'}
        isDisabled={patientStep < index}
        variant='ghost'
        onClick={setStep}
    >
        {categorie.categ}
    </Button>
  )
}

export default Categorie