import { Box, Button, Flex,Text } from '@chakra-ui/react'
import { TbEdit } from "react-icons/tb";
import PersoInfoCard from './PersoInfoCard';
import { useState } from 'react';
import InfoForm from './InfoForm';
import { useAppSelector } from 'src/redux/hooks';

const PersoInfo = ({patient,accouchement}:{patient:any,accouchement:any}) => {
  const [isEdit,setIsEdit] = useState(false)
  const {isValidateCPA} = useAppSelector((state)=>state.question)
  const questions = [accouchement,patient?.date_acouchement,patient?.gestite,patient?.poid_actuelle,patient?.poid_avant_grosesse,patient?.taille]
  const handleEdit = () => {
    setIsEdit((prev)=>!prev)
  }

  return (
    <Box bg='white' rounded={10} boxShadow='sm' px={8} py={4} >
        <Flex alignItems={{base:'start',sm:'center'}} gap={2} flexDirection={{base:'column',sm:'row'}} justifyContent='space-between' >
            <Text fontWeight='semibold' fontSize={18} >Informations personnelles</Text>
            {isValidateCPA === 0 ? (
              <Button colorScheme='blue' rightIcon={<TbEdit />} onClick={handleEdit} >
                {isEdit ? "Annuler la modification" : "Modifier"}
            </Button>
            ) : null}
        </Flex>
        {isEdit ? (
          <InfoForm questions={questions} setIsEdit={setIsEdit} />
        ) : (
          <PersoInfoCard  patient={patient} accouchement={accouchement}/>
        )}
    </Box>
  )
}

export default PersoInfo