import { Button, HStack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useToast } from '@chakra-ui/react'
import  { useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import {  closeValidCPA } from 'src/redux/features/practitioner/practitionerSlice'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import { validateCPA } from 'src/view-models/Patient'

const ValiderModal = () => {
  const {id} = useParams()
    const dispatch = useAppDispatch()
    const toast = useToast()
    const {isOpenValidCPA} = useAppSelector((state)=>state.practitioner)
    const finalRef = useRef(null)
    const [isLoading,setIsLoading] = useState(false)

    const handleValidCPA = async () => {
      try {
        setIsLoading(true)
        const res = await validateCPA(id!,toast)
        if(res.status === true){
          window.location.reload()
        }
      } catch (error) {
        return null
      }finally{
        setIsLoading(false)
      }
    }

    const handleClose = () => {
        dispatch(closeValidCPA())
    }

  return (
    <Modal finalFocusRef={finalRef} isOpen={isOpenValidCPA} onClose={handleClose}>
        <ModalOverlay 
        />
        <ModalContent>
          <ModalHeader>Valider CPA</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Êtes-vous sûr de vouloir valider cela. Note que vous ne pouvez pas valider à nouveau</Text>
          </ModalBody>
          <ModalFooter >
            <HStack gap={4} >
              <Button isDisabled={isLoading} colorScheme='blue' mr={3} onClick={handleClose}>Fermer</Button>
              <Button isLoading={isLoading} colorScheme='green' onClick={handleValidCPA}>Valider</Button>

            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
  )
}

export default ValiderModal