import { Box,Button,Flex,Text } from "@chakra-ui/react"
import ConclusionCard from "./ConclusionCard"
import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { getConclusionCPA } from "src/view-models/Patient"
import { RiLoader2Fill } from "react-icons/ri"
import { useAppDispatch, useAppSelector } from "src/redux/hooks"
import { TbEdit } from "react-icons/tb"
import ConclusionForm from "./ConclusionForm"

const Consclusion = () => {
  const dispatch = useAppDispatch()
  const {id} = useParams()
  const [isLoading,setIsLoading] = useState(true)
  const {conclusionCPA,isValidateCPA} = useAppSelector((state)=>state.question)
  const [isEdit,setIsEdit] = useState(false)

  useEffect(()=>{
    const fetchConclusionCPA = async () => {
      try {
         await getConclusionCPA(id!,dispatch)
      } catch (error) {
        
      }finally {
        setIsLoading(false)
      }
    }
    fetchConclusionCPA()
  },[])


  const handleEdit = () => {
    setIsEdit((prev)=>!prev)
  }

  if(isLoading){
    return (
      <Box bg='white' rounded={10} boxShadow='sm' px={8} py={4}>
        <Text fontWeight='bold' fontSize={18} >Conclusion CPA</Text>
        <Flex w='100%' my={20}  justifyContent='center' alignItems='center' >
              <RiLoader2Fill size={30} className='animate-spin' />
        </Flex>
      </Box>
    )
  }



  if(!isLoading && !conclusionCPA){
    return (
      <Box bg='white' rounded={10} boxShadow='sm' px={8} py={4}>
        <Text fontWeight='bold'  fontSize={18} >Conclusion CPA</Text>
        <Flex w='100%' my={20}  justifyContent='center' alignItems='center' >
              <Text>Rien à afficher!</Text>
        </Flex>
      </Box>
    )
  }


  return (
    <Box bg='white' rounded={10} boxShadow='sm' px={8} py={4}>
        <Flex alignItems={{base:'start',sm:'center'}} gap={2} flexDirection={{base:'column',sm:'row'}} justifyContent='space-between' >
            <Text fontWeight='bold'  fontSize={18} >Conclusion CPA</Text>
            {isValidateCPA === 0 ? (
              <Button colorScheme='blue' rightIcon={<TbEdit />} onClick={handleEdit} >
                {isEdit ? "Annuler la modification" : "Modifier"}
            </Button>
            ) : null}
        </Flex>
        {isEdit ? (
          <Box py={4}>
            <ConclusionForm cpa={conclusionCPA} setIsEdit={()=>setIsEdit(false)} />
          </Box>
        ) : (
          <ConclusionCard cpa={conclusionCPA} />
        )}
    </Box>
    
  )
}

export default Consclusion