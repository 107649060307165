import HttpClient from "src/services/httpclient"

export type PractitionerInfo = {
    identifier : string
    phone : string
    email : string
    country : string
    state : string
    city : string
    postalCode : string
    name : string
    family: string
    gender: string
    birthdate: string
    qualification : string
    identifiant_national : string
    organizations:string[]
    password?:string
    c_password? :string
    active:number
    roles:string[]
}


class Practitioner {
    public static createPractitioner = (PractitionerInfos:PractitionerInfo) => {
        return HttpClient.postWithLogin('/practitioner/store',PractitionerInfos)
    }
    public static updatePractitioner = (id:string,PractitionerInfos:PractitionerInfo) => {
        return HttpClient.postWithLogin(`/practitioner/update/${id}`,PractitionerInfos)
    }
    public static getPractitioners = () => {
        return HttpClient.getWithLogin('/practitioner/list?per_page=all')
    }
    public static getPractitioner = (id : string) => {
        return HttpClient.getWithLogin(`/practitioner/show/${id}`)
    }
    public static getPractitionersByRole = (role : string) => {
        return HttpClient.getWithLogin(`/practitioner/practitioner-by-role?per_page=all&role=${role}`)
    }
    public static archivedPractioner = (role : string) => {
        return HttpClient.getWithLogin(`/practitioner/archive/practitioner-by-role?per_page=all&role=${role}`)
    }
    public static getProfile = (role:string) => {
        return HttpClient.getWithLogin(`/${role}/profile`)
    }
    public static deletePractitioner = (id:string) => {
        return HttpClient.deleteWithLogin(`/practitioner/delete/${id}`)
    }
    public static filterSagefemme = (values:any) => {
        return HttpClient.getWithLogin(`/sage-femme/filtre-sage-femme?${values}`)
    }
    public static filterMedecin = (values:any) => {
        return HttpClient.getWithLogin(`/medecin/filtre-medecin?${values}`)
    }
}

export default Practitioner