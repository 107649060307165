import {configureStore} from '@reduxjs/toolkit'
import questionSlice from './features/question/questionSlice'
import adminSlice from './features/admin/adminSlice'
import practitionerSlice from './features/practitioner/practitionerSlice'
import organizationSlice from './features/organization/organizationSlice'
import patientSlice from './features/patient/patientSlice'
import ProfileSlice from './features/profile/ProfileSlice'
import roleSlice from './features/role/roleSlice'

const store = configureStore(
    {
        reducer:{
            question:questionSlice,
            admin:adminSlice,
            practitioner:practitionerSlice,
            organization:organizationSlice,
            patient:patientSlice,
            profile:ProfileSlice,
            role:roleSlice
        }
    }
)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store