import { Box, Button} from '@chakra-ui/react';

const Dashboards = () => {
  const data = [
    {id:1,nom:"Nom1",age:"1",contact:["contact1@gmail.com","023252"],weeks:1,status:<Button _hover={{bg:'#56E1C3'}} bg={true ? '#56E1C3' : 'red.300'} >{true ? 'Sent':'No sent'}</Button>},
    {id:2,nom:"Nom2",age:"2",contact:["contact1@gmail.com","023252"],weeks:2,status:<Button _hover={{bg:'#56E1C3'}} bg={true ? '#56E1C3' : 'red.300'} >{true ? 'Sent':'No sent'}</Button>},

  ];
  return (
    <Box  px={4} py={2} rounded={4}  >
      Dashboards
    </Box>
)
}

export default Dashboards