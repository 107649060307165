import { Box, Button, Flex, Text } from '@chakra-ui/react'
import  { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { getOrganization } from 'src/view-models/Organization'
import OrganizationCard from './components/OrganizationCard'
import EditOrganization from './components/EditOrganization'
import NotFound from 'src/components/not-found/NotFound'
import Loading from 'src/components/loading/Loading'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'

const OrganizationDetails = () => {
  const dispatch = useAppDispatch()
  const {organization} = useAppSelector((state)=>state.organization)
  const {pathname} = useLocation()
  const id = pathname.split("/")[3]
  const [isEdit,setIdEdit] = useState(false)
  const [isLoading,setIsLoading] = useState(true)

  useEffect(()=>{
    const fetchOrganization = async () => {
      await getOrganization(id,dispatch)
      setIsLoading(false)
    }
    fetchOrganization()
  },[id,isEdit])

  if(isLoading){
    return <Loading />
  }

  if(!organization && !isLoading){
    return <NotFound page='organization' />
  }

  if(organization === null && !isLoading){
    return <Flex justifyContent='center' mt={10} >
      <Text fontWeight='bold' fontSize={20} >Organization does not exists!</Text>
    </Flex>
  }

  return (
    <Box px={4} py={2} rounded={4}>
      <Flex mb={4} flexDirection={{base:'column',md:'row'}} alignItems={{base:'flex-start',md:'center'}} justifyContent='space-between' gap={4} >
        <Box>
          <Text color='#4E5D78' fontWeight={700} fontSize={30} >{organization?.name}</Text>
          <Text color='#4E5D78'>Sur cette page, vous pouvez gérer tous les aspects concernant {organization?.name} Établissement </Text>
        </Box>
        <Button colorScheme='blue' onClick={()=>setIdEdit((prev)=>!prev)} >{isEdit ? 'Annuler la modification':"Modifier l'Établissement"}</Button>
      </Flex>
      {isEdit ? (
        <Box backgroundColor='white' p={8} boxShadow='md' >
          <EditOrganization setIsEdit={setIdEdit} data={organization} />
        </Box>
      ) : (
        <OrganizationCard data={organization} />
      )}
    </Box>
  )
}

export default OrganizationDetails