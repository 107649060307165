import ActionTable from "./components/ActionTable";
import Name from "./components/Name";
import Organizations from "./components/Organizations";
import Status from "./components/Status";

export const colDefs = [
  {
    field:'Nom',
    cellRenderer:Name,
    cellRendererParams: (params : any) => {
      const rowData = params.data;
      return {
        name: `${rowData.name[0]}`,
      };
    },
    filter:true,
  },
  {
    field:'Prénom',
    cellRenderer:Name,
    cellRendererParams: (params : any) => {
      const rowData = params.data;
      return {
        name: `${rowData.family}`,
      };
    },
    filter:true,
  },
  { 
    headerName:"E-mail",
    field: "email",
  },
  { 
    headerName:"Téléphone",
    field: "phone" 
  },
  {
    field:'Status',
    cellRenderer:Status,
    filter:true,
  },
  {
    field:'Établissement',
    cellRenderer:Organizations,
    cellRendererParams: (params : any) => {
      const rowData = params.data;
      const organizations = [rowData?.organization?.name]
      return {
        organizations: organizations,
      };
    },
    filter:false,
  },
  {
    field:'Actions',
    cellRenderer:ActionTable,
    cellRendererParams: (params : any) => {
      const rowData = params.data;
      return {
        id: `${rowData.id}`,
        name:`${rowData.name}`
      };
    },
    filter:false,
  },
];