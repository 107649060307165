import { Box, Button, Flex, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { getPractitioner } from 'src/view-models/Practitioner'
import SeigeFemmeCard from './components/SeigeFemmeCard'
import EditSeigeFemme from './components/EditSeigeFemme'
import Loading from 'src/components/loading/Loading'
import NotFound from 'src/components/not-found/NotFound'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'

const SeigeFemmeDetails = () => {
  const dispatch = useAppDispatch()
  const {pathname} = useLocation()
  const {practitioner:seige_femme} = useAppSelector((state)=>state.practitioner)
  const id = pathname.split("/")[3]
  const [isEdit,setIdEdit] = useState(false)
  const [isLoading,setIsLoading] = useState(true)

  useEffect(()=>{
    const fetchSeigeFemme = async () => {
      await getPractitioner(id,dispatch)
      setIsLoading(false)
    }
    fetchSeigeFemme()
  },[id,isEdit])

  if(isLoading){
    return <Loading />
  }

  if(!seige_femme && !isLoading){
    return <NotFound page='doctor' />
  }

  return (
    <Box px={4} py={2} rounded={4}>
      <Flex mb={4} flexDirection={{base:'column',md:'row'}} alignItems={{base:'flex-start',md:'center'}} justifyContent='space-between' gap={4} >
        <Box>
          <Text color='#4E5D78' fontWeight={700} fontSize={30} >{seige_femme?.first_name}</Text>
          <Text color='#4E5D78'>Sur cette page, vous pouvez gérer tous les aspects concernant {seige_femme?.name} Sage-femme </Text>
        </Box>
        <Button colorScheme='blue' onClick={()=>setIdEdit((prev)=>!prev)} >{isEdit ? 'Annuler la modification':'Modifier la sagefemme'}</Button>
      </Flex>
      {isEdit ? (
        <Box backgroundColor='white' p={8} boxShadow='md' >
          <EditSeigeFemme  setIsEdit={setIdEdit} data={seige_femme} />
        </Box>
      ) : (
        <SeigeFemmeCard data={seige_femme} />
      )}
    </Box>
  )
}

export default SeigeFemmeDetails