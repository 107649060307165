import { Button, FormControl, FormErrorMessage, Textarea, useToast } from '@chakra-ui/react'

import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"
import { commentSagefemme } from 'src/view-models/Patient'
import { useParams } from 'react-router-dom'
import { useAppSelector } from 'src/redux/hooks'
import { useEffect, useRef, useState } from 'react'

const formSchema = z.object({
  comment: z.string().min(1, {
    message: "Le commentaire doit contenir au moins 1 caractères",
  }),
})

type CommentFormInputs = {
  comment: string;
};


const CommentaireForm = () => {
  const commentRef = useRef<any>(null);
  const {id} = useParams()
  const toast = useToast()
  const [isInForm,setIsInForm] = useState(false)
  const [isCommenting,setIsCommenting] = useState(false)
  const {commentSagefemme:commentOfSagefemme} = useAppSelector((state)=>state.question)
  const { register, handleSubmit,formState: { errors, isSubmitting},getValues} = useForm<CommentFormInputs>({
    resolver: zodResolver(formSchema),
    defaultValues:{
      comment:commentOfSagefemme || ''
    }
  });

  useEffect(() => {
    async function handleClickOutside(event:any) {
      
      if (commentRef.current && !commentRef.current.contains(event.target) && isInForm) {
        try {
          setIsCommenting(true)
          const values = {
            comment:getValues('comment'),
            id
          }
          await commentSagefemme(values,toast)
          setIsInForm(false)
        } catch (error) {
          
        }finally{
          setIsCommenting(false)
        }
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [commentRef,isInForm]);


  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    const theValues = {...values,id}
    await commentSagefemme(theValues,toast)
  }

  const HandleSetInForm = () => {
    setIsInForm(true)
  }


  return (
    <form onSubmit={handleSubmit(onSubmit)} className='space-y-4 mt-4' >
          <FormControl onClick={HandleSetInForm} ref={commentRef} isInvalid={!!errors.comment}>
                <Textarea
                  isDisabled={isSubmitting || isCommenting}
                  rounded='20px'
                  id='comment'
                  rows={10}
                  placeholder='Commenter ici'
                  {...register("comment")}
                />
              <FormErrorMessage>
                {errors.comment && errors.comment.message}
              </FormErrorMessage>
            </FormControl>
          <Button type='submit' isDisabled={isSubmitting || isCommenting} colorScheme='blue' w='100%'>Ajouter</Button>
        
        </form>
  )
}

export default CommentaireForm