import { Box,Text } from '@chakra-ui/react'

import QuestionForm from './QuestionForm'
import {useParams } from 'react-router-dom'

const Questions = ({isLoading}:{isLoading:boolean}) => {
    const {id} = useParams()

  return (
    <Box flex={4} >
        <Box backgroundColor='white' boxShadow='md' rounded={10} p={4} >
          <Text borderBottom='1px' pb={2} borderBottomColor='E6E6E6' fontSize={20} fontWeight='bold' >Questionnaire</Text>
            <QuestionForm id={id!} />
        </Box>
    </Box>
  )
}

export default Questions