import { Box, Button, Container, Flex, Image, Stack, Text, VStack } from "@chakra-ui/react"
import heroImg from '../../../../assets/hero-img.webp'

const Hero = () => {

    const goToCalendar = () => {
        window.open('https://calendly.com/focuscareio', '_blank', 'noopener,noreferrer')
    }

    const scrollToSection = (label:string) => {
        const section = document.getElementById(label);
        section!.scrollIntoView({ behavior: 'smooth' });
    };

  return (
    <Box py={'4rem'} id="#home" >
        <Container maxW={{base:'90%',xl:'80%'}} w={{ lg: '1200px' }}>
            <Flex alignItems={'center'} justifyContent={'space-between'} gap={4} flexDirection={{base:'column',lg:'row'}}>
                <VStack flex={1} alignItems={{base:'center',lg:'flex-start'}} gap={4} >
                    <Text textTransform={'uppercase'} fontFamily={'mybody'} lineHeight={{lg:'65px'}} textAlign={{base:'center',lg:'start'}} fontSize={{base:'30px',sm:'35px',md:'45px','2xl':'63px'}} fontWeight={700} color={'#182F45'}><Text as='span' color={'#235EE8'}>Focus Care : <br /></Text>Concentrez-vous sur l'essentiel</Text>
                    <Text textAlign={{base:'center',lg:'start'}} fontSize={'17px'} fontFamily={'moneserrat'} fontWeight={400} color={'#555B64'}>Notre solution numérique de pré-screening révolutionne la prise en charge anesthésique des femmes enceintes en optimisant chaque étape de son parcours</Text>
                    <Stack direction={{base:'column',lg:'row'}} gap={4}>
                        <Button w={'239px'} fontFamily={'mybody'} fontWeight={400} h='60px' color={'white'} bg='#515DEF' _hover={{bg:'#235EE8'}} rounded={'32px'} onClick={goToCalendar} >Programmer une démo</Button>
                        <Button w={'239px'} fontFamily={'mybody'} fontWeight={400} h='60px' color={'black'} bg='white' borderWidth={'1px'} borderColor={'#235EE8'} _hover={{bg:'#515DEF',color:'white'}} rounded={'32px'} onClick={()=>scrollToSection("#functions")} >En savoir plus</Button>
                    </Stack>
                </VStack>
                <Box flex={1}>
                    <Image  src={heroImg}  w={'100%'} objectFit={'fill'} />
                </Box>
            </Flex>
        </Container>
    </Box>
  )
}

export default Hero