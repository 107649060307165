import { Box, Container, Flex, Image, Text, VStack } from "@chakra-ui/react"
import calendar from '../../../../assets/calendar.webp'
import { motion} from "framer-motion";
import { useInView } from 'react-intersection-observer'
import { useEffect, useState } from 'react';
const MotionBox = motion(Box);

const Suggestion = () => {
    const { ref, inView } = useInView();
    const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (inView) {
      setIsVisible(true);
    }
  }, [inView]);
  return (
    <Box py={'4rem'}>
        <Container maxW={{base:'90%',lg:'80%'}} w={{ lg: '1200px' }}>
            <MotionBox
                ref={ref} 
                initial={{ x:100 , opacity: 0 }}
                animate={{ x: inView ? 0 : 100, opacity: inView ? 1 : 0 }}
                transition={{ duration: 1 }}
            >
                <Flex flexDirection={{base:'column',lg:'row'}}  justifyContent={'space-between'} alignItems={'center'} gap={4}>  
                    <VStack flex={1} alignItems={'flex-start'} gap={4} >
                        <Text fontFamily={'jakarta'} lineHeight={'50.14px'} fontSize={{base:'25px',sm:'30px',md:'35px','2xl':'49px'}} fontWeight={800} color={'#182F45'} >Suggestion automatique du bilan biologique recommandé</Text>
                        <Text fontFamily={'moneserrat'} lineHeight={'35px'} fontSize={{base:'18px',sm:'22px',lg:'20px',xl:'22px'}} fontWeight={400} color={'#555B64'}>En s'appuyant sur les dernières recommandations, notre algorithme propose un bilan biologique personnalisé pour chaque patiente, garantissant le respect des bonnes pratiques et des recommandations pour chaque praticien.</Text>
                    </VStack>
                    <Box flex={1}>
                        <Image  w={'100%'} src={calendar} /> 
                    </Box>
                </Flex>
            </MotionBox>
        </Container>
    </Box>
  )
}

export default Suggestion