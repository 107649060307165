import ForgetPasswordModel, { ForgetPasswordInfo, ResetPasswordInfo } from "src/models/ForgetPasswordModal"



export const forgetPassword = async (email:ForgetPasswordInfo,toast:any) => {
    const response : any = await ForgetPasswordModel.forgetPassword(email)
    if(response.status === 200){
        toast({
            position:'top',
            description: `${response.data.message}`,
            status: 'success',
            duration: 3000,
            isClosable: true,
          })
        return true
    }else{
        toast({
            position:'top',
            description: `${response.message.message}`,
            status: 'error',
            duration: 3000,
            isClosable: true,
          })
          return
    }
}

export const resetPassword = async (resetInfo:ResetPasswordInfo,toast:any) => {
    const response : any = await ForgetPasswordModel.resetPassword(resetInfo)
    if(response.status === 200 ) {
        toast({
            position:'top',
            description: `${response.data.message}`,
            status: 'success',
            duration: 3000,
            isClosable: true,
        })
          return true
    }else{
        toast({
            position:'top',
            description: `${response.message.message}`,
            status: 'error',
            duration: 3000,
            isClosable: true,
        })
        return false
    }
}