import Patient from "src/models/Patient"
import Question, { QuestionInfo } from "src/models/Question"
import { setPatient } from "src/redux/features/patient/patientSlice"
import { setCPA } from "src/redux/features/practitioner/practitionerSlice"
import { unsetSeigefemmeValidating, unsetPatientValidating, setPatientQuestions, setSageFemmeQuestions, setMedecinQuestions, closeMedecinQuestions, setConclusionCPA } from "src/redux/features/question/questionSlice"
import { setQuestion, setQuestions } from "src/redux/features/question/questionSlice"

export const createQuestion = async (QuestionInfos:QuestionInfo,toast:any,dispatch:any) => {
    const response : any = await Question.createQuestion(QuestionInfos)
    if(response.status === 201) {
        
        const response2 : any = await Question.getQuestions()
        if(response2.status === 200) {
             dispatch(setQuestions(response2.data.data.data))
        }
        toast({
            description: `Question a été créé avec succès!`,
            status: 'success',
            duration: 3000,
            isClosable: true,
            position:'top'
          })
          return {status:true}
          
    }else if (response.status !==500){
        toast({
            description: `${response?.message?.message}`,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position:'top'
          })
          return {status:false}
    }else {
        toast({
        description: `quelque chose s'est mal passé !`,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position:'top'
      })
      return {status:false}
    }
}

export const updateQuestion = async (id:string,QuestionInfos:any,toast:any,dispatch:any) => {
    const response : any = await Question.updateQuestion(id,QuestionInfos)
    if(response.status === 201) {
        
          const response2 : any = await Question.getQuestions()
        if(response2.status  === 200) {
            dispatch(setQuestions(response2.data.data.data))
        }
        toast({
            description: `Question a été mis à jour avec succès !`,
            status: 'success',
            duration: 3000,
            isClosable: true,
            position:'top'
          })
          return {status:true}
    }else if (response.status !==500){
        toast({
            description: `${response?.message?.message}`,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position:'top'
          })
          return {status:false}
    }else {
        toast({
        description: `quelque chose s'est mal passé !`,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position:'top'
      })
      return {status:false}
    }
}

export const getQuestions = async (dispatch?:any) => {
    const response : any = await Question.getQuestions()
    if(response.status === 200) {
        dispatch(setQuestions(response.data.data.data))
        return {status:true}
    }else {
        return {status:false}
    }
}

export const getPatientQuestions = async (id:string,dispatch?:any) => {
    const response : any = await Question.getPatientQuestions(id)
    if(response.status === 201) {
        dispatch(setPatientQuestions(response.data))
        return {status:true}
    }else if(response.status !== 500) {
        return {status:false,message:response.message.message}
    }
}

export const getSeigeFemmeQuestions = async (id:number,dispatch?:any) => {
    const response : any = await Question.getSeigeFemmeQuestions(id)
    if(response.status === 201) {
        dispatch(setSageFemmeQuestions(response.data))
        return {status:true}
    }else {
        return {status:false}
    }
}

export const getMedecinQuestions = async (id:string,dispatch?:any) => {
    const response : any = await Question.getMedecinQuestions(id)
    if(response.status === 200){
        dispatch(setMedecinQuestions(response?.data?.data))
        return {status:true}
    }else {
        return {status:false}
    }
}

export const getQuestion = async (id:string,dispatch:any) => {
    const response : any = await Question.getQuestion(id)
    if(response.status === 200) {
        dispatch(setQuestion(response.data.data))
        return {status:true}
    }else{
        return {status:false}
    }
}

export const deleteQuestion = async (id:string,toast:any,dispatch:any) => {
    const response : any = await Question.deleteQuestion(id)
    if(response.status === 200) {
        const response2 : any = await Question.getQuestions()
        if(response2.status  === 200) {
            dispatch(setQuestions(response2.data.data.data))
        }
        toast({
            description: `toast has been deleted successfully!`,
            status: 'success',
            duration: 3000,
            isClosable: true,
            position:'top'
          })
          return {status:true}
    }else if (response.status !==500){
        toast({
            description: `${response?.message?.message}`,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position:'top'
          })
          return {status:false}
    }else {
        toast({
        description: `quelque chose s'est mal passé !`,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position:'top'
      })
      return {status:false}
    }
}

export const patietntResponse = async (responses:any,toast:any,dispatch:any) => {
    const response : any = await Question.responsePatientQuestion(responses)
    if(response.status === 201) {
        toast({
            description: `Les réponses ont été soumises ! Merci pour votre temps!`,
            status: 'success',
            duration: 3000,
            isClosable: true,
            position:'top'
          })
          dispatch(unsetPatientValidating())
          return {status:true}
    }else if (response.status !==500){
        toast({
            description: `${response?.message?.message}`,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position:'top'
          })
          return {status:false}
    }else {
        toast({
        description: `quelque chose s'est mal passé !`,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position:'top'
      })
      return {status:false}
    }
}

export const SeigefemmeResponse = async (responses:any,toast:any,dispatch:any) => {
    const response : any = await Question.responseSeigefemmeQuestion(responses)
    if(response.status === 201) {
        toast({
            description: `Les réponses ont été soumises ! Merci pour votre temps!`,
            status: 'success',
            duration: 3000,
            isClosable: true,
            position:'top'
          })
          dispatch(unsetSeigefemmeValidating())
          return {status:true}
    }else if (response.status !==500){
        toast({
            description: `${response?.message?.message}`,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position:'top'
          })
          return {status:false}
    }else {
        toast({
        description: `quelque chose s'est mal passé !`,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position:'top'
      })
      return {status:false}
    }
}

export const MedecinResponse = async (responses:any,toast:any,dispatch:any,patientId:string) => {
    const response : any = await Question.responseMedecinQuestion(responses)
    
    if(response.status === 201) {
        
          dispatch(unsetPatientValidating())
          const res2 : any = await Question.getMedecinQuestions(patientId)
          if(res2.status === 200){
                dispatch(setMedecinQuestions(res2?.data?.data))
            }
            
          dispatch(closeMedecinQuestions())
          toast({
            description: `Les réponses ont été soumises ! Merci pour votre temps!`,
            status: 'success',
            duration: 3000,
            isClosable: true,
            position:'top'
          })
          return {status:true}
    }else if (response.status !==500){
        toast({
            description: `${response?.message?.message}`,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position:'top'
          })
          return {status:false}
    }else {
        toast({
        description: `quelque chose s'est mal passé !`,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position:'top'
      })
      return {status:false}
    }
}


export const CommenterQuestion = async (values:any,toast:any,dispatch:any) => {
    const response : any = await Question.commenterQuestion(values)
    if(response.status === 201) {  
          const res2 : any = await Question.getMedecinQuestions(values.id)
          if(res2.status === 200){
                dispatch(setMedecinQuestions(res2?.data?.data))
            }
            toast({
                description: `Le commentaire a ete rempli!`,
                status: 'info',
                duration: 3000,
                isClosable: true,
                position:'top'
              })
          return {status:true}
    }else if (response.status !==500){
        toast({
            description: `${response?.message?.message}`,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position:'top'
          })
          return {status:false}
    }else {
        toast({
        description: `quelque chose s'est mal passé !`,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position:'top'
      })
      return {status:false}
    }
}



export const modefyMedecinQuestionsToPatientAndSagefemme = async (responses:any,patientId:string,setIsEdit:any,toast:any,dispatch:any) => {
    const response : any = await Question.modefyMedecinQuestionsToPatientAndSagefemme(responses)
    if(response.status === 201) {
        
          const res2 : any = await Question.getMedecinQuestions(responses.id)
          if(res2.status === 200){
                dispatch(setMedecinQuestions(res2?.data?.data))
            }
            const res3 : any = await Patient.conclusionCPA(responses.id)
            if(res3.status === 200) {
                dispatch(setConclusionCPA(res3.data.data))
            }
            const res4 : any = await Patient.getPatient(patientId,'medecin')
            if(res4?.status === 200){
                dispatch(setPatient(res4.data.data))
            }
            const res5:any = await Patient.getCPA(patientId)
            if(res5?.status === 200){
                dispatch(setCPA(res5?.data?.data))
            }
            setIsEdit(false)
            toast({
                description: `Les réponses ont été modifiées!`,
                status: 'success',
                duration: 3000,
                isClosable: true,
                position:'top'
              })
          return {status:true}
    }else if (response.status !==500){
        toast({
            description: `${response?.message?.message}`,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position:'top'
          })
          return {status:false}
    }else {
        toast({
        description: `quelque chose s'est mal passé !`,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position:'top'
      })
      return {status:false}
    }
}