import { createBrowserRouter,Navigate } from "react-router-dom";
import Login from "src/pages/auth/login/Login";
import ForgetPassword from "src/pages/auth/forget-password/ForgetPassword";
import AdminRoutes from "./admin";
import SageFemmeRoutes from "./sagefemme";
import PatientRoutes from "./patient";
import MedecinRoutes from "./medecin";
import HomeRoutes from './home'


const refresh_token = localStorage.getItem("refresh_token")
const access_token = localStorage.getItem("access_token")

const isSignIn = refresh_token && access_token

const router = createBrowserRouter([
    HomeRoutes,
    {
      path: "/auth/login",
      element: !isSignIn ? <Login /> : <Navigate to='/sage-femme/patients' /> ,
    },
    {
      path: "/auth/forget-password",
      element: !isSignIn ? <ForgetPassword /> : <Navigate to='/sage-femme/patients' />  ,
    },
    AdminRoutes,
    SageFemmeRoutes,
    PatientRoutes,
    MedecinRoutes
  ]);

export default router