import { Avatar, Box, Button, HStack, Text, VStack } from '@chakra-ui/react'
import { useEffect, useRef } from 'react';
import { FaUser } from "react-icons/fa6";
import { IoMdLogOut } from "react-icons/io";
import { useLocation, useNavigate } from 'react-router-dom';

type Props = {
    onClose : () => void
    user:any
}

const NavMenu = ({onClose,user}:Props) => {
  const {pathname} = useLocation()
  const role = pathname.split('/')[1]
    const navigate = useNavigate() 
    const popupRef = useRef<HTMLDivElement>(null);

    const goToProfile = () => {
        navigate(`/${role}/settings`)
        onClose()
    }
    
    const handleLogOut = () => {
        localStorage.clear()
        onClose()
        window.location.assign("/auth/login")
    }

  useEffect(() => {
    const handleClickOutside = (event : any) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
 

  }, [popupRef, onClose]);

  return (
    <Box ref={popupRef} backgroundColor='white' zIndex={100} boxShadow='lg' position='absolute' top={10} right={4} >
        <HStack backgroundColor='#F0E5FF59' px={4} py={2}>
            <Avatar bg='blue.300' color='white' size='md' name={`${user?.first_name} ${user?.last_name}`} />
            <VStack>
                <Text fontWeight='bold' >{user?.first_name?.charAt(0).toUpperCase()}{user?.first_name?.slice(1)}</Text>
                <Text fontStyle='italic' >{user?.roles[0]}</Text>
            </VStack>
        </HStack>
        <Button variant='ghost'  leftIcon={<FaUser />} w='100%' onClick={goToProfile} >Mon profil</Button>
        <Button variant='ghost' leftIcon={<IoMdLogOut />} w='100%' onClick={handleLogOut} >Se déconnecter</Button>
    </Box>
  )
}

export default NavMenu