import { Box, Button, Flex, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { getPatient } from 'src/view-models/Patient'
import PatientCard from './components/PatientCard'
import EditPatient from './components/EditPatient'
import Loading from 'src/components/loading/Loading'
import NotFound from 'src/components/not-found/NotFound'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'

const PatientDetails = () => {
  const dispatch = useAppDispatch()
  const {pathname} = useLocation()
  const id = pathname.split("/")[3]
  const {patient} = useAppSelector((state)=>state.patient)
  const [isEdit,setIdEdit] = useState(false)
  const [isLoading,setIsLoading] = useState(true)

  useEffect(()=>{
    const fetchPatient = async () => {
      await getPatient(id,'sage-femme',dispatch)
      setIsLoading(false)
    }
    fetchPatient()
  },[id,isEdit])

  if(isLoading){
    return <Loading />
  }

  if(!patient && !isLoading){
    return <NotFound page='patient' />
  }

  return (
    <Box px={4} py={2} rounded={4}>
      <Flex mb={4} flexDirection={{base:'column',md:'row'}} alignItems={{base:'flex-start',md:'center'}} justifyContent='space-between' gap={4} >
        <Box>
          <Text color='#4E5D78' fontWeight={700} fontSize={30} >{patient?.first_name}</Text>
          <Text color='#4E5D78'>Sur cette page, vous pouvez gérer tous les aspects concernant {patient?.name} Patient </Text>
        </Box>
        <Button colorScheme='blue' onClick={()=>setIdEdit((prev)=>!prev)} >{isEdit ? 'Annuler la modification':'Modifier le patient'}</Button>
      </Flex>
      {isEdit ? (
        <Box backgroundColor='white' p={8} boxShadow='md'  >
          <EditPatient setIsEdit={setIdEdit} data={patient} />
        </Box>
      ) : (
        <PatientCard data={patient} />
      )}
    </Box>
  )
}

export default PatientDetails