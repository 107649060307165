import {createSlice} from '@reduxjs/toolkit'


type StateType = {
    isOpenDelete : boolean
    patientId:null | string
    patient : null | any
    patients:any[]
    isSendEmail:boolean
    status:number
}

const initialState : StateType = {
    isOpenDelete:false,
    patientId:null,
    patient:null,
    patients:[],
    isSendEmail:false,
    status:1,
}


const patientSlice = createSlice({
    name:"patient",
    initialState,
    reducers:{
        setPatients : (state,action) => {
            state.patients = action.payload
        },
        setPatient : (state,action) => {
            state.patient = action.payload
        },
        setStatus:(state,action)=>{
            state.status = action.payload
        },
        unsetStatus:(state,action)=>{
            state.status = action.payload
        },
        setSendMail : (state) => {
            state.isSendEmail = true
        },
        unsetSendMail : (state) => {
            state.isSendEmail = false
        },
        openDeleteModal : (state,action) => {
            state.isOpenDelete = true
            state.patientId = action.payload
        },
        closeDeleteModal : (state) => {
            state.isOpenDelete = false
            state.patientId = null
        },
    }

})

export const {openDeleteModal,closeDeleteModal,setPatients,setPatient,setSendMail,unsetSendMail,setStatus,unsetStatus} = patientSlice.actions

export default patientSlice.reducer