import { Box, Flex, HStack, Stack, Text, VStack } from '@chakra-ui/react'

const PersoInfoCard = ({patient,accouchement}:{patient:any,accouchement:any}) => {
  return (
    <Box my={4}  className='flex flex-col gap-4' >
            <Flex alignItems='center' justifyContent='space-between' gap={6} flexWrap='wrap' >
                <Stack direction={['column', 'row']}>
                    <HStack>
                        <Text fontSize={{base:'14',sm:'16'}}>Nom : </Text>
                        <Text fontSize={{base:'14',sm:'16'}} fontWeight='bold' >{patient?.full_name ? `${patient?.full_name?.split(' ')[0].charAt(0).toUpperCase()}${patient?.full_name?.split(' ')[0]?.slice(1)}`  : 'ne rien mettre comme texte'}</Text>
                    </HStack>
                    <HStack>
                        <Text fontSize={{base:'14',sm:'16'}}>Prénom : </Text>
                        <Text fontSize={{base:'14',sm:'16'}} fontWeight='bold' >{patient?.full_name ? `${patient?.full_name?.split(' ')[1].charAt(0).toUpperCase()}${patient?.full_name?.split(' ')[1]?.slice(1)}`  : 'ne rien mettre comme texte'}</Text>
                    </HStack>
                </Stack>
                <HStack>
                    <Text fontSize={{base:'14',sm:'16'}}>Date de naissance : </Text>
                    <Text fontSize={{base:'14',sm:'16'}} fontWeight='bold' >{`${patient?.birthdateOrg} (${patient?.birthdate} ans)`|| "ne rien mettre comme texte"}</Text>
                </HStack> 
            </Flex>

            <Flex alignItems='center' justifyContent='space-between' gap={6} flexWrap='wrap' >
                <Stack direction={['column', 'row']}>
                    <HStack>
                        <Text fontSize={{base:'14',sm:'16'}}>Gestité/Parité : </Text>
                        <Text fontSize={{base:'14',sm:'16'}} fontWeight='bold' >{`G ${patient?.gestite?.client_answers[0]?.id === 4 ? patient?.gestite?.client_answers[0]?.answer ? patient?.gestite?.client_answers[0]?.answer : 'ne rien mettre comme texte' : patient?.gestite?.client_answers[1]?.answer? patient?.gestite?.client_answers[1]?.answer : 'ne rien mettre comme texte'}/P ${patient?.gestite?.client_answers[0]?.answer === 5 ? patient?.gestite?.client_answers[0]?.answer ? patient?.gestite?.client_answers[0]?.answer : 'ne rien mettre comme texte' : patient?.gestite?.client_answers[1]?.answer ? patient?.gestite?.client_answers[1]?.answer : 'ne rien mettre comme texte'}`}</Text>
                    </HStack>
                </Stack>
                <HStack>
                    <Text fontSize={{base:'14',sm:'16'}}>Date d’accouchement prévu : </Text>
                    <Text fontSize={{base:'14',sm:'16'}} fontWeight='bold' >{patient?.date_acouchement?.client_answers[0]?.answer || 'ne rien mettre comme texte'}</Text>
                </HStack>
            </Flex>

            <Flex alignItems='center' justifyContent='space-between' gap={6} flexWrap='wrap' >
                    <HStack>
                        <Text fontSize={{base:'14',sm:'16'}}>Taille : </Text>
                        <Text fontSize={{base:'14',sm:'16'}} fontWeight='bold' >{patient?.taille?.client_answers[0]?.answer || 'ne rien mettre comme texte'} cm</Text>
                    </HStack>
                    <HStack>
                        <Text fontSize={{base:'14',sm:'16'}}>Poids avant la grossesse : </Text>
                        <Text fontSize={{base:'14',sm:'16'}} fontWeight='bold' >{`${patient?.poid_avant_grosesse?.client_answers ? patient?.poid_avant_grosesse?.client_answers[0]?.answer : 'ne rien mettre comme texte'} ${patient?.poid_avant_grosesse?.client_answers && 'kg'} `}</Text>
                    </HStack>
                    <HStack>
                        <Text fontSize={{base:'14',sm:'16'}}>Poids actuel : </Text>
                        <Text fontSize={{base:'14',sm:'16'}} fontWeight='bold' >{`${patient?.poid_actuelle?.client_answers ? patient?.poid_actuelle?.client_answers[0]?.answer : 'ne rien mettre comme texte'} ${patient?.poid_actuelle?.client_answers && 'kg'} `}</Text>
                    </HStack>
            </Flex>

            <Flex alignItems='center' justifyContent='space-between' gap={6} flexWrap='wrap' >
                    <HStack>
                        <Text fontSize={{base:'14',sm:'16'}}>Voie d'accouchement prévue : </Text>
                        <Text fontSize={{base:'14',sm:'16'}} fontWeight='bold' >{accouchement?.client_answers[0]?.answer || 'ne rien mettre comme texte'}</Text>
                    </HStack>
            </Flex>
        </Box>
  )
}

export default PersoInfoCard