import { Box,Grid } from "@chakra-ui/react"
import BasicInfo from "./BasicInfo"

type Props = {
    data:any
}

const OrganizationCard = ({data}:Props) => {
  return (
    <Box px={4} py={2} backgroundColor='white' rounded={10} boxShadow='md' >
        <Grid templateColumns={{base:'repeat(1,1fr)',md:'repeat(2,1fr)',lg:'repeat(3,1fr)'}} gap={4} >
            <BasicInfo info1="Nom" info2={data?.name} />
            <BasicInfo info1="Organization type" info2={data?.organization_type} />
            <BasicInfo info1="type" info2={data?.type} />
            <BasicInfo info1="Identifiant" info2={data?.identifier} />
            <BasicInfo info1="Téléphone" info2={data?.phone} />
            <BasicInfo info1="Email" info2={data?.email} />
            <BasicInfo info1="Pays" info2={data?.country} />
            <BasicInfo info1="City" info2={data?.city} />
            <BasicInfo info1="Address" info2={data?.state} />
            <BasicInfo info1="Numero du siret" info2={data?.numero_siret} />
            <BasicInfo info1="Code postal" info2={data?.postalCode} />
        </Grid>
    </Box>
  )
}

export default OrganizationCard