import { Box, Text, VStack } from "@chakra-ui/react"

const Allergie = ({title,text,isUpdated,cpaFlag}:{title:string,text:string,isUpdated:number,cpaFlag:any}) => {
  return (
    <Box flex={1} backgroundColor='white' rounded={10} h='fit-content' w='100%'>
        <VStack alignItems='start' p={4} >
            <Text fontWeight='bold' >{title}</Text>
            <Text>{isUpdated === 1 ? cpaFlag : text}</Text>
        </VStack>
    </Box>
  )
}

export default Allergie