import { Button,FormControl, FormErrorMessage,FormLabel,Grid,HStack,Input,useToast } from "@chakra-ui/react"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"
import Select from 'react-select';

import { useNavigate } from "react-router-dom"

import { createPatient } from "src/view-models/Patient"
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { useEffect, useState } from "react";

const formSchema = z.object({
    identifier : z.string().min(1,{
        message:"L'identifiant est requis",
    }),
    phone : z.string().min(1,{
        message:"Le téléphone est requis",
    }),
    name : z.string().min(1,{
        message:"Le nom est requis",
    }),
    family : z.string().min(1,{
        message:"Le prénom est requis",
    }),
    birthDate : z.string().min(1,{
        message:"Le date de naissance est requis",
    }),
    country : z.string().min(1,{
        message:"Le pays est requis",
    }),
    state : z.string().min(1,{
        message:"l address est obligatoire est requis",
    }),
    city: z.string().min(1,{
      message:"La ville est requis",
  }),
    email: z.string()
    .refine((value) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value), {
      message: 'Adresse e-mail invalide',
    }),
    postalCode : z.string().length(5,{
        message:"Le Code Postal est requis",
    }),
    selected_organization_id:z.number().min(1,{
      message:"L'organization est requis",
  }),
  })
  
  type NewPatFormInputs = {
    identifier : string
    phone : string
    email : string
    country : string
    state : string
    city : string
    postalCode : string
    name : string
    family: string
    birthDate: string
    selected_organization_id:number
  };

const NewPatForm = () => {
  const dispatch = useAppDispatch()
  const toast = useToast()
  const navigation = useNavigate()
  const {profile} = useAppSelector((state)=>state.profile)
  const [organizationsOptions,setOrganizationsOptions] = useState<any[]>([])

  const { register, setValue, handleSubmit,formState: { errors, isSubmitting},} = useForm<NewPatFormInputs>({
    resolver: zodResolver(formSchema),
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    const theValues = {...values,active:1,roles:["patient"],gender:'Female'}
    const response : any = await createPatient(theValues,toast,dispatch)
    if(response.status === true){
      navigation(`/sage-femme/patients/${response.id}/consulter`)
    }
  }

  useEffect(()=>{
    const options = profile?.details?.organizations?.map((o:any)=>({
      value:o.id,
      label:o.name
    }))
    setOrganizationsOptions(options)
  },[profile])

  const handleChange2 = (e : any) => {
    setValue('selected_organization_id',e.value)
  }

  
  return (
    <form  className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
          <Grid templateColumns={{base:'repeat(1,1fr)',md:'repeat(2,1fr)',lg:'repeat(4,1fr)'}} gap={4} >
            <FormControl isRequired isInvalid={!!errors.name}>
              <FormLabel>Nom</FormLabel>
              <Input
                boxShadow='sm'
                disabled={isSubmitting}
                rounded='5px'
                type='text'
                size='md'
                id='name'
                placeholder='Nom'
                {...register("name")}
              />
              <FormErrorMessage>
                {errors.name && errors.name.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!errors.family}>
              <FormLabel >Prénom</FormLabel>
              <Input
                boxShadow='sm'
                disabled={isSubmitting}
                rounded='5px'
                type='text'
                size='md'
                id='family'
                placeholder='Prénom'
                {...register("family")}
              />
              <FormErrorMessage>
                {errors.family && errors.family.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!errors.phone}>
              <FormLabel>Téléphone</FormLabel>
              <Input
                boxShadow='sm'
                disabled={isSubmitting}
                rounded='5px'
                type='tel'
                size='md'
                id='phone'
                placeholder='Téléphone'
                {...register("phone")}
              />
              <FormErrorMessage>
                {errors.phone && errors.phone.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!errors.email}>
              <FormLabel>Email</FormLabel>
              <Input
                boxShadow='sm'
                disabled={isSubmitting}
                rounded='5px'
                type='email'
                size='md'
                id='email'
                placeholder='email'
                {...register("email")}
              />
              <FormErrorMessage>
                {errors.email && errors.email.message}
              </FormErrorMessage>
            </FormControl>
          <FormControl isRequired isInvalid={!!errors.birthDate}>
            <FormLabel>Date de naissance</FormLabel>
              <Input
                boxShadow='sm'
                disabled={isSubmitting}
                rounded='5px'
                type='date'
                size='md'
                id='birthDate'
                placeholder='birthDate'
                {...register("birthDate")}
              />
              <FormErrorMessage>
                {errors.birthDate && errors.birthDate.message}
              </FormErrorMessage>
          </FormControl>
        <FormControl isRequired  isInvalid={!!errors.identifier}>
          <FormLabel>Identifiant</FormLabel>
          <Input
            boxShadow='sm'
            disabled={isSubmitting}
            rounded='5px'
            type='text'
            size='md'
            id='identifier'
            placeholder='Identifiant'
            {...register("identifier")}
          />
          <FormErrorMessage>
            {errors.identifier && errors.identifier.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isRequired isInvalid={!!errors.country}>
          <FormLabel>Pays</FormLabel>
          <Input
              boxShadow='sm'
              disabled={isSubmitting}
              rounded='5px'
              type='text'
              size='md'
              id='country'
              placeholder='Pays'
              {...register("country")}
          />
          <FormErrorMessage>
            {errors.country && errors.country.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isRequired isInvalid={!!errors.city}>
          <FormLabel>Ville</FormLabel>
          <Input
              boxShadow='sm'
              disabled={isSubmitting}
              rounded='5px'
              type='text'
              size='md'
              id='city'
              placeholder='Ville'
              {...register("city")}
          />
          <FormErrorMessage>
            {errors.city && errors.city.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isRequired isInvalid={!!errors.state}>
          <FormLabel>Adresse</FormLabel>
          <Input
              boxShadow='sm'
              disabled={isSubmitting}
              rounded='5px'
              type='text'
              size='md'
              id='state'
              placeholder='Adresse'
              {...register("state")}
          />
          <FormErrorMessage>
            {errors.state && errors.state.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isRequired isInvalid={!!errors.postalCode}>
          <FormLabel>Code Postal</FormLabel>
          <Input
            boxShadow='sm'
            disabled={isSubmitting}
            rounded='5px'
            type='number'
            size='md'
            id='postalCode'
            placeholder='Code Postal'
            {...register("postalCode")}
          />
          <FormErrorMessage>
            {errors.postalCode && errors.postalCode.message}
          </FormErrorMessage>
        </FormControl>

            <FormControl  isRequired isInvalid={!!errors.selected_organization_id}>
                <FormLabel>Établissement</FormLabel>
                <Select
                    placeholder='Sélectionner'
                    onChange={handleChange2}
                    options={organizationsOptions}  
                  />
                  <FormErrorMessage>
                      {errors.selected_organization_id && errors.selected_organization_id.message}
                  </FormErrorMessage>
              </FormControl>
          </Grid>
          <HStack mt={10}>
          <Button
            type='submit'
            onClick={handleSubmit(onSubmit)}
            isLoading={isSubmitting}
            backgroundColor='blue.500'
            p='4'
            rounded='20px'
            mx='auto'      
            w='40%'
            colorScheme='blue'
            variant='solid'
            
          >
            Ajouter
            </Button>
        </HStack>                   
          </form>
  )
}

export default NewPatForm