import { Navigate } from "react-router-dom"
import Root from "src/components/root/sage-femme/Root"
import ErrorPage from "src/pages/error/error-page"
import Patients from "src/pages/sage-femme/patients/Patients"
import Consulter from "src/pages/sage-femme/consultation/Consulter";
import Dashboard from "src/pages/sage-femme/dashboard/Dashboard";
import NewPatient from "src/pages/sage-femme/patients/new/NewPatient";
import Settings from "src/pages/sage-femme/settings/Settings";
import PatientDetails from "src/pages/sage-femme/patients/details/PatientDetails";

const refresh_token = localStorage.getItem("refresh_token")
const access_token = localStorage.getItem("access_token")

const isSignIn = refresh_token && access_token

const SageFemmeRoutes = {
    path: "/sage-femme",
    element: isSignIn ? <Root /> : <Navigate to='/auth/login' />,
    errorElement: <ErrorPage />,
    children:[
      {
        path:'/sage-femme/dashboard',
        element:<Dashboard />
      },
      {
        path:'/sage-femme/patients',
        element:<Patients />
      },
      {
        path:'/sage-femme/new-patient',
        element:<NewPatient />
      },
      {
        path:'/sage-femme/patients/:id',
        element:<PatientDetails />
      },
      {
        path:'/sage-femme/patients/:id/consulter',
        element:<Consulter />
      },
      {
        path:'/sage-femme/settings',
        element:<Settings />
      },
    ]

  }

  export default SageFemmeRoutes