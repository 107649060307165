import HttpClient from "../services/httpclient"

export type LoginInfo = {
    email:string,
    password:string
}

class LoginModel {
    public static login = (loginInfo:LoginInfo) => {
        return HttpClient.post('/login',loginInfo)
    }
}

export default LoginModel