import HttpClient from "src/services/httpclient"

export type PatientInfo = {
    identifier : string
    phone : string
    email : string
    country : string
    state : string
    city : string
    postalCode : string
    name : string
    family: string
    gender: string
    birthDate: string
    password? : string
    c_password? :string
    active:number
    roles:string[]
    selected_organization_id:number
}


class Patient {
    public static createPatient = (PatientInfos:PatientInfo) => {
        return HttpClient.postWithLogin('/sage-femme/patient/store',PatientInfos)
    }
    public static updatePatient = (id:string,PatientInfos:PatientInfo) => {
        return HttpClient.postWithLogin(`/sage-femme/patient/update/${id}`,PatientInfos)
    }
    public static getPatients = (role:string | undefined) => {
        if(role === undefined){
            return HttpClient.getWithLogin(`/patient/list?per_page=all`)
        }else{
            return HttpClient.getWithLogin(`/${role}/patient/list?per_page=all`)
        }
    }
    public static getArchivedPatients = (role:string|undefined) => {
        if(role === undefined){
            return HttpClient.getWithLogin(`/patient/archive/list?per_page=all`)
        }else{
            return HttpClient.getWithLogin(`/${role}/patient/archive/list?per_page=all`)
        }
    }
    public static sendMail = (id:string) => {
        return HttpClient.getWithLogin(`/sage-femme/patient/send-mail/${id}`)
    }
    public static getPatient = (id : string,role:string|undefined) => {
        if(role === undefined){
            return HttpClient.getWithLogin(`/patient/show/${id}`)
        }else{
            return HttpClient.getWithLogin(`/${role}/patient/show/${id}`)
        }
    }
    public static deletePatient = (id:string) => {
        return HttpClient.deleteWithLogin(`/sage-femme/patient/delete/${id}`)
    }
    public static getCPA = (id:string) => {
        return HttpClient.getWithLogin(`/medecin/patient/cpa-file/${id}`)
    }
    public static sageFemmeComment = (body:any) => {
        return HttpClient.postWithLogin('/sage-femme/question/store-comment',body)
    }
    public static setIsWatchedVideo = (id:any) => {
        return HttpClient.getWithLogin(`/start-question/${id}`)
    }
    public static conclusionCPA = (id:string) => {
        return HttpClient.getWithLogin(`/medecin/cpa_conclusion?patient_id=${id}`)
    }
    public static updateConclusionCPA = (id:any,body:any) => {
        return HttpClient.postWithLogin(`/medecin/cpa_conclusion/update?patient_id=${id}`,body)
    }
    public static validateCPA = (id:any) => {
        return HttpClient.getWithLogin(`/medecin/patient/validate-patient/${id}`)
    }
    public static filterPatients = (values:any) => {
        return HttpClient.getWithLogin(`/patient/filtre-patient?${values}`)
    }
    

}

export default Patient