import {createSlice} from '@reduxjs/toolkit'


type StateType = {
    isLoading:boolean
    profile : null | any
}

const initialState : StateType = {
    isLoading:true,
    profile:null,
}


const profileSlice = createSlice({
    name:"profile",
    initialState,
    reducers:{
        setProfile : (state,action) => {
            state.profile = action.payload
        },
        endLoading : (state) => {
            state.isLoading = false
        }

    }

})

export const {setProfile,endLoading} = profileSlice.actions

export default profileSlice.reducer

