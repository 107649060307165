import { Flex } from "@chakra-ui/react";
import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error : any = useRouteError();
  console.error(error);

  return (
    <Flex height='100vh' justifyContent='center' alignItems='center' id="error-page">
      <Flex flexDirection='column' alignItems='center' justifyContent='center'>
            <h1>Oops!</h1>
            <p>Sorry, an unexpected error has occurred.</p>
            <p>
              <i>{error.statusText || error.message}</i>
            </p>
          </Flex>
    </Flex>
    
  );
}