import { Box} from '@chakra-ui/react'
import NewPatForm from './components/NewPatForm'

const NewPatient = () => {
  return (
    <Box px={4} py={2} rounded={4}>
        <Box backgroundColor='white' p={8} boxShadow='md' >
          <NewPatForm />
        </Box>
    </Box>
  )
}

export default NewPatient