import { Box, Text } from "@chakra-ui/react"

type Props = {
    info1:string,
    info2:string
}

const BasicInfo = ({info1,info2} : Props) => {
  return (
    <Box>
        <Text fontWeight='bold' >{info1}</Text>
        <Text>{info2 ? info2 : 'null'}</Text>
    </Box>
  )
}

export default BasicInfo