import Consultation from "./components/Consultation";
import ID from "./components/ID";
import Name from "./components/Name";
import Organizations from "./components/Organizations";
import Status from './components/Status'

export const colDefs = [
  {
    field:'ID',
    cellRenderer:ID,
    cellRendererParams: (params : any) => {
      const rowData = params.data;
      return {
        id: `${rowData.id}`,
      };
    },
    filter:true,
  },
  {
    field:'Nom',
    cellRenderer:Name,
    cellRendererParams: (params : any) => {
      const rowData = params.data;
      return {
        name: `${rowData.name[0]}`,
      };
    },
    filter:true,
  },
  {
    field:'Prénom',
    cellRenderer:Name,
    cellRendererParams: (params : any) => {
      const rowData = params.data;
      return {
        name: `${rowData.family}`,
      };
    },
    filter:true,
  },
  { 
    headerName:"Date de naissance",
    field: "birthDate",
  },
  {
    field:'Questionnaire',
    cellRenderer:Status,
    cellRendererParams: (params : any) => {
      const rowData = params.data;
      return {
        status: `${rowData.status}`,
        type:'questionnaire'
      };
    },
    filter:true,
  },
  {
    field:'consultation',
    cellRenderer:Status,
    cellRendererParams: (params : any) => {
      const rowData = params.data;
      return {
        status: `${rowData.is_validate}`,
        type:'validation'
      };
    },
    filter:true,
  },
  {
    field:'Consultation',
    cellRenderer:Consultation,
    cellRendererParams: (params : any) => {
      const rowData = params.data;
      return {
        id: `${rowData.id}`,
      };
    },
    filter:false,
  },
];