import HttpClient from "../services/httpclient"

export type ForgetPasswordInfo = {
    email:string,
}

export type ResetPasswordInfo = {
    email:string,
    otp_number:string,
    password:string,
    password_confirmation:string
}

class ForgetPasswordModel {
    public static forgetPassword = (email:ForgetPasswordInfo) => {
        return HttpClient.post('/auth/reset_password/email/otp',email)
    }
    public static resetPassword = (resetInfo:ResetPasswordInfo) => {
        return HttpClient.post('/auth/reset_password/email',resetInfo)
    }
}

export default ForgetPasswordModel