import { Box, Grid } from "@chakra-ui/react"
import BasicInfo from "./BasicInfo"

type Props = {
    data:any
}

const PatientCard = ({data}:Props) => {
  return (
    <Box px={4} py={2} backgroundColor='white' rounded={10} boxShadow='md' >
        <Grid templateColumns={{base:'repeat(1,1fr)',md:'repeat(2,1fr)',lg:'repeat(3,1fr)'}} gap={4} >
            <BasicInfo info1="Name" info2={data?.name} />
            <BasicInfo info1="Prénom" info2={data?.family} />
            <BasicInfo info1="gender" info2={data?.gender} />
            <BasicInfo info1="Identifier" info2={data?.identifier} />
            <BasicInfo info1="Numéro de téléphone" info2={data?.phone} />
            <BasicInfo info1="Email" info2={data?.email} />
            <BasicInfo info1="Country" info2={data?.country} />
            <BasicInfo info1="City" info2={data?.city} />
            <BasicInfo info1="Address" info2={data?.state} />
            <BasicInfo info1="Birthdate" info2={data?.birthDate} />
            <BasicInfo info1="Postal Code" info2={data?.postalCode} />
        </Grid>
    </Box>
  )
}

export default PatientCard