import { Box, Flex } from "@chakra-ui/react"
import BasicInfo from "./BasicInfo"

type Props = {
    data:any
}

const ProfileCard = ({data}:Props) => {
  return (
    <Box px={4} py={2}  backgroundColor='white' rounded={10} boxShadow='md' >
        <Flex alignItems='center' justifyContent='center' wrap='wrap' gap={4} >
            <BasicInfo info1="Prénom" info2={data?.first_name} />
            <BasicInfo info1="Nom" info2={data?.last_name} />
            <BasicInfo info1="Numéro de téléphone" info2={data?.phone} />
            <BasicInfo info1="Email" info2={data?.email} />
        </Flex>
    </Box>
  )
}

export default ProfileCard