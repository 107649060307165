import { Box, Flex } from '@chakra-ui/react'
import { adminBars } from '../constants'
import Itembar from '../Itembar'

const AdminSidebar = () => {
  return (
    <Box>
        <Flex flexDirection='column' my={4} gap={2} >
            {adminBars.map((bar,index)=>(
                <Itembar type='admin' key={index} bar={bar} />
            ))}
        </Flex>
    </Box>
  )
}

export default AdminSidebar