import { Button,HStack, } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import {useAppSelector } from 'src/redux/hooks';


type Props = {
    id:string
}

const Consultation = ({id}:Props) => {
    const {status} = useAppSelector((state)=>state.patient)
    const navigate = useNavigate()
    
    const goToConsulter= () => {
      navigate(`/medecin/patients/${id}`)
    }

    if(status === 0){
      return null
    }

  return (
  <HStack justifyContent='center' alignItems='center' h='100%' >
    <Button colorScheme='blue' onClick={goToConsulter} >Consulter CPA</Button>
  </HStack>
  )
}

export default Consultation