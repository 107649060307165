import {createSlice} from '@reduxjs/toolkit'


type StateType = {
    isOpenDelete : boolean
    practitionerId:null | string
    practitioner : null | any
    practitioners:any[]
    status:number,
    cpa:any,
    isValidate:number,
    isOpenValidCPA : boolean
}

const initialState : StateType = {
    isOpenDelete:false,
    practitionerId:null,
    practitioner:null,
    practitioners:[],
    status:1,
    cpa:null,
    isOpenValidCPA:false,
    isValidate:0
}


const practitionerSlice = createSlice({
    name:"practitioner",
    initialState,
    reducers:{
        setPractitioners : (state,action) => {
            state.practitioners = action.payload
        },
        setPractitioner : (state,action) => {
            state.practitioner = action.payload
        },
        setStatus : (state,action) => {
            state.status = action.payload
        },
        setCPA : (state,action) => {
            state.cpa = action.payload
            state.isValidate = action.payload.validate_file
        },
        openValidCPA : (state) => {
            state.isOpenValidCPA = true
        },
        closeValidCPA : (state) => {
            state.isOpenValidCPA = false
        },
        openDeleteModal : (state,action) => {
            state.isOpenDelete = true
            state.practitionerId = action.payload
        },
        closeDeleteModal : (state) => {
            state.isOpenDelete = false
            state.practitionerId = null
        },
    }

})

export const {openDeleteModal,closeDeleteModal,setPractitioners,setPractitioner, setCPA, setStatus, openValidCPA, closeValidCPA} = practitionerSlice.actions

export default practitionerSlice.reducer