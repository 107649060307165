import { AdminInfo } from "src/models/Admin"
import Profile from "src/models/Profile"
import { endLoading, setProfile } from "src/redux/features/profile/ProfileSlice"


export const getProfile = async (dispatch:any) => {
    const response : any = await Profile.getProfile()
    if(response.status === 200) {
        dispatch(setProfile(response.data.data))
        dispatch(endLoading())
        return {status:true}
    }else{
        localStorage.removeItem("access_token")
        localStorage.removeItem("refresh_token")
        window.location.assign('/auth/login')
        return {status:false}
    }
}

export const updatePassword = async (role:string,values:AdminInfo,toast:any) => {
    const response : any = await Profile.updatePassword(role,values)
    if(response.status === 200) {
        toast({
            description: `Password a été mis à jour avec succès !`,
            status: 'success',
            duration: 3000,
            isClosable: true,
            position:'top'
          })
          return {status:true}
    }else if (response.status !==500){
        toast({
            description: `${response?.message?.message}`,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position:'top'
          })
          return {status:false}
    }else {
        toast({
        description: `quelque chose s'est mal passé !`,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position:'top'
      })
      return {status:false}
    }
}
