import { Button,Flex,FormControl, FormErrorMessage,FormLabel,Grid,HStack,Input,useToast } from "@chakra-ui/react"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"
import { useEffect, useState } from "react"
import {useNavigate } from "react-router-dom"
import { updatePractitioner } from "src/view-models/Practitioner"
import { getOrganizations } from "src/view-models/Organization"
import { useAppDispatch, useAppSelector } from "src/redux/hooks"
import { RiLoader2Fill } from "react-icons/ri"
import Select from 'react-select';


const formSchema = z.object({
    identifier : z.string().min(1,{
        message:"L'identifier est obligatoire",
    }),
    phone : z.string().min(1,{
        message:"Le phone est obligatoire",
    }),
    name : z.string().min(1,{
        message:"Le name est obligatoire",
    }),
    family : z.string().min(1,{
        message:"Le family est obligatoire",
    }),
    gender : z.string().min(1,{
        message:"Le gender est obligatoire",
    }),
    birthdate : z.string().min(1,{
        message:"Le birthdate est obligatoire",
    }),
    country : z.string().min(1,{
        message:"Le country est obligatoire",
    }),
    state : z.string().min(1,{
        message:"l address est obligatoire est obligatoire",
    }),
    city: z.string().min(1,{
      message:"Le city est obligatoire",
  }),
    email: z.string()
    .refine((value) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value), {
      message: 'Adresse e-mail invalide',
    }),
    postalCode : z.string().length(5,{
        message:"Le postalCode doit contenir 5 caractères",
    }),
    qualification : z.string().min(1,{
        message:"Le qualification est obligatoire",
    }),
    identifiant_national : z.string().min(1,{
        message:"Le identifiant_national est obligatoire",
    }),
    organizations : z.array(z.string()).min(1,{
        message:"Vous devez sélectionner au moins 1 organisation"
    }),
  })
  
  type EditDoctorInputs = {
    identifier : string
    phone : string
    email : string
    country : string
    state : string
    city : string
    postalCode : string
    name : string
    family: string
    gender: string
    birthdate: string
    qualification : string
    identifiant_national : string
    organizations:string[]
    password:string
    c_password :string
  };

const EditDoctor = ({data} : any) => {
  const dispatch = useAppDispatch()
  const toast = useToast()
  const navigation = useNavigate()
  const {organizations} = useAppSelector((state)=>state.organization)
  const [organizationsOptions,setOrganizationsOptions] = useState<any[]>([])
  const [isLoading,setIsLoading] = useState(true)
  const practionOrganizations = data?.organizations.map((d:any)=>`${d.id}`)
  const id=data?.id

  const { register, setValue, handleSubmit,formState: { errors, isSubmitting},} = useForm<EditDoctorInputs>({
    resolver: zodResolver(formSchema),
    defaultValues:{
      identifier : data?.identifier,
      phone : data?.phone,
      email : data?.email,
      country : data?.country,
      state : data?.state,
      city : data?.city,
      postalCode : data?.postalCode,
      name : data?.name,
      family: data?.family,
      gender: data?.gender,
      birthdate: data?.birthdate,
      qualification : data?.qualification,
      identifiant_national : data?.identifiant_national,
      organizations:practionOrganizations,
    }
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    const theValues = {...values,active:1,roles:['sage-femme']}
    const response : any = await updatePractitioner(id,theValues,toast,dispatch)
    if(response.status === true){
      navigation("/admin/sage-femmes")
    }else{
      return null
    }
  }
  
  useEffect(()=>{
    const options = organizations?.map((o)=>({
      value:o.id,
      label:o.name
    }))
    setOrganizationsOptions(options)
  },[organizations])

  useEffect(() => {
    const fetchOrganizations = async () => {
      await getOrganizations(dispatch)
      setIsLoading(false)
    }
    fetchOrganizations()
  }, [])

  const options = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
  ];

  const handleChange = (e : any) => {
    setValue('gender',e.value)
  }

  const handleChange2 = (options : any) => {
    const values = options?.map((o:any)=>(o.value.toString()))
    setValue('organizations',values)
  }

  return (
    <form  className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
          <Grid templateColumns={{base:'repeat(1,1fr)',md:'repeat(2,1fr)',lg:'repeat(4,1fr)'}} gap={4} >
            <FormControl isRequired isInvalid={!!errors.name}>
              <FormLabel>Nom</FormLabel>
              <Input
                boxShadow='sm'
                disabled={isSubmitting}
                rounded='5px'
                type='text'
                size='md'
                id='name'
                placeholder='Nom'
                {...register("name")}
              />
              <FormErrorMessage>
                {errors.name && errors.name.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!errors.family}>
              <FormLabel >Prénom</FormLabel>
              <Input
                boxShadow='sm'
                disabled={isSubmitting}
                rounded='5px'
                type='text'
                size='md'
                id='family'
                placeholder='Prénom'
                {...register("family")}
              />
              <FormErrorMessage>
                {errors.family && errors.family.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!errors.phone}>
              <FormLabel>Téléphone</FormLabel>
              <Input
                boxShadow='sm'
                disabled={isSubmitting}
                rounded='5px'
                type='tel'
                size='md'
                id='phone'
                placeholder='Téléphone'
                {...register("phone")}
              />
              <FormErrorMessage>
                {errors.phone && errors.phone.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!errors.email}>
              <FormLabel>Email</FormLabel>
              <Input
                boxShadow='sm'
                disabled={isSubmitting}
                rounded='5px'
                type='email'
                size='md'
                id='email'
                placeholder='email'
                {...register("email")}
              />
              <FormErrorMessage>
                {errors.email && errors.email.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!errors.gender}>
                <FormLabel>Genre</FormLabel>
                  <Select
                    placeholder='Sélectionner' 
                    defaultValue={[{value:data?.gender,label:data?.gender}]}
                    onChange={handleChange}
                    options={options}  
                  />
                <FormErrorMessage>
                    {errors.gender && errors.gender.message}
                </FormErrorMessage>
            </FormControl>
            {isLoading ? (
              <Flex justifyContent='center' alignItems='center' >
                <RiLoader2Fill size={30} className='animate-spin' />
              </Flex>
            ) : (
              <FormControl  isRequired isInvalid={!!errors.organizations}>
                <FormLabel>Établissement</FormLabel>
                <Select
                    placeholder='Sélectionner' 
                    defaultValue={data?.organizations?.map((d:any)=>({value:d.id,label:d.name}))}
                    isMulti
                    onChange={handleChange2}
                    options={organizationsOptions}  
                  />
                  <FormErrorMessage>
                      {errors.organizations && errors.organizations.message}
                  </FormErrorMessage>
              </FormControl>
            )}
          <FormControl isRequired isInvalid={!!errors.birthdate}>
            <FormLabel>Date de naissance</FormLabel>
              <Input
                boxShadow='sm'
                disabled={isSubmitting}
                rounded='5px'
                type='text'
                size='md'
                id='birthdate'
                placeholder='birthdate'
                {...register("birthdate")}
              />
              <FormErrorMessage>
                {errors.birthdate && errors.birthdate.message}
              </FormErrorMessage>
          </FormControl>
        
        <FormControl isRequired isInvalid={!!errors.country}>
          <FormLabel>Pays</FormLabel>
          <Input
              boxShadow='sm'
              disabled={isSubmitting}
              rounded='5px'
              type='text'
              size='md'
              id='country'
              placeholder='Pays'
              {...register("country")}
          />
          <FormErrorMessage>
            {errors.country && errors.country.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isRequired isInvalid={!!errors.city}>
          <FormLabel>City</FormLabel>
          <Input
              boxShadow='sm'
              disabled={isSubmitting}
              rounded='5px'
              type='text'
              size='md'
              id='city'
              placeholder='Ville'
              {...register("city")}
          />
          <FormErrorMessage>
            {errors.city && errors.city.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isRequired isInvalid={!!errors.state}>
          <FormLabel>Adress</FormLabel>
          <Input
              boxShadow='sm'
              disabled={isSubmitting}
              rounded='5px'
              type='text'
              size='md'
              id='state'
              placeholder='Adresse'
              {...register("state")}
          />
          <FormErrorMessage>
            {errors.state && errors.state.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isRequired isInvalid={!!errors.postalCode}>
          <FormLabel>Code Postal</FormLabel>
          <Input
            boxShadow='sm'
            disabled={isSubmitting}
            rounded='5px'
            type='number'
            size='md'
            id='postalCode'
            placeholder='Code Postal'
            {...register("postalCode")}
          />
          <FormErrorMessage>
            {errors.postalCode && errors.postalCode.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isRequired isInvalid={!!errors.qualification}>
          <FormLabel>Qualification</FormLabel>
          <Input
            boxShadow='sm'
            disabled={isSubmitting}
            rounded='5px'
            type='text'
            size='md'
            id='qualification'
            placeholder='qualification'
            {...register("qualification")}
          />
          <FormErrorMessage>
            {errors.qualification && errors.qualification.message}
          </FormErrorMessage>
        </FormControl>
            <FormControl isRequired  isInvalid={!!errors.identifier}>
                <FormLabel>Identifiant</FormLabel>
                <Input
                    boxShadow='sm'
                    disabled={isSubmitting}
                    rounded='5px'
                    type='text'
                    size='md'
                    id='identifier'
                    placeholder='Identifiant'
                    {...register("identifier")}
                />
                <FormErrorMessage>
                    {errors.identifier && errors.identifier.message}
                </FormErrorMessage>
            </FormControl>
            <FormControl isRequired  isInvalid={!!errors.identifiant_national}>
                <FormLabel>National id</FormLabel>
                <Input
                boxShadow='sm'
                disabled={isSubmitting}
                rounded='5px'
                type='text'
                size='md'
                id='id'
                placeholder='identifiant_national'
                {...register("identifiant_national")}
                />
            <FormErrorMessage>
                {errors.identifiant_national && errors.identifiant_national.message}
            </FormErrorMessage>
            </FormControl>
          </Grid>
          <HStack mt={10}>
          <Button
            type='submit'
            onClick={handleSubmit(onSubmit)}
            isLoading={isSubmitting}
            p='4'
            rounded='20px'
            mx='auto'        
            w='40%'
            colorScheme='blue'
            variant='solid'
            
          >
            Modifier
            </Button>
        </HStack>                  
          </form>
  )
}

export default EditDoctor