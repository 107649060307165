import ActionTable from "./components/ActionTable";
import Status from "./components/Status";

export const colDefs = [
  { headerName:"Name",
    field: "name",
  },
  { 
    headerName:"E-mail",
    field: "email",
    cellRenderer:(params : any)=>{
      return params.data.email ? params.data.email : "null"
    }
  },
  { 
    headerName:"Pays",
    field: "country"
   },
  { 
    headerName:"Address",
    field: "city" 
  },
  {
    field:'Status',
    cellRenderer:Status,
    filter:true,
  },
  {
    field:'Actions',
    cellRenderer:ActionTable,
    cellRendererParams: (params : any) => {
      const rowData = params.data;
      return {
        id: `${rowData.id}`,
        name:`${rowData.name}`
      };
    },
    filter:false,
  },
  
];