import { Flex, Text, VStack } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import Loading from 'src/components/loading/Loading'
import { useAppSelector } from 'src/redux/hooks'

const Welcome = () => {
    const {profile,isLoading} = useAppSelector((state)=>state.profile)

    if(!profile && isLoading){
        return <Loading />
    }

  return (
    <Flex w='100%' h='90vh' justifyContent='center' alignItems='center' >
        <VStack>
            <Text>Bienvenue <span className='font-bold' >{profile?.first_name}</span> </Text>
            <Link className='underline' to='/sage-femme/patients' >Accédez au List des patients pour gérer patients!</Link>
        </VStack>
    </Flex>
  )
}

export default Welcome