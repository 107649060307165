import { Box,HStack,Image,Text} from "@chakra-ui/react"
import accouchementImage from '../../../../../assets/accouchement.png'

type Props = {
    status:string,
    color:string,
    title:string,
    isUpdated:number,
    cpaFlag:any
}

const Accouchement = ({title,status,isUpdated,cpaFlag} : Props) => {
  return (
    <Box flex={1} backgroundColor='#ECECEC' rounded={10} h='fit-content'>
      <HStack p={4}>
        <Image src={accouchementImage} />
          <Box>
              <Text fontWeight={600} color='#182F45' >{title}</Text>
              <Text color={status ? 'black' : 'red'} fontWeight='bold' >{isUpdated === 1 ? cpaFlag : status ? status:'ne rien mettre comme texte'}</Text>
          </Box>
      </HStack>
    </Box>
  )
}

export default Accouchement