import { Flex } from '@chakra-ui/react'
import { RiLoader2Fill } from 'react-icons/ri'

const PatientLoading = () => {
  return (
    <Flex w='100%' my={20}  justifyContent='center' alignItems='center' >
        <RiLoader2Fill size={30} className='animate-spin' />
    </Flex>
  )
}

export default PatientLoading