import { HStack, Icon, Text } from '@chakra-ui/react'
import { FaBan } from 'react-icons/fa6';
import { MdVerified } from "react-icons/md";
import { useAppSelector } from 'src/redux/hooks';

const Status = () => {
    const {status} = useAppSelector((state)=>state.patient)
  return (
    <HStack>
        {status === 1 ? (
            <Icon as={MdVerified} size={20} color='green.500' />
        ) : (
            <Icon as={FaBan} size={20} color='red.500' />
        )}
        {status === 1 ? (
            <Text>Actif</Text>
        ) : (
            <Text>Disactive</Text>
        )}
    </HStack>
  )
}

export default Status