import { Box, Button, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import Loading from 'src/components/loading/Loading';
import { setIsWatchedVideo } from 'src/view-models/Patient';

const Video = ({id,setIsVideo}:{id:string,setIsVideo:any}) => {
    const toast = useToast()
    const videoUrl = "https://www.youtube.com/watch?v=PjK6jSviRFE"
    const embedUrl = videoUrl.replace('watch?v=', 'embed/');

    const [isMounted,setIsMounted] = useState(true)
    const [isLoading,setIsLoading] = useState(false)

    const goToQuestions = async () => {
        try {
            setIsLoading(true)
            const res = await setIsWatchedVideo(id,toast)
            if(res.status === true){
                setIsVideo(1)
            }

        } catch (error) {
        }finally{
            setIsLoading(false)
        }
    }

    useEffect(()=>{
        setIsMounted(false)
    },[])

    if(isMounted){
        return <Loading />
    }

  return (
    <Box py={4} px={{base:4,sm:10}}>
        <iframe
            width="100%"
            height="400"
            src={embedUrl}
            title="YouTube video player"
            frameBorder={0}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
        ></iframe>
            <div className='flex justify-end mt-2' onClick={goToQuestions} >
                <Button isDisabled={isLoading} type='button' colorScheme='blue' px={10}>Accéder au questionnaire</Button>
            </div>
    </Box>
  )
}

export default Video