import { useRef } from 'react'
import { Box, Button, Icon, useDisclosure } from '@chakra-ui/react'
import {FaBars} from 'react-icons/fa'
import {
    Drawer,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
  } from '@chakra-ui/react'
import Sidebar from '../sidebar/Sidebar'

const NavMobile = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    
  const btnRef = useRef<any>(null);
  return (
    <Box className='lg:hidden' >
        <Button variant='ghost' ref={btnRef} onClick={onOpen}>
          <Icon  as={FaBars} fontSize={25}  />
        </Button>
        <Drawer
        isOpen={isOpen}
        placement='left'
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton zIndex={100} />
          <Sidebar />
        </DrawerContent>
      </Drawer>
    </Box>
  )
}

export default NavMobile