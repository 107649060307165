import { Box, Button, Flex,Text,} from "@chakra-ui/react"
import { useState } from "react"
import QuestionComp from "./QuestionComp"
import { TbEdit } from "react-icons/tb"
import QuestionsForm from "./QuestionsForm"
import { useAppSelector } from "src/redux/hooks"

const PatientQuestions = ({tab,category}:{tab:any,category:string}) => {
  const {isValidateCPA} = useAppSelector((state)=>state.question)
  const [isEdit,setIsEdit] = useState(false)
  const handleEdit = () => {
    setIsEdit((prev)=>!prev)
  }

  return (
    <Box bg='white' rounded={10} boxShadow='sm' px={8} py={4}>
        <Flex alignItems='center' justifyContent='space-between' mb={4} >
          <Text fontWeight='bold' fontSize={18} >{category}</Text>
          {isValidateCPA === 0 ? (
              <Button colorScheme='blue' rightIcon={<TbEdit />} onClick={handleEdit} >
                {isEdit ? "Annuler la modification" : "Modifier"}
            </Button>
            ) : null}
        </Flex>
        <Flex flexDirection='column' gap={4} >
            {isEdit ? (
              <QuestionsForm category={category} setIsEdit={setIsEdit} questions={tab} />
            ) : (
              tab?.map((t:any,index:number)=>(
                <QuestionComp key={index} 
                  question={t}
                  />
              ))
            )}
        </Flex>
    </Box>
  )
}

export default PatientQuestions