import { Box, Text } from '@chakra-ui/react'

type Props = {
    title:string,
    desc:string
}

const MoreInfo = ({title,desc}:Props) => {
  return (
    <Box flex={1} p={4} backgroundColor='white' rounded={10} boxShadow='sm' w='100%' h='fit-content' >
        <Text fontWeight='bold'>{title}</Text>
        <Text color={desc ? "black" : "red"} >{desc}</Text>
    </Box>
  )
}

export default MoreInfo