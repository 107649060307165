import { Box,Flex} from '@chakra-ui/react'
import Categories from './Categories'
import Questions from './Questions'

const QstWrapper = ({uuid}:{uuid:string}) => {
  return (
    <Flex gap={4}>
        <Categories />
        <Box flex={4}>
          <Questions uuid={uuid} />
        </Box>
    </Flex>
  )
}

export default QstWrapper