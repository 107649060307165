import { HStack, Text, VStack } from '@chakra-ui/react'

const QuestionComp = ({question}:{question:any}) => {
  return (
    <VStack alignItems='flex-start' >
        <HStack>
            <Text fontSize={16} >{question?.question}</Text>
            {( (!question?.client_answers && question?.client_answers !== 0) || question?.client_answers?.length === 0 || question?.client_answers[0]?.answer === null) ? (
                <Text fontSize={16} fontWeight='bold'>à renseigner</Text>
            ) : (
              typeof(question?.client_answers) === 'number' ? (
                  <Text  fontSize={16} fontWeight='bold' >{question?.client_answers}</Text>
              ) :(
                question?.client_answers?.map((answer:any,index:number)=>(
                  <Text key={index} fontSize={16} fontWeight='bold' >{answer?.answer} {index !== question?.client_answers?.length - 1 && ","} </Text>
              )
              )
              )
            )}
        </HStack>
    </VStack>
  )
}

export default QuestionComp