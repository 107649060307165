import { Flex, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import PatientQuestions from './PatientQuestions'
import { useAppSelector } from 'src/redux/hooks'

const QuestionsTabs = () => {
  const {medecinQuestions} = useAppSelector((state)=>state.question)
  return (
    <Tabs my={6} variant='unstyled'>
        <TabList  >
            <Flex alignItems='center' gap={4} justifyContent={{base:'flex-start',lg:'space-around'}} w='100%' overflowX='auto' >
            <Tab   color='black' bg='white' px={4} py={2} boxShadow='sm' rounded={8}  _selected={{ color: 'white', bg: '#235EE8' }}>Grossesse actuelle</Tab>
            <Tab   color='black' bg='white' px={4} py={2} boxShadow='sm' rounded={8}  _selected={{ color: 'white', bg: '#235EE8' }}>Consultation du 7ème mois</Tab>
            <Tab   color='black' bg='white' px={4} py={2} boxShadow='sm' rounded={8}  _selected={{ color: 'white', bg: '#235EE8' }}>Antécédents obstétricaux</Tab>
            <Tab   color='black' bg='white' px={4} py={2} boxShadow='sm' rounded={8}  _selected={{ color: 'white', bg: '#235EE8' }}>Antécédent médicaux préalables à la grossesse</Tab>
            <Tab   color='black' bg='white' px={4} py={2} boxShadow='sm' rounded={8}  _selected={{ color: 'white', bg: '#235EE8' }}>Diathèse hémorragique</Tab>
            <Tab   color='black' bg='white' px={4} py={2} boxShadow='sm' rounded={8}  _selected={{ color: 'white', bg: '#235EE8' }}>Antécédents d'incidents anesthésiques</Tab>
            </Flex>
        </TabList>
        <TabPanels>
          <TabPanel>
            <PatientQuestions tab={medecinQuestions?.Grossesse_actuelle} category="Grossesse actuelle"/>
          </TabPanel>  
          <TabPanel>
            <PatientQuestions tab={medecinQuestions?.Paramètres_recueillis_lors_de_la_consultation_du_7ème_mois} category="Consultation du 7ème mois" />
          </TabPanel>
          <TabPanel>
            <PatientQuestions tab={medecinQuestions?.Antécédents_obstétricaux} category="Antécédents obstétricaux" />
          </TabPanel>      
          <TabPanel>
            <PatientQuestions tab={medecinQuestions?.Antécédent_médicaux_préalables_à_la_grossesse} category="Antécédent médicaux préalables à la grossesse" />    
          </TabPanel>
          <TabPanel>
            <PatientQuestions tab={medecinQuestions?.Diathèse_hémorragique} category="Diathèse hémorragique" />
          </TabPanel>
          <TabPanel>
            <PatientQuestions tab={medecinQuestions?.Antécédents_d_incidents_anesthésiques} category="Antécédents d'incidents anesthésiques" />
          </TabPanel>       
        </TabPanels>
    </Tabs>
  )
}

export default QuestionsTabs