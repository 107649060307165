import { Box, Flex } from "@chakra-ui/layout"
import { Outlet } from "react-router"
import Sidebar from "src/components/sidebar/Sidebar"
import Navbar from "src/components/navbar/Navbar"
import { useLocation } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "src/redux/hooks"
import { useEffect } from "react"
import Loading from "src/components/loading/Loading"
import { Navigate } from "react-router-dom"
import Welcome from "./Welcome"
import { getProfile } from "src/view-models/Practitioner"

const Root = () => {
    const dispatch = useAppDispatch()
    const {pathname} = useLocation()

   const {profile,isLoading} = useAppSelector((state)=>state.profile)

   useEffect(()=>{
    const fetchProfile = async () => {
      await getProfile("medecin",dispatch)
    }
    fetchProfile()
  },[])

  if(isLoading){
      return <Loading />
  }

  if(!profile && !isLoading){
      return <Navigate to='/auth/login' />
  }

  if(profile?.roles[0] !== 'medecin'){
    if(profile?.roles[0] === 'superadministrator'){
        return <Navigate to={`/admin/patients`} />
    }else{
        return <Navigate to={`/${profile?.roles[0]}/patients`} />
    }
  }

  return (
  <div className="w-full bg-[#F0E5FF59]" >
        <Flex>
            <Box w={64} className="hidden lg:block" >
                <Sidebar />
            </Box>
            <Box w="100%" >
                <Navbar />
                {(pathname === '/medecin' || pathname === '/medecin/') && (
                    <Welcome />
                )}
                <Outlet />
            </Box>
        </Flex> 
    </div>
  )
}

export default Root