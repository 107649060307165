import { Button, HStack, Modal,ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useToast } from '@chakra-ui/react'
import {useState } from 'react'
import {unsetPatientValidating } from 'src/redux/features/question/questionSlice'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import {patietntResponse } from 'src/view-models/Question'

const ValidateResponse = ({step}:{step:number}) => {
    const dispatch = useAppDispatch()
    const {isPatientValidating,validatingPatientData} = useAppSelector((state)=>state.question)
    const [isLoading,setIsLoading] = useState(false)
    const toast = useToast()

    const handleClose = () => {
        dispatch(unsetPatientValidating())
    }

    const handleSubmit = async () => {
        setIsLoading(true)
        const res = await patietntResponse(validatingPatientData,toast,dispatch)
        if(res.status === true && step === 0){
          window.location.reload()
        }
        setIsLoading(false)
    }

    

  return (
    <Modal    
        isCentered
        onClose={handleClose}
        isOpen={isPatientValidating}
        motionPreset='slideInBottom'
        
      >
        <ModalOverlay />
        <ModalContent  >
          <ModalHeader>Etes-vous sûr de vos réponses ?</ModalHeader>
          <ModalCloseButton />
          <ModalFooter>
            <HStack>
                <Button colorScheme='red' px={8} isDisabled={isLoading} onClick={handleClose} >Non</Button>
                <Button colorScheme='blue' px={8} isLoading={isLoading} onClick={handleSubmit}>Oui</Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
  )
}

export default ValidateResponse