import Organization, { OrganizationInfo } from "src/models/Organization"
import { setOrganization, setOrganizations } from "src/redux/features/organization/organizationSlice"

export const createOrganization = async (organizationInfos:OrganizationInfo,toast:any) => {
    const response : any = await Organization.createOrganization(organizationInfos)
    if(response.status === 201) {
        toast({
            description: `Organization a été créé avec succès!`,
            status: 'success',
            duration: 3000,
            isClosable: true,
            position:'top'
          })
          return {status:true}
    }else if (response.status !==500){
        toast({
            description: `${response?.message?.message}`,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position:'top'
          })
          return {status:false}
    }else {
        toast({
        description: `quelque chose s'est mal passé !`,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position:'top'
      })
      return {status:false}
    }
}

export const updateOrganization = async (id:string,organizationInfos:OrganizationInfo,toast:any,dispatch:any) => {
    const response : any = await Organization.updateOrganization(id,organizationInfos)
    if(response.status === 200) {
        
    const response2 : any = await Organization.getOrganization(response.data.data.id)
    if(response2.status === 200) {
        dispatch(setOrganization(response2.data.data))
    }
    toast({
        description: `Organization a été mis à jour avec succès !`,
        status: 'success',
        duration: 3000,
        isClosable: true,
        position:'top'
      })
          return {status:true}
    }else if (response.status !==500){
        toast({
            description: `${response?.message?.message}`,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position:'top'
          })
          return {status:false}
    }else {
        toast({
        description: `quelque chose s'est mal passé !`,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position:'top'
      })
      return {status:false}
    }
}

export const getOrganizations = async (dispatch :any) => {
    const response : any = await Organization.getOrganizations()
    if(response.status === 200){
        dispatch(setOrganizations(response.data.data.data))
        return {status:true}
    }else {
        return {status:false}
    }
}

export const getArchivedOrganizations = async (dispatch :any) => {
    const response : any = await Organization.getArchivedOrganizations()
    if(response.status === 200){
        dispatch(setOrganizations(response.data.data.data))
        return {status:true}
    }else {
        return {status:false}
    }
}

export const getOrganization = async (id:string,dispatch:any) => {
    const response : any = await Organization.getOrganization(id)
    if(response.status === 200) {
        dispatch(setOrganization(response.data.data))
        return {status:true}
    }else{
        return {status:false}
    }
}

export const deleteOrganization = async (id:string,toast:any) => {
    const response : any = await Organization.deleteOrganization(id)
    if(response.status === 200) {
          return {status:true}
    }else if (response.status !==500){
        toast({
            description: `${response?.message?.message}`,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position:'top'
          })
          return {status:false}
    }else {
        toast({
        description: `quelque chose s'est mal passé !`,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position:'top'
      })
      return {status:false}
    }
}