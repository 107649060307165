import { MdDashboard, MdQuestionAnswer } from "react-icons/md";
import { IoIosAddCircle, IoIosSettings } from "react-icons/io";
import { FaUser } from "react-icons/fa";
import { FaUserDoctor } from "react-icons/fa6";
import { GoOrganization } from "react-icons/go";
import { RiAdminLine } from "react-icons/ri";

export const seigeBars = [
    {
        label:'Dashboard',
        value:'/sage-femme/dashboard',
        icon:MdDashboard
    },
    {
        label:'Patients',
        value:'patients',
        icon:FaUser
    },
    {
        value:'new-patient',
        label:'Nouvelle patiente',
        icon:IoIosAddCircle
    },

]

export const medecinBars = [
    {
        label:'Dashboard',
        value:'/medecin/dashboard',
        icon:MdDashboard
    },
    {
        label:'Patients',
        value:'patients',
        icon:FaUser
    },

]

export const adminBars = [
    {
        label:'Dashboard',
        value:'/admin/dashboard',
        icon:MdDashboard
    },
    {
        label:'Patients',
        value:'/admin/patients',
        icon:FaUser
    },
    {
        label:'Sages-femmes',
        value:'/admin/sage-femmes',
        icon:FaUserDoctor
    },
    {
        label:'Médecins',
        value:'/admin/doctors',
        icon:FaUserDoctor
    },
    {
        label:'Établissement',
        value:'/admin/organizations',
        icon:GoOrganization
    },
    {
        label:'Admins',
        value:'/admin/admins',
        icon:RiAdminLine
    },
    {
        label:'Réglages',
        value:'/admin/settings',
        icon:IoIosSettings
    },
    {
        label:'Questionnaire',
        value:'/admin/questions',
        icon:MdQuestionAnswer
    },

]