import { Box, Button, Checkbox, Flex, FormControl, FormLabel,HStack,Input, Radio, Stack, Text, Textarea, VStack, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useAppDispatch,} from 'src/redux/hooks'
import { useParams } from 'react-router-dom'
import { MedecinResponse } from 'src/view-models/Question'

const QuestionsForm = ({questions}:{questions:any}) => {
    const {id} = useParams()
  const dispatch = useAppDispatch()
  const toast = useToast()
  const itemsPerPage = 4;
  const lastPage = Math.ceil(questions?.length / itemsPerPage)
  const [responses,setRespones] = useState<any>([])
  const [isLoading,setIsLoading] = useState(false)

  const [currentPage, setCurrentPage] = useState(0);

  const handleSubmit = (e:any) => {
    e.preventDefault()

  };
  
  const handleClickNext = async (e:any) => {
    e.preventDefault()
    if(currentPage + 1 !== lastPage){
      const newPage = currentPage + 1;
      if (newPage < Math.ceil(questions?.length / itemsPerPage)) {
        setCurrentPage(newPage);
      }
    }else{
      const data = {
        id,
        query:responses
      }
      try {
        setIsLoading(true)
        await MedecinResponse(data,toast,dispatch,id!)
      } catch (error) {
      }finally{
        setIsLoading(false)
      }
    }  
  };
  const handleClickPrev = () => {
    const newPage = currentPage - 1;
    if (newPage >= 0) {
      setCurrentPage(newPage);
    }
  };

  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedItems = questions?.slice(startIndex, endIndex);
  useEffect(()=>{
    const values = questions?.map((q:any)=>({
      id:q.question_id,
      value:q.question,
      type:q.type,
      answers:(q?.category === 'Score' && (q?.question_id === 57 || q?.question_id === 59)) ? (typeof (q?.client_answers) === 'number' ? q?.client_answers : q?.client_answers?.length === 0 && 0 ) : q?.client_answers,
      flag_response:q.flag_response,
      bilan_bilogique:q.bilan_bilogique,
      type_flag:q.type_flag
    }))
    setRespones(values)
  },[questions])
  const handleChange = (e:any,index:number,type:string,answerId:number | null) => {
   if(type === 'input'){
    setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,answers:[{id:null,answer:e.target.value}]} : item))
   }else if(type === 'radio'){
    setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,answers:[{id:answerId,answer:e.target.value}]} : item))
   }else if (type === 'checkbox'){
    if(e.target.checked){
      setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,answers:[...item.answers,{id:answerId,answer:e.target.value}]} : item))
      if((questions[index]?.question_id === 56 || questions[index]?.question_id === 58) && questions[0]?.category === 'Score'){
        setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index + 1 ?{...item,answers:responses[index]?.answers?.length +1} : item))
      }
    }else{
      setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,answers:item.answers.filter((a:{id:number,answer:string})=>a.answer!== e.target.value)} : item))
      if((questions[index]?.question_id === 56 || questions[index]?.question_id === 58) && questions[0]?.category === 'Score'){
        setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1=== index + 1 ?{...item,answers:responses[index]?.answers?.length -1} : item))
      }
    }

   }
  }

  return (
    <Box h='100%' >
      <form className='space-y-4 flex flex-col justify-between h-full' onSubmit={handleSubmit}>
        <Flex flexDirection='column' gap={4} >
            {displayedItems?.map((question:any,index:number)=>(
            <FormControl key={index} >
                <VStack  alignItems='start'  >
                    <HStack gap={0} >
                      <FormLabel className='w-fit' mt={2}>{question.question}</FormLabel>
                      {(question?.category === 'Score' && (questions[currentPage * itemsPerPage + index]?.question_id === 57 || questions[currentPage * itemsPerPage + index]?.question_id === 59)) ? (
                        <Text color='red' >:{responses[currentPage * itemsPerPage + index]?.answers}</Text>
                      ) : null}
                    </HStack>
                    <Box w='100%' >
                    {question.type === 'text' || question.type === 'number' || question.type === 'date' ? (
                    <Input
                    boxShadow='sm'
                    className={`${question?.category === 'Score' && (questions[currentPage * itemsPerPage + index]?.question_id === 57 || questions[currentPage * itemsPerPage + index]?.question_id === 59) && "hidden"}`}
                    isDisabled={isLoading || (question?.category === 'Score' && (questions[currentPage * itemsPerPage + index]?.question_id === 57 || questions[currentPage * itemsPerPage + index]?.question_id === 59))}
                    rounded='5px'
                    type={question.type === 'date' ? 'date' : question.type === 'text' ? 'text' : 'number'}
                    size='md'
                    value={responses[currentPage * itemsPerPage + index]?.answers[0]?.answer ? responses[currentPage * itemsPerPage + index]?.answers[0]?.answer : responses[currentPage * itemsPerPage + index]?.answers ? responses[currentPage * itemsPerPage + index]?.answers : ''}
                    placeholder='Reponse...'
                    onChange={(e)=>handleChange(e,currentPage * itemsPerPage + index,'input',null)}
                    />
                    ): question?.type === "textarea" ? (
                        <Textarea
                          isDisabled={isLoading || (question?.category === 'Score' && (questions[currentPage * itemsPerPage + index]?.question_id === 57 || questions[currentPage * itemsPerPage + index]?.question_id === 59))}
                          rounded='20px'
                          rows={4}
                          placeholder='Reponse...'
                          className='w-full'
                          value={responses[currentPage * itemsPerPage + index]?.answers[0]?.answer ? responses[currentPage * itemsPerPage + index]?.answers[0]?.answer : ''}
                          onChange={(e)=>handleChange(e,currentPage * itemsPerPage + index,'input',null)}

                        />
                    ) :  question.type === 'selectbox' ? (
                        <Flex  gap={2} flexWrap='wrap'>
                        {question?.possibles_answers?.map((answer : {id:number,answer:string},index2 : any)=>(
                            <Flex  key={index2} justifyContent='center' flexWrap='wrap' gap={2} >
                                <Radio isChecked={responses[currentPage * itemsPerPage + index]?.answers[0]?.answer === answer.answer} onChange={(e)=>handleChange(e,currentPage * itemsPerPage + index,'radio',answer.id)} value={answer.answer} key={index2} >
                                {answer.answer}
                            </Radio>
                            </Flex>
                        ))}
                        </Flex>
                    ):(
                        <Flex alignItems='center' gap={4} flexWrap='wrap' >
                        {question.possibles_answers?.map((answer:{id:number,answer:string},index2:number)=>(
                            <Checkbox  colorScheme='blue' isChecked={responses[currentPage * itemsPerPage + index]?.answers?.some((s:any)=>s.answer === answer.answer)}  onChange={(e)=>handleChange(e,currentPage * itemsPerPage + index,'checkbox',answer.id)} value={answer.answer} key={index2} >{answer.answer}</Checkbox>
                        ))}
                        </Flex>
                    )
                    }
                    </Box>
                </VStack>
            </FormControl>
            ))}
        </Flex>
        <Stack direction='row' mt={4} justifyContent='flex-end' >
          <Stack mb={4} direction={['column','row']}>
            {currentPage !== 0 && (
              <Button isDisabled={isLoading} type='button' px={10} variant='outline' borderColor='black' _hover={{bg:'blue.500',borderColor:'blue.500',color:'white'}} onClick={handleClickPrev}>Précédent</Button>
            )}
            <Button isDisabled={isLoading} type='button'  px={10} colorScheme='blue'  onClick={handleClickNext} >{currentPage + 1 === lastPage ? "Valider" : `Continuer (${currentPage + 1}/${lastPage})`}</Button>
          </Stack>
          
        </Stack>
      </form>
      

    </Box>
  )
}

export default QuestionsForm