import { Box, Flex } from "@chakra-ui/layout"
import { Outlet } from "react-router"
import Navbar from "src/components/navbar/Navbar"
import Sidebar from "src/components/sidebar/Sidebar"
import { useLocation } from "react-router-dom"
import Welcome from "./Welcome"
import { useAppDispatch, useAppSelector } from "src/redux/hooks"
import Loading from "src/components/loading/Loading"
import { useEffect } from "react"
import { Navigate } from "react-router-dom"
import { getProfile } from "src/view-models/Profile"

const AdminRoot = () => {
    const dispatch = useAppDispatch()
    const {pathname} = useLocation()

   const {profile,isLoading} = useAppSelector((state)=>state.profile)

   useEffect(()=>{
      const fetchDoctor = async () => {
        await getProfile(dispatch)
      }
      fetchDoctor()
    },[])

    if(isLoading){
        return <Loading />
    }

    if(!profile && !isLoading){
        <Navigate to='/auth/login' />
    }
    if(profile?.roles[0] !== 'superadministrator'){
        return <Navigate to={`/${profile?.roles[0]}/patients`} />
    }
  return (
  <div className="w-full bg-[#F0E5FF59]" >
        <Flex>
            <Box w={64} className="hidden lg:block" >
                <Sidebar />
            </Box>
            <Box w="100%" >
                <Navbar />
                {pathname === '/admin' && (
                    <Welcome />
                )}
                <Outlet />
            </Box>
        </Flex>     
    </div>
  )
}

export default AdminRoot