export const navs = [
    {
        label:'Accueil',
        href:'#home'
    },
    {
        label:'Nos Fonctionnalités',
        href:'#functions'
    },
    {
        label:'Contactez-nous',
        href:'#contact'
    }
]