import { Box, Flex, Text, VStack } from "@chakra-ui/react"
import { Link } from "react-router-dom"

const FooterMenu = () => {
  const scrollToSection = (label:string) => {
    const section = document.getElementById(label);
    section!.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Box mt={8}>
      <Flex flexDirection={{base:'column',lg:'row'}} gap={8} color={'white'} >
        <Box  flex={1} >
          <Text fontFamily={'jakarta'} fontSize={{base:'40px',sm:'50px'}} fontWeight={600}>Focus Care</Text>
          <Text fontFamily={'inter'} fontSize={{base:'16px',sm:'18px'}} fontWeight={500}>Concentrez-vous sur l’essentiel</Text>
        </Box>
        <Flex  mt={{base:0,lg:4}} flexDirection={{base:'column',lg:'row'}} flex={1} justifyContent={'space-between'} gap={8}>
          <VStack alignItems={'start'} flex={1} gap={4} >
              <Text fontFamily={'inter'} fontSize={'18px'} fontWeight={500}>
                Import Link
              </Text>
              <Text fontFamily={'inter'} fontSize={'18px'} fontWeight={500} cursor={'pointer'} onClick={()=>scrollToSection('#contact')}>
                Contactez-Nous
              </Text>
              <Text fontFamily={'inter'} fontSize={'18px'} fontWeight={500}>
                <Link to={'https://calendly.com/focuscareio'} target="_blank">Demander une démo</Link>
              </Text>    
              <Text fontFamily={'inter'} fontSize={'18px'} fontWeight={500} cursor={'pointer'} onClick={()=>scrollToSection('#functions')}>
                Fonctionnalités principales
              </Text>          
          </VStack>
          <VStack alignItems={'start'} flex={1} gap={4} >
            <Text fontFamily={'inter'} fontSize={'18px'} fontWeight={500}>
              Aide
            </Text>
            <Text fontFamily={'inter'} fontSize={'18px'} fontWeight={500}>
              Termes et conditions
            </Text>
            <Text fontFamily={'inter'} fontSize={'18px'} fontWeight={500}>
              Politique de confidentialité
            </Text>          
          </VStack>
        </Flex>
      </Flex>
    </Box>
  )
}

export default FooterMenu