import { Flex, Text, VStack } from '@chakra-ui/react'
import { IoCheckmarkCircleOutline } from 'react-icons/io5'

const Finish = () => {
  return (
    <Flex w='100%' justifyContent='center' alignItems='center' mt={20} >
        <VStack  boxShadow='sm'  p={8}  >
            <IoCheckmarkCircleOutline className='text-5xl text-green-500'  />
            <Text>Le questionnaire a été rempli complètement.</Text>
            <Text>Merci pour votre temps</Text>
        </VStack>
    </Flex>
  )
}

export default Finish