import { Box, Button, Checkbox, Flex, FormControl, FormLabel, HStack, Input, Radio, Stack,useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import ValidateResponse from './ValidateResponse'
import { setPatientStep, setPatientValidating } from 'src/redux/features/question/questionSlice'
import { categories } from './Categories'

const Questions = ({uuid}:{uuid:string}) => {
  
  const dispatch = useAppDispatch()
  const {patientQuestions:questions,patientStep,currentCategorie} = useAppSelector((state)=>state.question)
  const [getCategory,setGetCategory] = useState<any>(categories[0])
  const [categoryResponses,setCategoryResponses] = useState<any>([])
  
  const toast = useToast()
  const [qsCategories,setQsCategories] = useState<any[]>(questions?.filter((f)=>f.category === categories[0].categ))
  const [numbQst,setNumbQst] = useState(0)
  const [startIndex,setStartIndex] = useState(numbQst)
  const [endIndex,setEndIndex] = useState(startIndex + qsCategories?.length)
  const [currentPage, setCurrentPage] = useState(0);
  const lastPage = 3
  const displayedItems = questions.slice(startIndex, endIndex);
  const [responses,setRespones] = useState<any>([])
  useEffect(()=>{
    const values = questions?.map((q)=>({
      id:q.question_id,
      value:q.question,
      type:q.type,
      answers:q.client_answers || [],
      is_required:q?.is_required,
      type_flag:q?.type_flag?.map((t:any)=>t.type),
      comment:q?.comment || null,
      flag_response:
        q?.question_id === 35 ? (q?.flag_response) : 
        (q?.client_answers ? q?.operator_flag_reponse === ">" ? (q?.type !== "checkbox" ? q?.client_answers[0]?.answer : q?.client_answers?.length) > q?.condition_flag_reponse ? q?.flag_response : null :
        q?.operator_flag_reponse === "<" ? ((q?.type !== "checkbox" ? q?.client_answers[0]?.answer : q?.client_answers?.length) < q?.condition_flag_reponse ? q?.flag_response : null ) : 
        q?.operator_flag_reponse === "=" ? ((q?.type !== "checkbox" ? q?.client_answers[0]?.answer : q?.client_answers?.length) == q?.condition_flag_reponse ? q?.flag_response : null ) : null : null),
      bilan_bilogique:
        q?.cliet_answers ? q?.operator_bilan_bilogique === ">" ? q.client_answers[0]?.answer > q?.condition_bilan_bilogique ? q.bilan_bilogique : null :
        q?.operator_bilan_bilogique === "<" ? (q.client_answers[0]?.answer < q?.condition_bilan_bilogique ? q.bilan_bilogique : null ) : 
        q?.operator_bilan_bilogique === "=" ? (q.client_answers[0]?.answer == q?.condition_bilan_bilogique ? q.bilan_bilogique : null ) : null : null
    }))
    setRespones(values)
  },[questions])

  useEffect(()=>{
    setStartIndex(numbQst)
  },[numbQst])

  useEffect(()=>{
    setEndIndex(startIndex + qsCategories?.length)
  },[startIndex])

  useEffect(()=>{
    const position = questions.findIndex((item:any)=>item.category === currentCategorie)
    setStartIndex(position)
    setEndIndex(position + qsCategories?.length)
  },[currentCategorie])

  useEffect(()=>{
    const category = categories?.find((c)=>c.categ === currentCategorie)
    setGetCategory(category)
  },[currentCategorie])

  useEffect(()=>{
    setQsCategories(questions?.filter((f)=>f.category === categories[patientStep].categ))
  },[patientStep])

  const handleSubmit = async (e:any) => {
    e.preventDefault()
    const theResponses = responses?.map((res: any, index: number) => {
      if (res?.answers?.length === 0 || !res?.answers[0]?.answer) {
        return { ...res, answers: null };
      } else {
        return res;
      }
    });
    const removePoint = theResponses?.map((res:any)=>(res?.id === 14 && res?.answers !== null) ? {...res,answers:[{id:null,answer:res?.answers[0]?.answer?.replace(/[.,]/g,"")}]} :res)
    const theValues = removePoint?.map((r:any)=>r?.id === 35 ? 
      (r?.answers?.length  > 0 ? 
        r?.answers?.find((a:any)=>a?.answer?.toLowerCase() === 'non') ? {...r,flag_response:null} : 
        r?.answers?.length === 2 ? {...r,flag_response:'Diabète préalable à la grossesse,Dysthyroïdie préalable à la grossesse'} 
        : {...r,flag_response:r?.answers[0]?.answer?.includes('diabète') ? 'Diabète préalable à la grossesse' : 'Dysthyroïdie préalable à la grossesse'}
        : {...r,flag_response:null})
    : r
    )
    const data = {
        uuid,
        query:theValues
      }
      dispatch(setPatientValidating(data))
  };

  const [emptyInputs,setEmptyInputs] = useState<any>([])
  const handleClickNext = () => {
    const requiredQurstions = categoryResponses?.filter((r:any)=>r.is_required === 1)
    const isNotAnswerAll = requiredQurstions?.filter((r:any)=>(r.answers?.length === 0 || r?.answers[0]?.answer === null || r?.answers[0]?.answer === ''))
    setEmptyInputs(isNotAnswerAll)
    if(isNotAnswerAll?.length > 0){
      return toast({
        description: 'assurez-vous de répondre à toutes les questions de cette étape!',
        status: 'warning',
        duration: 5000,
        isClosable: true,
        position:'top'
      })
    }
    if(getCategory.id < categories.length - 1){
      if(getCategory?.id === categories.length - 1){
        setQsCategories(questions?.filter((f)=>f.category === categories[0].categ))
        dispatch(setPatientStep({
          step:0,
          category:categories[0].categ
        }))
        
      }else {
        setQsCategories(questions?.filter((f)=>f.category === categories[getCategory?.id + 1].categ))

        dispatch(setPatientStep({
          step:getCategory?.id + 1,
          category:categories[getCategory?.id + 1].categ
        }))
        setNumbQst((prev)=>prev + qsCategories?.length)
        
      }
    }else{
      const theResponses = responses?.map((res: any, index: number) => {
        if (res?.answers?.length === 0 || !res?.answers[0]?.answer) {
          return { ...res, answers: null };
        } else {
          return res;
        }
      });
      const removePoint = theResponses?.map((res:any)=>(res?.id === 14 && res?.answers !== null) ? {...res,answers:[{id:null,answer:res?.answers[0]?.answer?.replace(/[.,]/g,"")}]} :res)
      const theValues = removePoint?.map((r:any)=>r?.id === 35 ? 
      (r?.answers?.length  > 0 ? 
        r?.answers?.find((a:any)=>a?.answer?.toLowerCase() === 'non') ? {...r,flag_response:null} : 
        r?.answers?.length === 2 ? {...r,flag_response:'Diabète préalable à la grossesse,Dysthyroïdie préalable à la grossesse'} 
        : {...r,flag_response:r?.answers[0]?.answer?.includes('diabète') ? 'Diabète préalable à la grossesse' : 'Dysthyroïdie préalable à la grossesse'}
        : {...r,flag_response:null})
    : r
    )
        const data = {
          uuid,
          query:theValues
        }
        dispatch(setPatientValidating(data))
    } 
  }
  const handleClickPrev = () => {
      const qsts = questions.filter((f:any)=>f.category === categories[getCategory?.id -1].categ)
      if(getCategory?.id === 0) {
        setQsCategories(questions?.filter((f:any)=>f.category === categories[categories.length - 1].categ))
        dispatch(setPatientStep({
          step:categories.length - 1,
          category:categories[categories.length - 1].categ
        }))
      }else {
        setQsCategories(questions?.filter((f:any)=>f.category === categories[categories.length - 1].categ))
        dispatch(setPatientStep({
          step:getCategory?.id -1,
          category:categories[getCategory?.id -1].categ
        }))
        setNumbQst((prev)=>prev - qsts?.length)    
      }

  };


  
  

  const handleChange = (e:any,index:number,type:string,answerId:number | null,questionId:number) => {
    const question = categoryResponses?.find((r:any)=>r.id === questionId)
    const originalQuestion = questions?.find((q:any)=>q?.question_id === questionId)
    if(type !== "checkbox") {
      if(e?.target?.value){
        const qst = emptyInputs?.filter((f : any)=>f?.id !== question?.id)
        setEmptyInputs(qst)
      }else{
        setEmptyInputs([...emptyInputs,question])
      }
    }else{
      if(e.target.checked){
        const qst = emptyInputs?.filter((f : any)=>f?.id !== question?.id)
        setEmptyInputs(qst)
      }else{
        if(question?.answers?.length === 1){
          setEmptyInputs([...emptyInputs,question])

        }
      }
    }      
   if(type === 'input'){
    setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,answers:[{id:null,answer:e.target.value}]} : item))
   }else if(type === 'radio'){
    setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,answers:[{id:answerId,answer:e.target.value}]} : item))
   }else if (type === 'checkbox'){
    if(e.target.checked){
      setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,answers:[...item.answers,{id:answerId,answer:e.target.value}]} : item))
    }else{
      setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,answers:item.answers.filter((a:{id:number,answer:string})=>a.answer!== e.target.value)} : item))
    }
   }
   if(originalQuestion?.flag_reponse){
    if(type !== "checkbox"){
      if(originalQuestion?.operator_flag_reponse === ">"){
        if(Number(e.target.value) > originalQuestion?.condition_flag_reponse){
          setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,flag_response:originalQuestion?.flag_reponse} : item))
        }else {
          setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,flag_response:null} : item))
        }
      }else if (originalQuestion?.operator_flag_reponse === "<"){
        if(Number(e.target.value) < originalQuestion?.condition_flag_reponse){
          setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,flag_response:originalQuestion?.flag_reponse} : item))
        }else {
          setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,flag_response:null} : item))
        }
      }else{
        if(e.target.value == originalQuestion?.condition_flag_reponse){
          setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,flag_response:originalQuestion?.flag_reponse} : item))
        }else {
          setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,flag_response:null} : item))
        }
      }
    }else {
      if(e.target.checked){
        if(originalQuestion?.operator_flag_reponse === ">"){
          if(question?.answers?.length + 1 > originalQuestion?.condition_flag_reponse){
            setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,flag_response:originalQuestion?.flag_reponse} : item))
          }else {
            setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,flag_response:null} : item))
          }
        }else if (originalQuestion?.operator_flag_reponse === "<"){
          if(question?.answers?.length + 1 < originalQuestion?.condition_flag_reponse){
            setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,flag_response:originalQuestion?.flag_reponse} : item))
          }else {
            setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,flag_response:null} : item))
          }
        }else{
          if(question?.answers?.length + 1 == originalQuestion?.condition_flag_reponse){
            setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,flag_response:originalQuestion?.flag_reponse} : item))
          }else {
            setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,flag_response:null} : item))
          }
        }
      }else {
        if(originalQuestion?.operator_flag_reponse === ">"){
          if(question?.answers?.length - 1 > originalQuestion?.condition_flag_reponse){
            setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,flag_response:originalQuestion?.flag_reponse} : item))
          }else {
            setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,flag_response:null} : item))
          }
        }else if (originalQuestion?.operator_flag_reponse === "<"){
          if(question?.answers?.length - 1 < originalQuestion?.condition_flag_reponse){
            setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,flag_response:originalQuestion?.flag_reponse} : item))
          }else {
            setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,flag_response:null} : item))
          }
        }else{
          if(question?.answers?.length - 1 == originalQuestion?.condition_flag_reponse){
            setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,flag_response:originalQuestion?.flag_reponse} : item))
          }else {
            setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,flag_response:null} : item))
          }
        }
      }
    }
  }
  if(originalQuestion?.bilan_bilogique){
    if(type !== "checkbox"){
      if(originalQuestion?.operator_bilan_bilogique === ">"){
        if(Number(e.target.value) > originalQuestion?.condition_bilan_bilogique){
          setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,bilan_bilogique:originalQuestion?.bilan_bilogique} : item))
        }else {
          setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,bilan_bilogique:null} : item))
        }
      }else if (originalQuestion?.operator_bilan_bilogique === "<"){
        if(Number(e.target.value) < originalQuestion?.condition_bilan_bilogique){
          setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,bilan_bilogique:originalQuestion?.bilan_bilogique} : item))
        }else {
          setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,bilan_bilogique:null} : item))
        }
      }else{
        if(e.target.value == originalQuestion?.condition_bilan_bilogique){
          setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,bilan_bilogique:originalQuestion?.bilan_bilogique} : item))
        }else {
          setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,bilan_bilogique:null} : item))
        }
      }
    }else {
      if(e.target.checked){
        if(originalQuestion?.operator_bilan_bilogique === ">"){
          if(question?.answers?.length + 1 > originalQuestion?.condition_bilan_bilogique){
            setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,bilan_bilogique:originalQuestion?.bilan_bilogique} : item))
          }else {
            setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,bilan_bilogique:null} : item))
          }
        }else if (originalQuestion?.operator_bilan_bilogique === "<"){
          if(question?.answers?.length + 1 < originalQuestion?.condition_bilan_bilogique){
            setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,bilan_bilogique:originalQuestion?.bilan_bilogique} : item))
          }else {
            setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,bilan_bilogique:null} : item))
          }
        }else{
          if(question?.answers?.length + 1 == originalQuestion?.condition_bilan_bilogique){
            setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,bilan_bilogique:originalQuestion?.bilan_bilogique} : item))
          }else {
            setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,bilan_bilogique:null} : item))
          }
        }
      }else {
        if(originalQuestion?.operator_bilan_bilogique === ">"){
          if(question?.answers?.length - 1 > originalQuestion?.condition_bilan_bilogique){
            setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,bilan_bilogique:originalQuestion?.bilan_bilogique} : item))
          }else {
            setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,bilan_bilogique:null} : item))
          }
        }else if (originalQuestion?.operator_bilan_bilogique === "<"){
          if(question?.answers?.length - 1 < originalQuestion?.condition_bilan_bilogique){
            setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,bilan_bilogique:originalQuestion?.bilan_bilogique} : item))
          }else {
            setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,bilan_bilogique:null} : item))
          }
        }else{
          if(question?.answers?.length - 1 == originalQuestion?.condition_bilan_bilogique){
            setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,bilan_bilogique:originalQuestion?.bilan_bilogique} : item))
          }else {
            setRespones((prev:any)=>prev.map((item:any,index1:number)=>index1===index ?{...item,bilan_bilogique:null} : item))
          }
        }
      }
    }
  }
  }

  const addCommentAllergies = (e:any) => {
    const updateAllergieResponse = responses?.map((r:any)=>r.id === 46 ? {...r,comment:e.target.value}:r)
    setRespones(updateAllergieResponse)
  }

  useEffect(()=>{
    const results = responses.filter((r:any)=>displayedItems?.some((d:any)=>r.id === d.question_id))
    setCategoryResponses(results)
  },[currentCategorie,displayedItems.length,responses])
  const commentQuestion = responses?.find((r:any)=>r?.id === 46)

  return (
    <Box  py={4} px={{base:4,sm:10}} my={4} >
      <ValidateResponse step={categories.length -1 - patientStep} />
      <form className='space-y-4' onSubmit={handleSubmit}>
        {displayedItems?.map((question,index)=> {
          const inputValue = responses[startIndex + index]?.answers[0]?.answer
          const res = responses?.find((r:any)=>r.id === question?.question_id)
          const isEmpty = emptyInputs?.some((i:any)=>i?.id===res?.id)
          return (
            <FormControl isRequired={question?.is_required === 1} key={index} >
                <FormLabel color={isEmpty ? "red" : "black"}  >{question.question}</FormLabel>
                {question.type === 'text' || question.type === 'number' || question.type === 'date' ? (
                  <Input
                  boxShadow='sm'
                  rounded='5px'
                  type={question.type === 'date' ? 'date' : question.type === 'text' ? 'text' : 'number'}
                  size='md'
                  value={inputValue ? inputValue : '' }
                  placeholder='Reponse...'
                  onChange={(e)=>handleChange(e,startIndex + index,'input',null,question?.question_id)}
                  />
                ): question.type === 'selectbox' ? (
                    <Stack spacing={5} direction='column'>
                      {question?.possibles_answers?.map((answer : {id:number,answer:string},index2 : any)=>(
                        <Radio isChecked={inputValue === answer.answer} onChange={(e)=>handleChange(e,startIndex + index,'radio',answer.id,question?.question_id)} value={answer.answer} key={index2} >
                            {answer.answer}
                        </Radio>
                      ))}
                    </Stack>
                ):(
                    <Box>
                        <Stack spacing={5} direction='column'>
                          {question.possibles_answers?.map((answer:{id:number,answer:string},index2:number)=>(
                            <Checkbox colorScheme='blue'  isChecked={responses[startIndex + index]?.answers?.some((s:any)=>s.answer === answer.answer)} onChange={(e)=>handleChange(e,startIndex + index,'checkbox',answer.id,question?.question_id)} value={answer.answer} key={index2} >{answer.answer}</Checkbox>
                          ))}
                        </Stack>
                        { question?.question_id === 46 && commentQuestion?.answers?.length > 0 ? (
                          <Input
                          mt={4}
                          boxShadow='sm'
                          // disabled={isSubmitting}
                          value={commentQuestion?.comment ? commentQuestion.comment: ''}
                          rounded='5px'
                          type="text"
                          size='md'
                          placeholder='Commenter ici...'
                          onChange={(e)=>addCommentAllergies(e)}
                          />
                        ):null}
                    </Box>
                )
                }
            </FormControl>
          )
        })}
        <Stack direction='row' mt={4} justifyContent='space-between' alignItems='center' >
          <Button type='submit' variant='ghost' colorScheme='blue' onClick={handleSubmit} >Enregistrer les réponses</Button>
          <Stack direction={{base:'column',md:'row'}}>
            {getCategory?.id !== 0 ? (
              <Button type='button' px={10} variant='outline' borderColor='black' _hover={{bg:'blue.500',borderColor:'blue.500',color:'white'}} onClick={handleClickPrev}>Précédent</Button>
            ) : null}
            <Button type='button' px={10} colorScheme='blue'  onClick={handleClickNext} >{currentPage + 1 === lastPage ? "Envoyer" : `Continuer (${getCategory?.id + 1}/${categories?.length})`}</Button>
          </Stack>
        </Stack>
      </form>
      

    </Box>
  )
}

export default Questions