import { motion} from "framer-motion";
import { useInView } from 'react-intersection-observer'
import { useEffect, useState } from 'react';
import { Box,Container, Flex, Image, Text  } from "@chakra-ui/react";
import grandest from '../../../../assets/grandest.webp'
import innovation from '../../../../assets/innovation.webp'

const MotionBox = motion(Box);

const Sponsors = () => {
    const { ref, inView } = useInView({threshold:0.1});
    const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (inView) {
      setIsVisible(true);
    }
  }, [inView]);
  return (
    <Box py={'4rem'} bg={'white'}>
        <Container maxW={{base:'90%',lg:'80%'}} w={{ lg: '1200px' }}>
        <MotionBox
            ref={ref} 
            initial={{ scale: 1.1, opacity: 0 }}
            animate={{ scale: inView ? 1 : 1.1, opacity: inView ? 1 : 0 }}
            transition={{ duration: 0.5 }}
        >
            <Text fontFamily={'mybody'} color={'#235EE8'} fontWeight={700} fontSize={22} mb={4} textAlign={'center'} >Nos Partenaires</Text>
                <Flex flexDirection={{base:'column',sm:'row'}} justifyContent={'center'} flex={1} alignItems={'center'} gap={4} >
                    <Image w={{base:'100%',sm:'50%'}} src={grandest} />
                    <Image w={{base:'100%',sm:'50%'}} src={innovation} />
                </Flex>
        </MotionBox>    
        </Container>
    </Box>
  )
}

export default Sponsors