import { Box, Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent,DrawerHeader, DrawerOverlay, VStack, useDisclosure } from '@chakra-ui/react'
import {useRef} from 'react'
import { FaBars } from 'react-icons/fa6'
import { categories } from './Categories'
import Categorie from './Categorie'

const MenuBar = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = useRef<any>()
  return (
    <Box display={{base:'block',lg:'none'}} >
        <Button ref={btnRef} variant='ghost' onClick={onOpen}>
            <FaBars className='text-3xl' />
        </Button>
        <Drawer
            isOpen={isOpen}
            placement='left'
            onClose={onClose}
            finalFocusRef={btnRef}
            size={{base:'xs',md:'md'}}
        >
            <DrawerOverlay />
        <DrawerContent  >
          <DrawerCloseButton />
          <DrawerHeader>Categories</DrawerHeader>
          <DrawerBody >
            <VStack alignItems='flex-start' >
                {categories?.map((c,index)=>(
                    <Categorie index={index} categorie={c} key={index}  />
                ))}
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
        
    </Box>
  )
}

export default MenuBar