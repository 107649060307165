import { Box, Flex, Text } from '@chakra-ui/react'
import { seigeBars } from '../constants'
import Itembar from '../Itembar'

const SeigeSidebar = () => {
  return (
    <Box>
        {/* <Flex flexDirection='column' my={4} gap={4} >
            {seigeBars.slice(0,1).map((bar,index)=>(
                <Itembar type='sage-femme' key={index} bar={bar} />
            ))}
        </Flex> */}
        <Text color='gray' mt={4} >Events</Text>
        <Flex flexDirection='column' my={4} gap={4} >
            {seigeBars.slice(1).map((bar,index)=>(
                <Itembar type='sage-femme' key={index} bar={bar} />
            ))}
        </Flex>
    </Box>
  )
}

export default SeigeSidebar