import { Box, Flex } from "@chakra-ui/layout"
import Searchbar from "./Searchbar"
import { IoNotificationsOutline } from "react-icons/io5";
import ProfileNav from "./ProfileNav"
import { Icon } from "@chakra-ui/icon";
import NavMobile from "./NavMobile";
import { useAppDispatch } from "src/redux/hooks";

const Navbar = () => {
  return (
    <Box px={4} py={2} className="h-auto z-50" boxShadow='sm' position='sticky' top={0} zIndex={100} backgroundColor='white'  >
      <Flex py={2} alignItems='center' justifyContent={{base:'space-between',lg:'flex-end'}} gap={4} >
        <NavMobile />
        <Box>
            <ProfileNav />
        </Box>
      </Flex>
    </Box>
  )
}

export default Navbar