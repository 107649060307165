import { Button, HStack, Modal,ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay,useToast } from '@chakra-ui/react'
import {useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {  unsetSeigefemmeValidating } from 'src/redux/features/question/questionSlice'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import { SeigefemmeResponse} from 'src/view-models/Question'

const ValidateResponse = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const {isSeigefemmeValidating,validatingSeigefemmeData} = useAppSelector((state)=>state.question)
    const [isLoading,setIsLoading] = useState(false)
    const toast = useToast()

    const handleClose = () => {
        dispatch(unsetSeigefemmeValidating())
    }

    const handleSubmit = async () => {
        setIsLoading(true)
        const res = await SeigefemmeResponse(validatingSeigefemmeData,toast,dispatch)
        if(res.status === true){
          navigate("/sage-femme/patients")
        }
        setIsLoading(false)
    }

    

  return (
    <Modal    
        isCentered
        onClose={handleClose}
        isOpen={isSeigefemmeValidating}
        motionPreset='slideInBottom'
        
      >
        <ModalOverlay />
        <ModalContent  >
          <ModalHeader>Etes-vous sûr de vos réponses ?</ModalHeader>
          <ModalCloseButton />
          <ModalFooter>
            <HStack>
                <Button colorScheme='red' px={8} isDisabled={isLoading} onClick={handleClose} >Non</Button>
                <Button colorScheme='blue' px={8} isLoading={isLoading} onClick={handleSubmit}>Oui</Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
  )
}

export default ValidateResponse