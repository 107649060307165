import HttpClient from "src/services/httpclient"

export type AdminInfo = {
    first_name? : string
    last_name? : string
    phone? : string
    email? : string
    password? : string
    c_password? :string
    roles?:string[]
}

class Admin {
    public static createAdmin = (AdminInfos:AdminInfo) => {
        return HttpClient.postWithLogin('/user/store',AdminInfos)
    }
    public static updatePofile = (id:string,AdminInfos:AdminInfo) => {
        return HttpClient.postWithLogin(`/user/update/${id}`,AdminInfos)
    }
    public static getAdmins = () => {
        return HttpClient.getWithLogin('/user/list?per_page=all')
    }
    public static getAdmin = (id : string) => {
        return HttpClient.getWithLogin(`/user/show/${id}`)
    }
    public static deleteAdmin = (id:string) => {
        return HttpClient.deleteWithLogin(`/user/delete/${id}`)
    }
    public static getProfile = () => {
        return HttpClient.getWithLogin(`/profile`)
    }
}

export default Admin