import { Box, Flex, Heading, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import QstWrapper from './components/QstWrapper'
import MenuBar from './components/MenuBar'
import Video from './Video'
import { useLocation, useParams } from 'react-router-dom'
import Finish from './components/Finish'
import PatientLoading from 'src/components/loading/PatientLoading'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import { getPatientQuestions } from 'src/view-models/Question'

const Welcome = () => {
  const {id} = useParams()
  const {pathname} = useLocation()
    const uuid = pathname.split("/")[2]
    const [errMsg,setErrMsg] = useState<string | null>(null)
    const {patientQuestions,isPatientWatchedVideo} = useAppSelector((state)=>state.question)
    const [isVideo,setIsVidoe] = useState(isPatientWatchedVideo)
    
    useEffect(()=>{
      setIsVidoe(isPatientWatchedVideo)
    },[isPatientWatchedVideo])

    const dispatch = useAppDispatch()
    const [isLoading,setIsLoading] = useState(true)
    useEffect(()=>{
        const fetchQuestions = async () => {
            const res = await getPatientQuestions(uuid,dispatch)
            if(res?.status === false && res?.message){
              setErrMsg(res.message)
            }
            setIsLoading(false)
        }
        fetchQuestions()
    },[])


    if(isLoading){
        return <PatientLoading />
    }

    if(!isLoading && errMsg){
      return <Box>
        <Text textAlign='center' fontWeight='bold' my={8} >{errMsg}</Text>
      </Box>
    }
    if(!isLoading && !errMsg && patientQuestions[0]==='vous avez déjà répondu à vos questions'){
      return <Finish />
    }


  return (
    <Box w='100%' minH='100vh' >
      <Flex position={{base:'sticky',lg:'relative'}} top={0} left={0} zIndex={100} bg='#E3F5FF' py={4} px={{base:4,sm:10,md:20,lg:0}} w='100%' alignItems='center' justifyContent={{base:'space-between',lg:'center'}} >
          <MenuBar />
          <Heading>FOCUS</Heading>
      </Flex>
      <Text mt={4} textAlign='center' px={4} w={{base:'100%',sm:'90%',md:'80%',lg:'70%'}} mx='auto' color='#182F45'>{isVideo === 0 ? 'Avant d’accéder au questionnaire, nous vous prions de regarder attentivement cette vidéo d’information concernant la prise en charge anesthésique de votre accouchement. Votre bien-être et votre sécurité sont notre priorité. Merci de prendre le temps nécessaire à la visualisation de cette vidéo' : 'Chère patiente, afin de garantir votre sécurité et celle de votre bébé lors de votre accouchement, nous vous prions de bien vouloir prendre quelques instants pour répondre à ce questionnaire d’évaluation anesthésique. Vos réponses nous sont essentielles pour personnaliser votre prise en charge et optimiser les conditions de votre accouchement. Merci pour votre collaboration'}</Text>
      {isVideo === 0 ? (
        <Video id={id!} setIsVideo={setIsVidoe} />
      ) : (
        <QstWrapper uuid={uuid} />
      )}

    </Box>
  )
}

export default Welcome