import { Box, Container } from "@chakra-ui/react"
import Navbar from "./components/navbar/Navbar"
import Hero from "./components/hero/Hero"
import Sponsors from "./components/sponsors/Sponsors"
import Benifits from "./components/benifits/Suggestion"
import Evaluation from "./components/evaluation/Evaluation"
import Suggestion from "./components/Suggestion/Suggestion"
import CPA from "./components/cpa/CPA"
import Footer from "./components/footer/Footer"
import Wrapper from "./components/Wrapper"

const Home = () => {
  return (
    <Box overflowX={'hidden'} >
        <Box bg={'#F2F7FFB2'}>
            <Wrapper>
              <Navbar />
              <Hero />
            </Wrapper>
        </Box>
        <Sponsors />
        <Benifits />
        <Evaluation />
        <Suggestion />
        <CPA />
        <Footer />
    </Box>
  )
}

export default Home