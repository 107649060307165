import { Box } from '@chakra-ui/react'
import NewOrgForm from './components/NewOrgForm'

const NewOrganization = () => {
  return (
    <Box px={4} py={2} rounded={4}>
        <Box backgroundColor='white' p={8} boxShadow='md'>
          <NewOrgForm />
        </Box>
    </Box>
  )
}

export default NewOrganization