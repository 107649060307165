import { Flex, Text } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

const NotFound = ({page}:{page:string}) => {
  return (
    <Flex flexDirection='column' alignItems='center' justifyContent='center' mt={10} >
      <Text fontWeight='bold' fontSize={20} >{page} does not exists!</Text>
      <Link to={`/admin/${page}s` }className='underline'>Back to {page}s page</Link>
    </Flex>
  )
}

export default NotFound