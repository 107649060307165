import { Flex, Text, VStack } from "@chakra-ui/react"

type Props = {
    organizations:any
}
const Organizations = ({organizations}:Props) => {
  return (
    <Flex flexDirection='column' justifyContent='start' alignItems='start' gap={0} >
        {organizations?.map((o:any)=>(
            <Text>- {o} </Text>
        ))}
    </Flex>
  )
}

export default Organizations