import { Box, Text } from "@chakra-ui/react"
import { Link } from "react-router-dom"

type Props = {
    info1:string,
    info2:any
}

const BasicInfo = ({info1,info2} : Props) => {
  return (
    <Box>
        <Text fontWeight='bold' >{info1}</Text>
        {info1 === 'Organizations' ? (
          <Box>
              {info2?.map((i : any,index : number)=>(
              <Link className="underline" to={`/admin/organizations/${i.id}`} key={index} >{i?.name}</Link>
            ))}
          </Box>
        ) : (
          <Text>{info2 ? info2 : 'null'}</Text>
        )}
    </Box>
  )
}

export default BasicInfo