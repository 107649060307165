import { Box, Button, Flex, Text, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react'
import DataTable from 'src/components/tables/DataTable';
import { colDefs } from './constants';
import { useNavigate } from 'react-router-dom';
import { disactivePractitioner, getPractitionersByRole } from 'src/view-models/Practitioner';
import Loading from 'src/components/loading/Loading';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import DeleteModal from './components/DeleteModal';
import SearchSagefemme from 'src/components/filters/SearchSageFemme';

const SeigeFemmes = () => {
  const dispatch = useAppDispatch()
  const navigation = useNavigate()
  const {practitioners:seige_femmes,status} = useAppSelector((state)=>state.practitioner)
  const [isLoading,setIsLoading] = useState(true)
    
  const goToAddPractitioner = () => {
    navigation('/admin/sage-femmes/new')
  }

  useEffect(() => {
    const fetchSageFemmes = async () => {
      if(status === 1) {
        setIsLoading(true)
        await getPractitionersByRole("sage-femme",dispatch)
      }else{
        setIsLoading(true)
        await disactivePractitioner("sage-femme",dispatch)
      }
      setIsLoading(false)
    }
    fetchSageFemmes()
  }, [status])

  if(isLoading){
    return <Loading />
  }
  
  return (
    <Box  px={4} py={2} rounded={4}  >
      <DeleteModal />
      <Flex flexDirection={{base:'column',md:'row'}} alignItems={{base:'flex-start',md:'center'}} justifyContent='space-between' gap={4} >
        <Box>
          <Text color='#4E5D78' fontWeight={700} fontSize={30} >Sages-femmes</Text>
          <Text color='#4E5D78'>Sur cette page, vous pouvez gérer tous les aspects concernant les sages-femmes </Text>
        </Box>
        <Button colorScheme='blue' onClick={goToAddPractitioner} >Ajouter une sage-femme</Button>
      </Flex>
      <VStack  alignItems='start' my={4} >
        <SearchSagefemme />
        <DataTable columns={colDefs} data={seige_femmes} />
      </VStack>
    </Box>
)
}

export default SeigeFemmes