import { Box, Button, Container, Flex, Image, Text } from "@chakra-ui/react"
import { navs } from "./constants"
import MobileNav from "./MobileNav"
import logo from '../../../../assets/logo.svg'

const Navbar = () => {
    const scrollToSection = (label:string) => {
        const section = document.getElementById(label);
        section!.scrollIntoView({ behavior: 'smooth' });
    };

    const goToCalendar = () => {
        window.open('https://calendly.com/focuscareio', '_blank', 'noopener,noreferrer')
    }

  return (
    <Box as='nav'>
        <Container maxW={{base:'90%',xl:'80%'}} w={{ lg: '1200px' }}>
            <Flex fontFamily={'mybody'} py={2} justifyContent={'space-between'} gap={4} alignItems={'center'} >
                <Image src={logo} w={{base:'195px',lg:'150px',xl:'196px'}} h={'40px'} />
                <Flex display={{base:'none',lg:'flex'}} ml={10} flex={1} alignItems={'center'} gap={10}>
                    {navs?.map((nav,index)=>(
                        <Text key={index}  fontWeight={400} color={'#182F45'} cursor={'pointer'} _hover={{color:'#235EE8'}} onClick={()=>scrollToSection(nav.href)}>{nav.label}</Text>
                    ))}
                </Flex>
                <Button display={{base:'none',lg:'block'}} w={{base:'248px',lg:'200px',xl:'248px'}} h='60px' fontWeight={400} color={'white'} bg='#515DEF' _hover={{bg:'#235EE8'}} rounded={'32px'} onClick={goToCalendar} >Programmer une démo</Button>
                <MobileNav />
            </Flex>
        </Container>
    </Box>
  )
}

export default Navbar