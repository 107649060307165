import { Box, Button,FormControl, FormErrorMessage,FormLabel,Grid,Input,InputGroup,InputLeftElement,InputRightElement,Text,Textarea,useToast } from "@chakra-ui/react"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"
import { loginUser } from "src/view-models/LoginViewModel"
import { LockIcon, EmailIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons"
import { useState } from "react"
import { Link } from "react-router-dom"

const formSchema = z.object({
    firstName: z.string().min(1, {
        message: "Le nom est obligatoire",
    }),
    lastName: z.string().min(1, {
        message: "Le prénom est obligatoire",
    }),
    email: z.string()
    .refine((value) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value), {
      message: 'Adresse e-mail invalide',
    }),
    message: z.string().min(1, {
      message: "Le nom est obligatoire",
    }),
  })
  
  type LoginFormInputs = {
    firstName:string,
    lastName:string,
    email: string;
    message: string;
  };

const ContactForm = () => {
    const toast = useToast()

    const { register, handleSubmit,formState: { errors, isSubmitting ,isValid},} = useForm<LoginFormInputs>({
        resolver: zodResolver(formSchema),
    });

    const onSubmit = async (values: z.infer<typeof formSchema>) => {
        
      }

  return (
    <Box flex={1} bg='#0C7FDA' rounded={'20px'}>
        <Box w='100%' p={4}>
            <Text fontFamily={'jakarta'} color={'white'} textAlign={'center'} fontSize={'30px'} fontWeight={500}>Contactez-nous</Text>
            <Box w='100%' mt={4} color={'white'}>
                <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
                <Grid templateColumns={{base:`repeat(1,1fr)`,md:`repeat(2,1fr)`}} gap={4} >
                    <FormControl isInvalid={!!errors.firstName}>
                            <FormLabel fontFamily={'inter'} fontSize={'18px'} fontWeight={500}>Nom</FormLabel>
                            <Input
                            disabled={isSubmitting}
                            rounded='10px'
                            bg='white'
                            type='text'
                            size='md'
                            id='firstName'
                            h={'50px'}
                            color={'black'}
                            placeholder='Entrer votre nom'
                            {...register("firstName")}
                            />
                        <FormErrorMessage>
                            {errors.firstName && errors.firstName.message}
                        </FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!errors.lastName}>
                            <FormLabel fontFamily={'inter'} fontSize={'18px'} fontWeight={500}>Prénom</FormLabel>
                            <Input
                            disabled={isSubmitting}
                            rounded='10px'
                            bg='white'
                            type='text'
                            size='md'
                            id='lastName'
                            h={'50px'}
                            color={'black'}
                            placeholder='Entrer votre prénom'
                            {...register("lastName")}
                            />
                        <FormErrorMessage>
                            {errors.lastName && errors.lastName.message}
                        </FormErrorMessage>
                    </FormControl>
                </Grid>
                <FormControl isInvalid={!!errors.email}>
                    <FormLabel fontFamily={'inter'} fontSize={'18px'} fontWeight={500}>Email</FormLabel>
                    <Input
                    disabled={isSubmitting}
                    rounded='10px'
                    bg='white'
                    size='md'
                    type="email"
                    id='email'
                    h={'50px'}
                    color={'black'}
                    placeholder='Entrer votre email'
                    {...register('email')}
                    />
                    <FormErrorMessage>
                        {errors.email && errors.email.message}
                    </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.message}>
                    <FormLabel fontFamily={'inter'} fontSize={'18px'} fontWeight={500}>Message</FormLabel>
                    <Textarea
                    disabled={isSubmitting}
                    rounded='10px'
                    bg='white'
                    size='md'
                    id='message'
                    h={'50px'}
                    color={'black'}
                    placeholder='Entrer votre message'
                    {...register('message')}
                    />
                    <FormErrorMessage>
                        {errors.message && errors.message.message}
                    </FormErrorMessage>
                </FormControl>
                <Button
                fontFamily={'inter'}
                fontWeight={600}
                type='submit'
                onClick={handleSubmit(onSubmit)}
                isLoading={isSubmitting}
                p='4'
                rounded='10px'
                h={'60px'}
                w='100%'
                bg='#54BD95'
                _hover={{bg:'#40A578'}}
                color={'white'}
                >
                Envoyer
                </Button>
            </form>

            </Box>
        </Box>
    </Box>
  )
}

export default ContactForm