import {Box, Button,Flex,FormControl, FormErrorMessage,FormLabel,Grid,HStack,Input,Radio,RadioGroup,Stack,Text,Textarea,VStack,useToast } from "@chakra-ui/react"
import { zodResolver } from "@hookform/resolvers/zod"
import { Controller,useFieldArray, useForm } from "react-hook-form"
import * as z from "zod"
import { useAppDispatch} from "src/redux/hooks"
import { updateConclusionCPA } from "src/view-models/Patient"
import { useParams } from "react-router-dom"

import { IoMdTrash } from "react-icons/io";
import { MdAddCircleOutline } from "react-icons/md";



const formSchema = z.object({
    score_asa : z.string(),
    risque_diahrse : z.string(),
    grossess_risque : z.string(),
    allergies : z.string(),
    mode_acouchement : z.any(),
    comment : z.string().optional(),
    billans_biologique: z.array(z.string()).optional(),
    flag_diathese:z.array(z.string()).optional(),
    flag_grossess:z.array(z.string()).optional(),
    flag_autre:z.array(z.string()).optional(),
    flag_asa:z.array(z.string()).optional(),

  })
  

  type ConclusionCPATypes = z.infer<typeof formSchema>

const ConclusionForm = ({cpa,setIsEdit}:{cpa:any,setIsEdit:any}) => {
  const {id} = useParams()
  const dispatch = useAppDispatch() 
  const toast = useToast()
  const {watch, register,control,setValue,handleSubmit,formState: { errors, isSubmitting }} = useForm<ConclusionCPATypes>({
    resolver: zodResolver(formSchema),
    defaultValues:{
      score_asa:cpa?.score_asa_count.toString() || 0,
      risque_diahrse:cpa?.risque_diahrse_count,
      grossess_risque:cpa?.grossess_risque_count,
      allergies:cpa?.allergies || '',
      mode_acouchement:cpa?.mode_acouchement || null,
      billans_biologique:cpa?.billans_biologique || [],
      comment:cpa?.comment  || '',
      flag_asa:cpa?.flag_asa || [],
      flag_autre:cpa?.flag_autre || [],
      flag_diathese:cpa?.flag_diathese || [],
      flag_grossess:cpa?.flag_grossess || []

    }
  });
  const {
    fields,
    append,
    update,
    remove,
  } = useFieldArray({
    control,
    //@ts-ignore
    name: "billans_biologique",
  } as never);

  const {
    fields:filedsAutre,
    append:appendAutre,
    update:updateAutre,
    remove:removeAutre,
  } = useFieldArray({
    control,
    //@ts-ignore
    name: "flag_autre",
  } as never);

  const {
    fields:filedsAsa,
    append:appendAsa,
    update:updateAsa,
    remove:removeAsa,
  } = useFieldArray({
    control,
    //@ts-ignore
    name: "flag_asa",
  } as never);

  const {
    fields:filedsDiathese,
    append:appendDiathese,
    update:updateDiathese,
    remove:removeDiathese,
  } = useFieldArray({
    control,
    //@ts-ignore
    name: "flag_diathese",
  } as never);

  const {
    fields:filedsGrossess,
    append:appendGrossess,
    update:updateGrossess,
    remove:removeGrossess,
  } = useFieldArray({
    control,
    //@ts-ignore
    name: "flag_grossess",
  } as never);

  const updateModeAccouchement = (id:number) => {
    if(id === 1){
      setValue('mode_acouchement',[{
        id:1,
        answer:"Par voie basse ('normale')"
      }])
    }else{
      setValue('mode_acouchement',[{
        id:2,
        answer:"Par césarienne"
      }])
    }
  }
  const onSubmit = async (values: z.infer<typeof formSchema>) => {
   await updateConclusionCPA(id!,values,toast,dispatch,setIsEdit)
  }
  return (
      <form  className="space-y-4 h-full overflow-y-auto" onSubmit={handleSubmit(onSubmit)}>  
        <Flex gap={6} >
          <Flex flex={1} flexDirection={'column'} gap={4} >
            <VStack boxShadow={'sm'} h='fit content' alignItems='start' rounded={'4px'} borderWidth={1} borderColor={'#ECECEECE'} px={4} py={2} >
              <FormControl isInvalid={!!errors.score_asa}>
                  <FormLabel color={'#182F45'} fontSize={'18px'} fontWeight={700} >Score ASA</FormLabel>
                  <HStack px={4}>
                    <Text color='#09101D' fontWeight={600}>Score ASA : </Text>
                    <RadioGroup onChange={(e)=>{setValue('score_asa',e)}} value={watch('score_asa')}>
                      <Stack direction={'row'}>
                        <Radio  size='lg' value='1' {...register('score_asa')}>1</Radio>
                        <Radio  size='lg' value='2' {...register('score_asa')}>2</Radio>
                        <Radio  size='lg' value='3' {...register('score_asa')}>3</Radio>
                        <Radio  size='lg' value='4' {...register('score_asa')}>4</Radio>
                        <Radio  size='lg' value='5' {...register('score_asa')}>5</Radio>
                        <Radio  size='lg' value='6' {...register('score_asa')}>6</Radio>
                      </Stack>
                    </RadioGroup>
                  </HStack>
                  <FormErrorMessage>
                    {errors.score_asa && errors.score_asa.message}
                  </FormErrorMessage>
                </FormControl>
                <VStack alignItems={'flex-start'} w='100%'>
                    <FormLabel fontSize={'18px'} fontWeight={500}>Commentaires</FormLabel>
                    <HStack alignItems={'flex-end'} w='100%'>
                      <VStack flex={1} >
                        {filedsAsa.map((item, index) => {
                          return (
                            <Flex key={item.id} flexDirection={{base:'column',md:'row'}} gap={4} w='100%' >
                              <Controller
                              control={control}
                              name={`flag_asa.${index}`}
                              render={({ field: { onChange,value} }) => (
                                  <Input
                                  value={value}
                                  onChange={onChange}
                                  placeholder='Answer'
                                  fontSize={'16px'}
                                  />
                              )}
                              />
                              <HStack>
                                <Button px={0} colorScheme="red"  type="button" onClick={() => removeAsa(index)}>
                                    <IoMdTrash />
                                </Button>
                              </HStack>
                            </Flex>
                          );
                        })}
                      </VStack>
                      <Button
                        px={2}
                        
                        colorScheme="blue"
                          type="button"
                          onClick={() => {
                            appendAsa('value');
                            updateAsa(filedsAsa.length,'')
                          }}
                        >
                        <MdAddCircleOutline size={20} />
                      </Button>
                    </HStack>   
              </VStack>
            </VStack>
            <VStack boxShadow={'sm'} h='fit-content' alignItems='start' rounded={'4px'} borderWidth={1} borderColor={'#ECECEECE'} px={4} py={2}>
              <FormControl isInvalid={!!errors.grossess_risque}>
                <FormLabel color={'#182F45'} fontSize={'18px'} fontWeight={700} > Grossesse à risque</FormLabel>
                <HStack px={4}>
                  <Text color='#09101D' fontWeight={600}> Grossesse à risque : </Text>
                  <RadioGroup onChange={(e)=>{setValue('grossess_risque',e)}} value={watch('grossess_risque')}>
                    <Stack direction={'row'}>
                      <Radio  size='lg' value='Oui' {...register('grossess_risque')}>Oui</Radio>
                      <Radio  size='lg' value='Non' {...register('grossess_risque')}>Non</Radio>
                    </Stack>
                  </RadioGroup>
                </HStack>
                  <FormErrorMessage>
                    {errors.grossess_risque && errors.grossess_risque.message}
                  </FormErrorMessage>
              </FormControl>
              <VStack alignItems={'flex-start'} w='100%'>
                <FormLabel fontSize={'18px'} fontWeight={500}>Commentaires</FormLabel>
                <HStack alignItems={'flex-end'} w='100%'>
                  <VStack flex={1} >
                    {filedsGrossess.map((item, index) => {
                      return (
                        <Flex key={item.id} flexDirection={{base:'column',md:'row'}} gap={4} w='100%' >
                          <Controller
                          control={control}
                          name={`flag_grossess.${index}`}
                          render={({ field: { onChange,value} }) => (
                              <Input
                              value={value}
                              onChange={onChange}
                              placeholder='Answer'
                              />
                          )}
                          />
                          <HStack>
                            <Button px={0} colorScheme="red"  type="button" onClick={() => removeGrossess(index)}>
                                <IoMdTrash size={20} />
                            </Button>
                          </HStack>
                        </Flex>
                      );
                    })}
                  </VStack>
                  <Button
                    px={2}
                    colorScheme="blue"
                      type="button"
                      onClick={() => {
                        appendGrossess('value');
                        updateGrossess(filedsGrossess.length,'')
                      }}
                    >
                    <MdAddCircleOutline size={20} />
                  </Button>
                </HStack>   
              </VStack>
            </VStack>
            <VStack boxShadow={'sm'} h='fit-content' alignItems='start' rounded={'4px'} borderWidth={1} borderColor={'#ECECEECE'} px={4} py={2}>
              <FormLabel color={'#182F45'} fontSize={'18px'} fontWeight={700} >Bilan Biologique</FormLabel>
              <VStack alignItems={'flex-start'} w='100%'>
                <FormLabel fontSize={'18px'} fontWeight={500}>Commentaires</FormLabel>
                <HStack alignItems={'flex-end'} w='100%'>
                  <VStack flex={1} >
                    {fields.map((item, index) => {
                      return (
                        <Flex key={item.id} flexDirection={{base:'column',md:'row'}} gap={4} w='100%' >
                          <Controller
                          control={control}
                          name={`billans_biologique.${index}`}
                          render={({ field: { onChange,value} }) => (
                              <Input
                              value={value}
                              onChange={onChange}
                              placeholder='Answer'
                              />
                          )}
                          />
                          <HStack>
                            <Button px={0} colorScheme="red"  type="button" onClick={() => remove(index)}>
                                <IoMdTrash size={20} />
                            </Button>
                          </HStack>
                        </Flex>
                      );
                    })}
                  </VStack>
                  <Button
                    px={2}
                    colorScheme="blue"
                      type="button"
                      onClick={() => {
                        append('value');
                        update(fields.length,'')
                      }}
                    >
                    <MdAddCircleOutline size={20} />
                  </Button>
                </HStack>   
              </VStack>
            </VStack>
            <VStack boxShadow={'sm'} h='fit-content' alignItems='start' rounded={'4px'} borderWidth={1} borderColor={'#ECECEECE'} px={4} py={2}>
              <FormLabel color={'#182F45'} fontSize={'18px'} fontWeight={700} >Allergies</FormLabel>
              <FormControl isInvalid={!!errors.allergies}>
                <Input
                  boxShadow='sm'
                  disabled={isSubmitting}
                  rounded='5px'
                  type='text'
                  size='md'
                  id='allergies'
                  placeholder='allergies'
                  {...register("allergies")}
                />
                <FormErrorMessage>
                  {errors.allergies && errors.allergies.message}
                </FormErrorMessage>
              </FormControl>
            </VStack>
          </Flex>
          <Flex flex={1} flexDirection={'column'} gap={4}>
            <VStack boxShadow={'sm'} h='fit-content' alignItems='start' rounded={'4px'} borderWidth={1} borderColor={'#ECECEECE'} px={4} py={2}>
              <FormControl isInvalid={!!errors.risque_diahrse}>
                <FormLabel color={'#182F45'} fontSize={'18px'} fontWeight={700} >Diathèse hémorragique</FormLabel>
                <HStack px={4}>
                  <Text color='#09101D' fontWeight={600}>Signe(s) de diathèse hémorragique : </Text>
                  <RadioGroup onChange={(e)=>{setValue('risque_diahrse',e)}} value={watch('risque_diahrse')}>
                    <Stack direction={'row'}>
                      <Radio  size='lg' value='Oui' {...register('risque_diahrse')}>Oui</Radio>
                      <Radio  size='lg' value='Non' {...register('risque_diahrse')}>Non</Radio>
                    </Stack>
                  </RadioGroup>
                </HStack>
                  <FormErrorMessage>
                    {errors.risque_diahrse && errors.risque_diahrse.message}
                  </FormErrorMessage>
              </FormControl>
              <VStack alignItems={'flex-start'} w='100%'>
                <FormLabel fontSize={'18px'} fontWeight={500}>Commentaires</FormLabel>
                <HStack alignItems={'flex-end'} w='100%'>
                  <VStack flex={1} >
                    {filedsDiathese.map((item, index) => {
                      return (
                        <Flex key={item.id} flexDirection={{base:'column',md:'row'}} gap={4} w='100%' >
                          <Controller
                          control={control}
                          name={`flag_diathese.${index}`}
                          render={({ field: { onChange,value} }) => (
                              <Input
                              value={value}
                              onChange={onChange}
                              placeholder='Answer'
                              />
                          )}
                          />
                          <HStack>
                            <Button px={0} colorScheme="red"  type="button" onClick={() => removeDiathese(index)}>
                                <IoMdTrash size={20} />
                            </Button>
                          </HStack>
                        </Flex>
                      );
                    })}
                  </VStack>
                  <Button
                    px={2}
                    colorScheme="blue"
                      type="button"
                      onClick={() => {
                        appendDiathese('value');
                        updateDiathese(filedsDiathese.length,'')
                      }}
                    >
                    <MdAddCircleOutline size={20} />
                  </Button>
                </HStack>   
              </VStack>
            </VStack>
            <VStack boxShadow={'sm'} h='fit-content' alignItems='start' rounded={'4px'} borderWidth={1} borderColor={'#ECECEECE'} px={4} py={2}>
              <FormLabel color={'#182F45'} fontSize={'18px'} fontWeight={700} >Autre(s) alerte(s)</FormLabel>
              <VStack alignItems={'flex-start'} w='100%'>
                <FormLabel fontSize={'18px'} fontWeight={500}>Commentaires</FormLabel>
                <HStack alignItems={'flex-end'} w='100%'>
                  <VStack flex={1} >
                    {filedsAutre.map((item, index) => {
                      return (
                        <Flex key={item.id} flexDirection={{base:'column',md:'row'}} gap={4} w='100%' >
                          <Controller
                          control={control}
                          name={`flag_autre.${index}`}
                          render={({ field: { onChange,value} }) => (
                              <Input
                              value={value}
                              onChange={onChange}
                              placeholder='Answer'
                              />
                          )}
                          />
                          <HStack>
                            <Button px={0} colorScheme="red"  type="button" onClick={() => removeAutre(index)}>
                                <IoMdTrash />
                            </Button>
                          </HStack>
                        </Flex>
                      );
                    })}
                  </VStack>
                  <Button
                    px={2}
                    colorScheme="blue"
                      type="button"
                      onClick={() => {
                        appendAutre('value');
                        updateAutre(filedsAutre.length,'')
                      }}
                    >
                    <MdAddCircleOutline size={20} />
                  </Button>
                </HStack>   
              </VStack>
            </VStack>
            <VStack boxShadow={'sm'} h='fit-content' alignItems='start' rounded={'4px'} borderWidth={1} borderColor={'#ECECEECE'} px={4} py={2}>
              <FormLabel color={'#182F45'} fontSize={'18px'} fontWeight={700} >Mode Acouchement</FormLabel>
              <FormControl>
                  <Stack spacing={5} direction='column'>
                      <RadioGroup defaultValue={cpa?.mode_acouchement  && cpa?.mode_acouchement[0]?.id.toString()} >
                        <VStack alignItems='start' >
                          <Radio value="1" onChange={()=>updateModeAccouchement(1)} >
                          Par voie basse ('normale')
                          </Radio>
                          <Radio value="2" onChange={()=>updateModeAccouchement(2)} >
                          Par césarienne
                          </Radio>
                        </VStack>
                      </RadioGroup>
                  </Stack>
              </FormControl>
            </VStack>
            <VStack boxShadow={'sm'} h='fit-content' alignItems='start' rounded={'4px'} borderWidth={1} borderColor={'#ECECEECE'} px={4} py={2}>
              <FormLabel color={'#182F45'} fontSize={'18px'} fontWeight={700} >Ajouter commentaire</FormLabel>
              <FormControl isInvalid={!!errors.comment}>
                <Input
                  boxShadow='sm'
                  disabled={isSubmitting}
                  rounded='5px'
                  type='text'
                  size='md'
                  id='comment'
                  placeholder='comment'
                  {...register("comment")}
                />
                <FormErrorMessage>
                  {errors.comment && errors.comment.message}
                </FormErrorMessage>
              </FormControl>
            </VStack>
          </Flex>
        </Flex>
        <HStack mt={10}>
          <Button
            type='submit'
            onClick={handleSubmit(onSubmit)}
            isLoading={isSubmitting}
            p='4'
            rounded='20px'
            colorScheme='blue'
            variant='solid'
            w='100%'
          >
          Sauvegarder
          </Button>
        </HStack>                    
      </form>
  )
}

export default ConclusionForm