import { Box,Flex,HStack,Heading,Text } from "@chakra-ui/react"
import EmailInput from "./components/EmailInput"
import { Link } from "react-router-dom"
import { ChevronLeftIcon } from "@chakra-ui/icons"
import { useState } from "react"
import ResetInputs from "./components/ResetInputs"

enum STEPS {
  step1 = 0,
  step2 = 1
}

const ForgetPassword = () => {
  const [steps,setSteps] = useState(STEPS.step1)
  return (
    <Flex minHeight='100vh' alignItems='center' justifyContent='center' >
      <Flex width={{base:'base',sm:'sm',md:'lg'}} flexDirection='column' alignItems='center' justifyContent='center' >
        <Heading fontWeight={700} >FOCUS CARE</Heading>
        <Box w='100%' p={4} my={4} className="login-shadow" >
          <Text fontSize={{base:16,sm:20}} mb={4} className="text-center" fontWeight='bold' >Mot de passe oublié</Text>
          {
            steps === 0 ? (
              <EmailInput steps={steps} setSteps={setSteps} />
            ) : (
              <ResetInputs steps={steps} setSteps={setSteps} />

            )
          }
          <Link to='/auth/login'>
            <HStack justifyContent='center' mt={4} >
              <ChevronLeftIcon color='#235EE8' />
              <Text color='#235EE8' >Retourner à la page d’acceuil</Text>
            </HStack>
          </Link>
        </Box>
      </Flex>
    </Flex>
  )
}

export default ForgetPassword