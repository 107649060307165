import { Box, Button, Flex, HStack, Text} from "@chakra-ui/react"
import QuestionComp from "./QuestionComp"
import { TbEdit } from "react-icons/tb"
import { useAppDispatch, useAppSelector } from "src/redux/hooks"
import { openMedecinQuestions } from "src/redux/features/question/questionSlice"
import MedecinFormModal from "./MedecinFormModal"

const MedecinQuestions = ({tab,category}:{tab:any,category:string}) => {
  const dispatch = useAppDispatch()
  const {medecinQuestions,isValidateCPA} = useAppSelector((state)=>state.question)
  const handleOpenModal = () => {
    const questions =  medecinQuestions?.medecin_question?.filter((q:any)=>q.category === category)
    dispatch(openMedecinQuestions({questions,category}))
  }
  return (
    <Box   bg='white' pos='relative' rounded={10} boxShadow='sm' px={8} py={4}>
        <MedecinFormModal   />
        <Box>
          <HStack mb={4} justifyContent='space-between'>
            <Text fontWeight='bold' fontSize={18} >{category}</Text>
            {isValidateCPA === 0 ? (
              <Button colorScheme='blue' rightIcon={<TbEdit />} onClick={handleOpenModal} >
                Modifier
            </Button>
            ) : null}
          </HStack>
          <Flex flexDirection='column' gap={4} >
              {tab?.map((t:any,index:number)=>(
                <QuestionComp key={index} 
                  question={t}
                  />
              ))}
          </Flex>
        </Box>
    </Box>
  )
}

export default MedecinQuestions