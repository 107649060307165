import { Box } from '@chakra-ui/react'
import NewQstForm from './components/NewQstForm'
const NewQuestion = () => {
  return (
    <Box px={4} py={2} rounded={4}>
        <Box h='80vh' backgroundColor='white' my={4} boxShadow='md' width={{base:'98%',sm:'80%',md:'70%',lg:'50%'}} mx='auto' >
          <NewQstForm />
        </Box>
    </Box>
  )
}

export default NewQuestion