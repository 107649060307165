import { Box, HStack, Image, Text } from "@chakra-ui/react"

type Props = {
    info1:string,
    info2:string,
    image? : any
}

const BasicInfo = ({info1,info2,image} : Props) => {
  return (
    <HStack>
        {image ? (
          <Image src={image} />
        ) : null}
        <Box>
          <Text fontWeight='bold' >{info1}</Text>
          <Text>{info2}</Text>
        </Box>
    </HStack>
  )
}

export default BasicInfo