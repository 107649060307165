import { Text, VStack } from '@chakra-ui/react'

const Header = () => {
  return (
    <VStack alignItems='start' p={4} backgroundColor='white' rounded={4} boxShadow='sm' >
        <Text fontSize={25} fontWeight='bold'>Ajout d’une nouvelle patiente</Text>
        <Text>Merci de répondre à l’ensemble des questions afin de finaliser la création du dossier</Text>
    </VStack>
  )
}

export default Header