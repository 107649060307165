import LoginModel, { LoginInfo } from "src/models/LoginModal"



export const loginUser = async (values:LoginInfo,toast:any) => {
  const response : any = await LoginModel.login(values)
  if(response?.status === 200){
    const access_token = response.data.data.access_token
    const refresh_token = response.data.data.refresh_token
    localStorage.setItem("access_token",JSON.stringify(access_token))
    localStorage.setItem("refresh_token",JSON.stringify(refresh_token))
    if(response.data.data.user.roles[0] === 'sage-femme'){
      window.location.assign('/sage-femme/patients')
    }else if (response.data.data.user.roles[0] === 'medecin'){
      window.location.assign('/medecin/patients')
    }else{
      window.location.assign('/admin/dashboard')
    }
    return {status:true}
  }else if(response?.status!==500){
    toast({
      description: `${response?.message?.message}`,
      status: 'error',
      duration: 3000,
      isClosable: true,
      position:'top'
    })
    return {status:false}
  }else {
      toast({
      description: `quelque chose s'est mal passé !`,
      status: 'error',
      duration: 3000,
      isClosable: true,
      position:'top'
    })
    return {status:false}
  }
}