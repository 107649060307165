import { Button,FormControl, FormErrorMessage,Icon,Input,InputGroup,InputLeftElement,InputRightElement,useToast } from "@chakra-ui/react"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"
import { EmailIcon, LockIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons"
import { useState } from "react"
import { CiShoppingTag } from "react-icons/ci";
import { resetPassword } from "src/view-models/ForgetPassword"
import { useNavigate } from "react-router-dom"


const formSchema = z.object({
    email: z.string()
    .refine((value) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value), {
      message: 'Adresse e-mail invalide',
    }),
    password: z.string().min(8, {
        message: "Le mot de passe doit contenir au moins 8 caractères",
    }),
    password_confirmation: z.string().min(8, {
        message: "Le mot de passe doit contenir au moins 8 caractères",
    }),
    otp_number:z.string().length(4,{
        message: "L'otp nomero doit contenir 4 numbers",
    })
  })
  
  type ResetInputsType = {
    email: string;
    password:string,
    password_confirmation:string,
    otp_number:string
  };

  type Props = {
    steps:number,
    setSteps:any
  }

const ResetInputs = ({steps,setSteps} : Props) => {
  const navigation = useNavigate()
    const toast = useToast()
    const [isVisible,setIsVisible] = useState(false)
    const [isVisible2,setIsVisible2] = useState(false)

  const {register, handleSubmit,formState: { errors, isSubmitting ,isValid},} = useForm<ResetInputsType>({
    resolver: zodResolver(formSchema),
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    if(values.password !== values.password_confirmation){
        return toast({
            position:'top',
            description: "Passwords must be the same",
            status: 'error',
            duration: 3000,
            isClosable: true,
          })
    }
    const response = await resetPassword(values,toast)
    if(response === true ){
      navigation('/auth/login')
    }
  }

  const switchVisble = () => {
    setIsVisible((prev)=>!prev)
  }

  const switchVisble2 = () => {
    setIsVisible2((prev)=>!prev)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
            <FormControl isInvalid={!!errors.email}>
              <InputGroup>
                <InputLeftElement pointerEvents='none'>
                  <EmailIcon color='gray.300' />
                </InputLeftElement>
                <Input
                  disabled={isSubmitting}
                  rounded='20px'
                  type='tel'
                  size='md'
                  id='email'
                  placeholder='email@federation.sa'
                  {...register("email")}
                />
              </InputGroup>
              <FormErrorMessage>
                {errors.email && errors.email.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.password}>
              <InputGroup>
                <InputLeftElement pointerEvents='none'>
                  <LockIcon color='gray.300' />
                </InputLeftElement>
                <Input
                  type={isVisible ? 'text' : 'password'}
                  disabled={isSubmitting}
                  rounded='20px'
                  size='md'
                  id='password'
                  placeholder='Votre mot de passe'
                  {...register('password')}
                />
                <InputRightElement cursor='pointer' onClick={switchVisble}>
                  {isVisible ? (
                    <ViewOffIcon color='gray.300'  fontSize={20} />
                  ) : (
                    <ViewIcon color='gray.300'  fontSize={20} />
                  )}
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage>
                {errors.password && errors.password.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.password_confirmation}>
              <InputGroup>
                <InputLeftElement pointerEvents='none'>
                  <LockIcon color='gray.300' />
                </InputLeftElement>
                <Input
                  type={isVisible2 ? 'text' : 'password'}
                  disabled={isSubmitting}
                  rounded='20px'
                  size='md'
                  id='password_confirmation'
                  placeholder='Votre mot de passe'
                  {...register('password_confirmation')}
                />
                <InputRightElement cursor='pointer' onClick={switchVisble2}>
                  {isVisible2 ? (
                    <ViewOffIcon color='gray.300'  fontSize={20} />
                  ) : (
                    <ViewIcon color='gray.300'  fontSize={20} />
                  )}
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage>
                {errors.password_confirmation && errors.password_confirmation.message}
              </FormErrorMessage>
            </FormControl>  
            <FormControl isInvalid={!!errors.otp_number}>
              <InputGroup>
                <InputLeftElement pointerEvents='none'>
                  <Icon as={CiShoppingTag} />
                </InputLeftElement>
                <Input
                   
                  disabled={isSubmitting}
                  rounded='20px'
                  type='number'
                  size='md'
                  id='otp_number'
                  placeholder='Enter otp_number number received from your email'
                  {...register("otp_number")}
                />
              </InputGroup>
              <FormErrorMessage>
                {errors.otp_number && errors.otp_number.message}
              </FormErrorMessage>
            </FormControl>
            
            <Button
              type='submit'
              onClick={handleSubmit(onSubmit)}
              isLoading={isSubmitting}
              p='4'
              rounded='20px'
              mt='6'
              w='100%'
              colorScheme='blue'
              variant='solid'
              isDisabled={!!errors.email || !!errors.password || !!errors.password_confirmation || !!errors.otp_number || !isValid}
            >
              Réinitialiser le mot de passe
            </Button>
          </form>
  )
}

export default ResetInputs