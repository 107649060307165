import rehumanisation from '../../../../assets/rehumanisation.png'
import optimisation from '../../../../assets/optimisation.png'
import planification from '../../../../assets/planification.png'
import meilleur from '../../../../assets/meilleur.png'

export const items = [
    {
        img:rehumanisation,
        title:"Réhumanisation de la prise en charge",
        text:"Amélioration de l'échange, de la réassurance et de l’information des patientes"
    },
    {
        img:optimisation,
        title:"Optimisation du temps médical",
        text:"Réduction du temps de consultation tout en augmentant le temps dédié à la réflexion médicale"
    },
    {
        img:planification,
        title:"Planification améliorée",
        text:"Créneaux de consultation adaptés à la complexité du dossier"
    },
    {
        img:meilleur,
        title:"Meilleure prise en charge",
        text:"Du temps libéré pour les dossiers les plus complexes"
    }
]