import { Icon } from "@chakra-ui/icon"
import { HStack, Text} from "@chakra-ui/layout"
import { FaCheckCircle } from "react-icons/fa";
import { Accordion, AccordionButton,AccordionItem, AccordionPanel} from "@chakra-ui/react"
import { IconType } from "react-icons"
import { useLocation, useNavigate } from "react-router"
import { FaBan } from "react-icons/fa6";
import { useAppDispatch } from "src/redux/hooks";
import { setStatus } from "src/redux/features/practitioner/practitionerSlice";
import {setStatus as patientStatus} from "src/redux/features/patient/patientSlice"
import {setStatus as organizationStatus} from "src/redux/features/organization/organizationSlice"

type Props = {
  bar:{
    label:string,
    value:string,
    icon:IconType,
  },
  type:string
}

const Itembar = ({type,bar}:Props) => {
  
  const dispatch = useAppDispatch()
  const navigation = useNavigate()
  const {pathname} = useLocation()
  const isPath = pathname.includes(bar.value)

  const goToPage = () => {
    navigation(bar.value)
  }

  const handleStatusActive = () => {
    if(type === 'admin') {
      if(bar.label !== 'Organizations'){
        if(bar.label === 'Patients'){
          dispatch(patientStatus(1))
        }else{
          dispatch(setStatus(1))
        }
        
      }else{
        dispatch(organizationStatus(1))
      }
    }else{
      dispatch(patientStatus(1))
    }
    goToPage()
  }

  const handleStatusDisactive = () => {
    if(type === 'admin') {
      if(bar.label !== 'Organizations'){
        if(bar.label === 'Patients'){
          dispatch(patientStatus(0))
        }else{
          dispatch(setStatus(0))
        }
      }else{
        dispatch(organizationStatus(0))
      }
    }else{
      dispatch(patientStatus(0))
    }
    goToPage()
  }
  return (
    <>
      {bar.label !== "Dashboard" && bar.label !== "Réglages" && bar.label !== "Questionnaire"  && bar.label !== 'Nouvelle patiente' ? (
        <Accordion allowToggle>
        <AccordionItem borderBottom='none' borderTop='none'>
            <AccordionButton borderBottom='none'>
              <HStack>
                <Icon as={bar.icon} boxSize={5} color={isPath ? '#464DFC' : 'gray.600'} />
                <Text color={isPath ? '#464DFC' : 'gray.600'} fontWeight='500' >{bar.label}</Text>
              </HStack>
            </AccordionButton>
          <AccordionPanel my={0} py={0}>
            <HStack cursor='pointer' _hover={{backgroundColor:'#F0E5FF59'}} p={2} onClick={handleStatusActive}>
              <Icon color='gray.600'  as={FaCheckCircle} boxSize={5}  />
              <Text color='gray.600' >Actifs</Text>
            </HStack>
          </AccordionPanel>
          <AccordionPanel>
            <HStack cursor='pointer' _hover={{backgroundColor:'#F0E5FF59'}} p={2} onClick={handleStatusDisactive}>
              <Icon as={FaBan} color='gray.600'  boxSize={5}  />
              <Text color='gray.600' >Inactifs</Text>
            </HStack>
          </AccordionPanel>
        </AccordionItem>
    </Accordion>
      ) : (
        <HStack cursor='pointer' _hover={{bg:isPath ? 'transparent' : '#F0E5FF59'}}  px={4} py={1} rounded={4} backgroundColor={isPath ? '#F0E5FF59' : 'transparent'} onClick={goToPage} >
          <Icon as={bar.icon} boxSize={5} color={isPath ? '#464DFC' : 'gray.600'} />
          <Text color={isPath ? '#464DFC' : 'gray.600'} fontWeight='500' >{bar.label}</Text>
      </HStack>
      )}
    </>
    
  )
}

export default Itembar