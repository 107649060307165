import { Box, Image, Text, VStack } from "@chakra-ui/react"
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

type Props = {
    img:string,
    title:string,
    text:string,
    index:number
}
const MotionBox = motion(Box);

const SugguestionComp = ({img,title,text,index}:Props) => {
  const [isVisible, setIsVisible] = useState(false);
  const { ref, inView } = useInView({threshold:0.3});

  useEffect(() => {
    if (inView) {
      setIsVisible(true);
    }
  }, [inView]);
  return (
    <MotionBox ref={ref}
    initial={{ opacity: 0, y: 30 }}
    animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 30 }}
    transition={{ duration: 1, delay: index * 0.2 }}>
        <VStack alignItems={'center'} gap={1}>
          <Image src={img} mb={2} />
          <Text fontFamily={'moneserrat'} textAlign={'center'} fontSize={'16px'} fontWeight={500} color={'#7A7D9C'} lineHeight={'30.01px'}>{title}</Text>
          <Text mt={0} fontFamily={'moneserrat'} textAlign={'center'} fontSize={'16px'} fontWeight={500} color={'#7A7D9C'} lineHeight={'30.01px'}>{text}</Text>
        </VStack>
    </MotionBox>
  )
}

export default SugguestionComp