import { Button,FormControl, FormErrorMessage,Input,InputGroup,InputLeftElement, useToast } from "@chakra-ui/react"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"
import { EmailIcon } from "@chakra-ui/icons"
import { forgetPassword } from "src/view-models/ForgetPassword"

const formSchema = z.object({
    email: z.string()
    .refine((value) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value), {
      message: 'Adresse e-mail invalide',
    }),
  })
  
  type EmailInputType = {
    email: string;
  };

  type Props = {
    steps:number,
    setSteps:any
  }

const EmailInput = ({steps,setSteps} : Props) => {
  const toast = useToast()
  const { register, handleSubmit,formState: { errors, isSubmitting ,isValid},} = useForm<EmailInputType>({
    resolver: zodResolver(formSchema),
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    const response = await forgetPassword({
      email:values.email,
    },toast)
    if(response === true){
      setSteps(steps + 1)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
            <FormControl isInvalid={!!errors.email}>
              <InputGroup>
                <InputLeftElement pointerEvents='none'>
                  <EmailIcon color='gray.300' />
                </InputLeftElement>
                <Input
                  disabled={isSubmitting}
                  rounded='20px'
                  type='tel'
                  size='md'
                  id='email'
                  placeholder='email@federation.sa'
                  {...register("email")}
                />
              </InputGroup>
              <FormErrorMessage>
                {errors.email && errors.email.message}
              </FormErrorMessage>
            </FormControl>     
            <Button
              type='submit'
              onClick={handleSubmit(onSubmit)}
              isLoading={isSubmitting}
              p='4'
              rounded='20px'
              mt='6'
              w='100%'
              colorScheme='blue'
              variant='solid'
              isDisabled={!!errors.email || !isValid}
            >
              Réinitialiser le mot de passe
            </Button>
          </form>
  )
}

export default EmailInput