import { Box, Flex, Text, VStack } from '@chakra-ui/react'
import ContactForm from './ContactForm'

const Contact = () => {
  return (
    <Flex flexDirection={{base:'column',lg:'row'}} justifyContent={'space-between'} alignItems={{lg:'center'}} gap={10}>
        <Box alignItems={'start'} flex={1} color={'white'}>
            <Text fontSize={{base:'25px',sm:'35px',md:'40px',xl:'45px','2xl':'48px'}} fontWeight={600} lineHeight={'60.48px'} fontFamily={'jakarta'}>Rejoignez la Révolution Focus Care</Text>
            <Text fontSize={{base:'16px',md:'18px'}} fontWeight={500} fontFamily={'inter'} lineHeight={'45px'} opacity={'80%'}>Vous êtes un professionnel de santé soucieux d’offrir la meilleure prise en charge à vos patientes ? Focus Care est la solution !</Text>
            <Text fontSize={{base:'16px',md:'18px'}} fontWeight={500} lineHeight={'45px'} fontFamily={'inter'} opacity={'80%'}>N’hésitez pas à nous contacter pour plus d’informations</Text>
        </Box>
        <ContactForm />
    </Flex>
  )
}

export default Contact