import { Button,FormControl, FormErrorMessage,FormLabel,Grid,HStack,Input,Select,useToast } from "@chakra-ui/react"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"
import { updateOrganization } from "src/view-models/Organization"
import { useAppDispatch } from "src/redux/hooks"

const formSchema = z.object({
    type : z.string().min(1,{
        message:"Le type est obligatoire",
    }),
    identifier : z.string().min(1,{
        message:"L'identifiant est obligatoire",
    }),
    organization_type : z.string().min(1,{
        message:"L'organization_type est obligatoire",
    }),
    numero_siret : z.string().min(1,{
        message:"Le numero_siret est obligatoire",
    }),
    phone : z.string().min(1,{
        message:"Le téléphone est obligatoire",
    }),
    name : z.string().min(1,{
        message:"Le nom est obligatoire",
    }),
    country : z.string().min(1,{
        message:"Le country est obligatoire",
    }),
    state : z.string().min(1,{
        message:"l address est obligatoire est obligatoire",
    }),
    city: z.string().min(1,{
      message:"Le city est obligatoire",
  }),
    email: z.string()
    .refine((value) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value), {
      message: 'Adresse e-mail invalide',
    }),
    postalCode : z.string().length(5,{
        message:"Le code postal doit contenir 5 caractères",
    }),
  })
  
  type EditOrganizationInputs = {
    type : string
    identifier : string
    organization_type : string
    numero_siret : string
    phone : string
    name : string
    country : string
    state : string
    city: string
    email: string
    postalCode : string
  };

const EditOrganization = ({data,setIsEdit}:any) => {
  const dispatch = useAppDispatch()
  const toast = useToast()
  const id=data?.id

  const { register, handleSubmit,formState: { errors, isSubmitting},} = useForm<EditOrganizationInputs>({
    resolver: zodResolver(formSchema),
    defaultValues:{
      type : data?.type,
      identifier : data?.identifier,
      organization_type : data?.organization_type,
      numero_siret : data?.numero_siret,
      phone : data?.phone,
      name : data?.name,
      country : data?.country,
      state : data?.state,
      city: data?.city,
      email: data?.email,
      postalCode : data?.postalCode
    }
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    const theValues = {...values,active:1}
    const response = await updateOrganization(id,theValues,toast,dispatch)
    if(response.status === true){
      setIsEdit(false)
    }
  }

  
  return (
    <form  className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
          <Grid templateColumns={{base:'repeat(1,1fr)',md:'repeat(2,1fr)',lg:'repeat(4,1fr)'}} gap={4} >
            <FormControl isRequired isInvalid={!!errors.name}>
              <FormLabel>Nom</FormLabel>
              <Input
                boxShadow='sm'
                disabled={isSubmitting}
                rounded='5px'
                type='text'
                size='md'
                id='name'
                placeholder='Nom'
                {...register("name")}
              />
              <FormErrorMessage>
                {errors.name && errors.name.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!errors.organization_type}>
              <FormLabel >Organization Type</FormLabel>
                <Select
                    placeholder='Sélectionner' boxShadow='sm' {...register("organization_type")}>
                  <option>Hopital</option>
                </Select>
              <FormErrorMessage>
                {errors.organization_type && errors.organization_type.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!errors.phone}>
              <FormLabel>Téléphone</FormLabel>
              <Input
                boxShadow='sm'
                disabled={isSubmitting}
                rounded='5px'
                type='tel'
                size='md'
                id='phone'
                placeholder='Téléphone'
                {...register("phone")}
              />
              <FormErrorMessage>
                {errors.phone && errors.phone.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!errors.email}>
              <FormLabel>Email</FormLabel>
              <Input
                boxShadow='sm'
                disabled={isSubmitting}
                rounded='5px'
                type='email'
                size='md'
                id='email'
                placeholder='email'
                {...register("email")}
              />
              <FormErrorMessage>
                {errors.email && errors.email.message}
              </FormErrorMessage>
            </FormControl>
          <FormControl isRequired isInvalid={!!errors.type}>
            <FormLabel>Type</FormLabel>
              <Input
                boxShadow='sm'
                disabled={isSubmitting}
                rounded='5px'
                type='text'
                size='md'
                id='type'
                placeholder='type'
                {...register("type")}
              />
              <FormErrorMessage>
                {errors.type && errors.type.message}
              </FormErrorMessage>
          </FormControl>
          <FormControl isRequired isInvalid={!!errors.numero_siret}>
            <FormLabel>numero siret</FormLabel>
              <Input
                boxShadow='sm'
                disabled={isSubmitting}
                rounded='5px'
                type='text'
                size='md'
                id='numero_siret'
                placeholder='numero_siret'
                {...register("numero_siret")}
              />
              <FormErrorMessage>
                {errors.numero_siret && errors.numero_siret.message}
              </FormErrorMessage>
          </FormControl>
        <FormControl isRequired  isInvalid={!!errors.identifier}>
          <FormLabel>Identifiant</FormLabel>
          <Input
            boxShadow='sm'
            disabled={isSubmitting}
            rounded='5px'
            type='text'
            size='md'
            id='identifier'
            placeholder='Identifiant'
            {...register("identifier")}
          />
          <FormErrorMessage>
            {errors.identifier && errors.identifier.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isRequired isInvalid={!!errors.country}>
          <FormLabel>Pays</FormLabel>
          <Input
              boxShadow='sm'
              disabled={isSubmitting}
              rounded='5px'
              type='text'
              size='md'
              id='country'
              placeholder='Pays'
              {...register("country")}
          />
          <FormErrorMessage>
            {errors.country && errors.country.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isRequired isInvalid={!!errors.city}>
          <FormLabel>City</FormLabel>
          <Input
              boxShadow='sm'
              disabled={isSubmitting}
              rounded='5px'
              type='text'
              size='md'
              id='city'
              placeholder='Ville'
              {...register("city")}
          />
          <FormErrorMessage>
            {errors.city && errors.city.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isRequired isInvalid={!!errors.state}>
          <FormLabel>Adress</FormLabel>
          <Input
              boxShadow='sm'
              disabled={isSubmitting}
              rounded='5px'
              type='text'
              size='md'
              id='state'
              placeholder='Adresse'
              {...register("state")}
          />
          <FormErrorMessage>
            {errors.state && errors.state.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isRequired isInvalid={!!errors.postalCode}>
          <FormLabel>Code Postal</FormLabel>
          <Input
            boxShadow='sm'
            disabled={isSubmitting}
            rounded='5px'
            type='number'
            size='md'
            id='postalCode'
            placeholder='Code Postal'
            {...register("postalCode")}
          />
          <FormErrorMessage>
            {errors.postalCode && errors.postalCode.message}
          </FormErrorMessage>
        </FormControl>
        </Grid>

        <HStack mt={10}>
          <Button
            type='submit'
            onClick={handleSubmit(onSubmit)}
            isLoading={isSubmitting}
            p='4'
            rounded='20px'
            mx='auto'         
            w='40%'
            colorScheme='blue'
            variant='solid'           
          >
            Modifier
            </Button>
        </HStack>    
          </form>
  )
}

export default EditOrganization