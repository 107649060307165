import { Box, Button, Flex, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { getQuestion } from 'src/view-models/Question'
import QuestionCard from './components/QuestionCard'
import EditQuestion from './components/EditQuestion'
import Loading from 'src/components/loading/Loading'
import NotFound from 'src/components/not-found/NotFound'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'

const QuestionDetails = () => {
  const dispatch = useAppDispatch()
  const {pathname} = useLocation()
  const {question} = useAppSelector((state)=>state.question)
  const id = pathname.split("/")[3]
  const [isEdit,setIdEdit] = useState(false)
  const [isLoading,setIsLoading] = useState(true)
  useEffect(()=>{
    const fetchQuestion = async () => {
      await getQuestion(id,dispatch)
      setIsLoading(false)
    }
    fetchQuestion()
  },[id,isEdit])

  if(isLoading){
    return <Loading />
  }

  if(!question && !isLoading){
    return <NotFound page='Question' />
  }

  return (
    <Box px={4} py={2} rounded={4}>
      <Flex mb={4} flexDirection={{base:'column',md:'row'}} alignItems={{base:'flex-start',md:'center'}} justifyContent='space-between' gap={4} >
        <Box>
          <Text color='#4E5D78' fontWeight={700} fontSize={30} >{question?.first_name}</Text>
          <Text color='#4E5D78'>Sur cette page, vous pouvez gérer tous les aspects concernant {question?.name} Questionnaire </Text>
        </Box>
        <Button colorScheme='blue' onClick={()=>setIdEdit((prev)=>!prev)} >{isEdit ? 'Annuler la modification':'Modifier Questionnaire'}</Button>
      </Flex>
      {isEdit ? (
        <Box backgroundColor='white' p={8} boxShadow='md' width={{base:'98%',sm:'80%',md:'70%',lg:'50%'}} mx='auto' >
          <EditQuestion  setIsEdit={setIdEdit} data={question} />
        </Box>
      ) : (
        <QuestionCard data={question} />
      )}
    </Box>
  )
}

export default QuestionDetails