import { Box, Flex, Text, VStack } from '@chakra-ui/react';
import  { useEffect, useState } from 'react'
import DataTable from 'src/components/tables/DataTable';
import { colDefs } from './constants';
import { getArchivedPatients, getPatients } from 'src/view-models/Patient';
import Loading from 'src/components/loading/Loading';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import DeleteModal from './components/DeleteModal';
import SearchPatients from '../../../components/filters/SearchPatients';

const Patients = () => {
  const dispatch = useAppDispatch()
  const {patients,status} = useAppSelector((state)=>state.patient)
  const [isLoading,setIsLoading] = useState(true)
  useEffect(() => {
    const fetchPatients = async () => {
      if(status === 1) {
        setIsLoading(true)
        await getPatients(undefined,dispatch)
      }else{
        setIsLoading(true)
        await getArchivedPatients(undefined,dispatch)
      }
      setIsLoading(false)
    }
    fetchPatients()
  }, [status])
  
  if(isLoading){
    return <Loading />
  }

  return (
    <Box  px={4} py={2} rounded={4}  >
      <DeleteModal />
      <Flex flexDirection={{base:'column',md:'row'}} alignItems={{base:'flex-start',md:'center'}} justifyContent='space-between' gap={4} >
        <Box>
          <Text color='#4E5D78' fontWeight={700} fontSize={30} >Patients</Text>
          <Text color='#4E5D78'>Sur cette page, vous pouvez trouver la liste des patients actifs de votre établissement </Text>
        </Box>
      </Flex>
      <VStack  alignItems='start' my={4} >
        <SearchPatients />
        <DataTable columns={colDefs} data={patients} />
      </VStack>
    </Box>
)
}

export default Patients