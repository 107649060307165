import { Box,Grid } from "@chakra-ui/react"
import BasicInfo from "./BasicInfo"

type Props = {
    data:any
}

const QuestionCard = ({data}:Props) => {
  return (
    <Box px={4} py={2} backgroundColor='white' rounded={10} boxShadow='md' width={{base:'98%',sm:'80%',md:'70%',lg:'50%'}} mx='auto' >
        <Grid templateColumns={{base:'repeat(1,1fr)'}} gap={4} >
            <BasicInfo info1="Question" info2={data?.question} />
            <BasicInfo info1="Question à" info2={data?.question_to} />
            <BasicInfo info1="Réponses" info2={data?.possibles_answers} />
            <BasicInfo info1="type" info2={data?.type} />
            <BasicInfo info1="Category" info2={data?.category} />
            <BasicInfo info1="condition_flag_reponse" info2={data?.condition_flag_reponse} />
            <BasicInfo info1="operator_flag_reponse" info2={data?.operator_flag_reponse} />
            <BasicInfo info1="flag_reponse" info2={data?.flag_reponse} />
            <BasicInfo info1="condition_bilan_bilogique" info2={data?.condition_bilan_bilogique} />
            <BasicInfo info1="operator_bilan_bilogique" info2={data?.operator_bilan_bilogique} />
            <BasicInfo info1="bilan_bilogique" info2={data?.bilan_bilogique} />
        </Grid>
    </Box>
  )
}

export default QuestionCard