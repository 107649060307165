import { Box, Icon, Text, VStack } from "@chakra-ui/react"
import { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

type Props = {
    status:string | null,
    color:string,
    title:string,
    resultats:any,
    cpaFlag:any,
    isUpdated:number
}

const Diagnosis = ({color,title,resultats,status,cpaFlag,isUpdated} : Props) => {
 
    const [isShow,setIsShow] = useState(false)

    const showMore = () => {
        setIsShow((prev)=>!prev)
    }

  return (
    <Box  flex={1} backgroundColor='white' rounded={10} h='fit-content'>
        <Box p={3} >
            <Box   position='relative' backgroundColor={color === 'red' ? '#EB5757' : color === 'green' ? '#41C174' : color!='white' ? color : 'white'} px={2} color='white' py={2} rounded={'8px'} >
                <Text fontWeight={600} color={color === 'white' ? 'black' : color==='#FFA85A' ? 'black' : 'white'}>{title}</Text>
                {status? (
                    <Text color={color==='#FFA85A' ? 'black' : 'white'}>{status}</Text>
                ): null}
                <Icon as={isShow ? IoIosArrowUp : IoIosArrowDown} position='absolute' fontSize={25} bottom={-3} right={-1} zIndex='50' color='white' backgroundColor='blue.500' rounded='100%' cursor='pointer' onClick={showMore} />
            </Box>
            {isShow  ? (
                <VStack alignItems='start'  my={4} >
                    {isUpdated === 1 ? (
                        cpaFlag?.map((f:any,index:number)=>(
                            <Text color='black' fontWeight='bold' key={index}>{f}</Text>
                        ))
                    ) : resultats?.filter((f:any)=>f?.flag_reponse).map((resultat : any,index:number)=>(
                        <Text key={index} color='black' fontWeight='bold' px={4} >{resultat.flag_reponse}</Text>
                    ))}
                </VStack>
            ) : null}

        </Box>
    </Box>
  )
}

export default Diagnosis