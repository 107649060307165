import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react'
import DataTable from 'src/components/tables/DataTable';
import { colDefs } from './constants';
import { useNavigate } from 'react-router-dom';
import { getQuestions } from 'src/view-models/Question';
import Loading from 'src/components/loading/Loading';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import DeleteModal from './components/DeleteModal';

const Questions = () => {
  const dispatch = useAppDispatch()
  const navigation = useNavigate()
  const {questions} = useAppSelector((state)=>state.question)
  const [isLoading,setIsLoading] = useState(true)
    
  const goToAddQuestion = () => {
    navigation('/admin/questions/new')
  }

  useEffect(() => {
    const fetchQuestions = async () => {
      await getQuestions(dispatch)
      setIsLoading(false)
    }
    fetchQuestions()
  }, [])

  if(isLoading){
    return <Loading />
  }
  
  return (
    <Box  px={4} py={2} rounded={4}  >
      <DeleteModal />
      <Flex flexDirection={{base:'column',md:'row'}} alignItems={{base:'flex-start',md:'center'}} justifyContent='space-between' gap={4} >
        <Box>
          <Text color='#4E5D78' fontWeight={700} fontSize={30} >Questions</Text>
          <Text color='#4E5D78'>Sur cette page, vous pouvez gérer tous les aspects concernant Questionnaires </Text>
        </Box>
        <Button colorScheme='blue' onClick={goToAddQuestion} >Ajouter Un Questionnaire</Button>
      </Flex>
      <Box my={8}  >
        <DataTable columns={colDefs} data={questions} />
      </Box>
    </Box>
)
}

export default Questions