import { Box} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { getPatient } from 'src/view-models/Patient'
import PatientCard from './components/PatientCard'
import Loading from 'src/components/loading/Loading'
import NotFound from 'src/components/not-found/NotFound'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'

const PatientDetails = () => {
  const dispatch = useAppDispatch()
  const {pathname} = useLocation()
  const id = pathname.split("/")[3]
  const {patient} = useAppSelector((state)=>state.patient)
  const [isLoading,setIsLoading] = useState(true)

  useEffect(()=>{
    const fetchPatient = async () => {
      await getPatient(id,undefined,dispatch)
      setIsLoading(false)
    }
    fetchPatient()
  },[id])

  if(isLoading){
    return <Loading />
  }

  if(!patient && !isLoading){
    return <NotFound page='patient' />
  }

  return (
    <Box px={4} py={2} rounded={4}>
      <PatientCard data={patient} />
    </Box>
  )
}

export default PatientDetails