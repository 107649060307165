import {createSlice} from '@reduxjs/toolkit'


type StateType = {
    isOpen:boolean
    isOpenDelete : boolean
    roles:any[],
    permissions:any[]
    roleId:any
    role:any

}

const initialState : StateType = {
    isOpen:false,
    isOpenDelete:false,
    roleId:null,
    roles:[],
    permissions:[],
    role:null
}


const roleSlice = createSlice({
    name:"role",
    initialState,
    reducers:{
        openModal : (state,action) => {
            state.isOpen = true
            state.roleId = action.payload ? action.payload : null
        },
        closeModal : (state) => {
            state.isOpen = false
            state.roleId = null
            state.role = null
        },
        setRoles : (state,action) => {
            state.roles = action.payload
        },
        setRole:(state,action)=>{
            state.role = action.payload
        },
        setPermissions : (state,action) => {
            state.permissions = action.payload
        },
        openDeleteModal : (state,action) => {
            state.isOpenDelete = true
            state.roleId = action.payload
        },
        closeDeleteModal : (state) => {
            state.isOpenDelete = false
            state.roleId = null
        },
    }

})

export const {openModal,closeModal,openDeleteModal,closeDeleteModal,setRoles, setRole, setPermissions} = roleSlice.actions

export default roleSlice.reducer

