import { extendTheme } from '@chakra-ui/react'
import '@fontsource/poppins/700.css'
import '@fontsource/poppins/400.css'
import '@fontsource/poppins/600.css'
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/plus-jakarta-sans/800.css'
import '@fontsource/plus-jakarta-sans/500.css'
import '@fontsource/plus-jakarta-sans/600.css'
import '@fontsource/inter/500.css'
import '@fontsource/inter/600.css'





const theme = extendTheme({
  fonts: {
    mybody:'Poppins',
    moneserrat:'Montserrat',
    jakarta:'Plus Jakarta Sans',
    inter:'Inter'
  },
})

export default theme