import Practitioner, { PractitionerInfo } from "src/models/Practitioner"
import { setPractitioner, setPractitioners } from "src/redux/features/practitioner/practitionerSlice"
import { endLoading, setProfile } from "src/redux/features/profile/ProfileSlice"

export const createPractitioner = async (PractitionerInfos:PractitionerInfo,toast:any) => {
    const response : any = await Practitioner.createPractitioner(PractitionerInfos)
    if(response.status === 201) {
        toast({
            description: `Practitioner a été créé avec succès!`,
            status: 'success',
            duration: 3000,
            isClosable: true,
            position:'top'
          })
          return {status:true}
    }else if (response.status === 500){
        toast({
            description: `quelque chose s'est mal passé !`,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position:'top'
          })
          return {status:false}
    }else {
        toast({
            description: `${response?.message?.message}`,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position:'top'
          })
          return {status:false}
    }
}

export const updatePractitioner = async (id:string,PractitionerInfos:PractitionerInfo,toast:any,dispatch:any) => {
    const response : any = await Practitioner.updatePractitioner(id,PractitionerInfos)
    if(response.status === 200) {
        
    const response2 :any = await Practitioner.getPractitioner(response.data.data.id)
          if(response2.status === 200){
            dispatch(setPractitioner(response2.data.data))
          }
          toast({
            description: `Practitioner a été mis à jour avec succès !`,
            status: 'success',
            duration: 3000,
            isClosable: true,
            position:'top'
          })
          return {status:true}
    }else if (response.status !==500){
        toast({
            description: `${response?.message?.message}`,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position:'top'
          })
          return {status:false}
    }else {
        toast({
        description: `quelque chose s'est mal passé !`,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position:'top'
      })
      return {status:false}
    }
}

export const getPractitioners = async (dispatch:any) => {
    const response : any = await Practitioner.getPractitioners()
    if(response.status === 200 ){
        dispatch(setPractitioners(response.data.data.data))
        return {status:true}
    }else {
        return {status:false}
    }
}

export const getPractitioner = async (id:string,dispatch:any) => {
    const response : any = await Practitioner.getPractitioner(id)
    if(response.status === 200){
        dispatch(setPractitioner(response.data.data))
        return {status:true}
    }else {
        return {status:false}
    }
}

export const getPractitionersByRole = async (role:string,dispatch:any) => {
    const response : any = await Practitioner.getPractitionersByRole(role)
    if(response.status === 200){
        dispatch(setPractitioners(response.data.data.data))
        return {status:true}
    }else {
        return {status:false}
    }
}

export const disactivePractitioner = async (role:string,dispatch:any) => {
    const response : any = await Practitioner.archivedPractioner(role)
    if(response.status === 200){
        dispatch(setPractitioners(response.data.data.data))
        return {status:true}
    }else {
        return {status:false}
    }
}

export const getProfile = async (role:string,dispatch:any) => {
    const response : any = await Practitioner.getProfile(role)
    if(response.status === 200) {
        dispatch(setProfile(response.data.data))
        dispatch(endLoading())
        return {status:true}
    }else{
        localStorage.removeItem("access_token")
        localStorage.removeItem("refresh_token")
        window.location.assign('/auth/login')
        dispatch(endLoading())
        return {status:false}
    }
}

export const deletePractitioner = async (id:string,toast:any) => {
    const response : any = await Practitioner.deletePractitioner(id)
    if(response.status === 200) {
          return {status:true}
    }else if (response.status !==500){
        toast({
            description: `${response?.message?.message}`,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position:'top'
          })
          return {status:false}
    }else {
        toast({
        description: `quelque chose s'est mal passé !`,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position:'top'
      })
      return {status:false}
    }
}

export const filterSagefemme = async (values:any,dispatch:any,toast:any) => {
    const response : any = await Practitioner.filterSagefemme(values)
    if(response.status === 200) {
        dispatch(setPractitioners(response.data.data.data))
        return {status:true}
    }else {
        toast({
            description: `quelque chose s'est mal passé !`,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position:'top'
          })
        return {status:false}
    }
}

export const filterMedecin = async (values:any,dispatch:any,toast:any) => {
    const response : any = await Practitioner.filterMedecin(values)
    if(response.status === 200) {
        dispatch(setPractitioners(response.data.data.data))
        return {status:true}
    }else {
        toast({
            description: `quelque chose s'est mal passé !`,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position:'top'
          })
        return {status:false}
    }
}