import { Box } from '@chakra-ui/react'
import NewSeigeForm from './components/NewSeigeFemme'

const NewSeigeFemme = () => {

  return (
    <Box px={4} py={2} rounded={4}>
        <Box backgroundColor='white' p={8} boxShadow='md' >
          <NewSeigeForm />
        </Box>
    </Box>
  )
}

export default NewSeigeFemme