import { Flex, HStack, Icon, IconButton, Menu, MenuButton, MenuItem, MenuList, Portal, Text } from '@chakra-ui/react'
import { FaEdit } from 'react-icons/fa'
import { FaDeleteLeft} from 'react-icons/fa6'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { openDeleteModal } from 'src/redux/features/practitioner/practitionerSlice';
import { HamburgerIcon } from '@chakra-ui/icons';

type Props = {
    id:string
}

const ActionTable = ({id}:Props) => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const {status} = useAppSelector((state)=>state.practitioner)

    const goToProfile = () => {
        navigate(`/admin/sage-femmes/${id}`)
    }
    
    const handleOpen = () => {
      dispatch(openDeleteModal(id))
    }

    if(status === 0 ){
      return null
    }

  return (
    <Flex justifyContent='center' alignItems='center' h='100%' >
      <Menu>
        <MenuButton as={IconButton}
          aria-label='Options'
          icon={<HamburgerIcon />}
          variant='outline'
        />
      <Portal>
        <MenuList>
          <MenuItem>
            <HStack w='100%' color='red.500' cursor='pointer' _hover={{color:'red.300'}}  onClick={handleOpen} >
              <Icon as={FaDeleteLeft} />
              <Text>Suprimer</Text>
            </HStack>
          </MenuItem>
          <MenuItem >
            <HStack w='100%' color='blue.500' cursor='pointer' _hover={{color:'blue.300'}} onClick={goToProfile}>
              <Icon as={FaEdit}  />
              <Text>Modifier</Text>
            </HStack>
          </MenuItem>
        </MenuList>
      </Portal>  
    </Menu>
  </Flex>
  )
}

export default ActionTable