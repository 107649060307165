import Admin, { AdminInfo } from "src/models/Admin"
import { setAdmin, setAdmins } from "src/redux/features/admin/adminSlice"
import { endLoading, setProfile } from "src/redux/features/profile/ProfileSlice"

export const createAdmin = async (AdminInfos:AdminInfo,toast:any) => {
    const response : any = await Admin.createAdmin(AdminInfos)
    if(response.status === 201) {
        toast({
            description: `Le patient a été créé avec succès!`,
            status: 'success',
            duration: 3000,
            isClosable: true,
            position:'top'
          })
          return {status:true}
    }else if (response.status !==500){
        toast({
            description: `${response?.message?.message}`,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position:'top'
          })
          return {status:false}
    }else {
        toast({
        description: `quelque chose s'est mal passé !`,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position:'top'
      })
      return {status:false}
    }
}

export const updatePofile = async (id:string,AdminInfos:AdminInfo,toast:any,dispatch : any) => {
    const response : any = await Admin.updatePofile(id,AdminInfos)
    if(response.status === 201) {
       
    const response2 :any = await Admin.getAdmin(id)
          dispatch(setAdmin(response2?.data.data))
          toast({
            description: `Admin a été mis à jour avec succès !`,
            status: 'success',
            duration: 3000,
            isClosable: true,
            position:'top'
          })
          return {status:true}
    }else if (response.status !==500){
        toast({
            description: `${response?.message?.message}`,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position:'top'
          })
          return {status:false}
    }else {
        toast({
        description: `quelque chose s'est mal passé !`,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position:'top'
      })
      return {status:false}
    }
}

export const getAdmins = async (dispatch:any) => {
    const response : any = await Admin.getAdmins()
    if(response.status === 200) {
        dispatch(setAdmins(response.data.data.data))
        return {status:true}
    }else{
        return {status:false}
    }
}

export const getAdmin = async (id:string,dispatch:any) => {
    const response : any = await Admin.getAdmin(id)
    if(response.status === 200) {
        dispatch(setAdmin(response.data.data))
        return {status:true}
    }else{
        return {status:false}
    }
}

export const deleteAdmin = async (id:string,toast:any) => {
    const response : any = await Admin.deleteAdmin(id)
    if(response.status === 200) {
          return {status:true}
    }else if (response.status !==500){
        toast({
            description: `${response?.message?.message}`,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position:'top'
          })
          return {status:false}
    }else {
        toast({
        description: `quelque chose s'est mal passé !`,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position:'top'
      })
      return {status:false}
    }
}

export const getProfile = async (role:string,dispatch:any) => {
    const response : any = await Admin.getProfile()
    if(response.status === 200) {
        dispatch(setProfile(response.data.data))
        dispatch(endLoading())
        return {status:true}
    }else{
        dispatch(endLoading())
        return {status:false}
    }
}

