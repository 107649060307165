import Role, { RoleInfo } from "src/models/Role"
import { setPermissions, setRole, setRoles } from "src/redux/features/role/roleSlice"

export const createRole = async (RoleInfos:RoleInfo,toast:any,dispatch:any) => {
    const response : any = await Role.createRole(RoleInfos)
    if(response.status === 201) {
        toast({
            description: `Role a été créé avec succès!`,
            status: 'success',
            duration: 3000,
            isClosable: true,
            position:'top'
          })
        const response2 : any = await Role.getRoles()
        if(response2.status === 200) {
             dispatch(setRoles(response2.data.data.data))
        }
          return {status:true}
    }else if (response.status !==500){
        toast({
            description: `${response?.message?.message}`,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position:'top'
          })
          return {status:false}
    }else {
        toast({
        description: `quelque chose s'est mal passé !`,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position:'top'
      })
      return {status:false}
    }
}

export const updateRole = async (id:string,RoleInfos:RoleInfo,toast:any,dispatch:any) => {
    const response : any = await Role.updateRole(id,RoleInfos)
    if(response.status === 201) {
        toast({
            description: `Role a été mis à jour avec succès !`,
            status: 'success',
            duration: 3000,
            isClosable: true,
            position:'top'
          })
          const response2 : any = await Role.getRoles()
        if(response2.status  === 200) {
            dispatch(setRoles(response2.data.data.data))
        }
          return {status:true}
    }else if (response.status !==500){
        toast({
            description: `${response?.message?.message}`,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position:'top'
          })
          return {status:false}
    }else {
        toast({
        description: `quelque chose s'est mal passé !`,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position:'top'
      })
      return {status:false}
    }
}

export const getRoles = async (dispatch?:any) => {
    const response : any = await Role.getRoles()
    if(response.status === 200) {
        dispatch(setRoles(response.data.data.data))
        return {status:true}
    }else {
        return {status:false}
    }
}

export const getPermissions = async (dispatch?:any) => {
    const response : any = await Role.getPermissions()
    if(response.status === 200) {
        dispatch(setPermissions(response.data.data))
        return response.data.data
    }else {
        return {status:false}
    }
}

export const getRole = async (id:string,dispatch:any) => {
    const response : any = await Role.getRole(id)
    if(response.status === 200) {
        dispatch(setRole(response.data.data))
        return response.data.data
    }else{
        return {status:false}
    }
}

export const deleteRole = async (id:string,toast:any,dispatch:any) => {
    const response : any = await Role.deleteRole(id)
    if(response.status === 200) {
        const response2 : any = await Role.getRoles()
        if(response2.status  === 200) {
            dispatch(setRoles(response2.data.data.data))
        }
          return {status:true}
    }else if (response.status !==500){
        toast({
            description: `${response?.message?.message}`,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position:'top'
          })
          return {status:false}
    }else {
        toast({
        description: `quelque chose s'est mal passé !`,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position:'top'
      })
      return {status:false}
    }
}