import { Button, Flex, FormControl, FormErrorMessage, FormLabel, Grid, Input,useToast } from '@chakra-ui/react'

import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"
import { useAppDispatch} from 'src/redux/hooks'
import { filterSagefemme } from 'src/view-models/Practitioner'

const formSchema = z.object({
  family: z.string().optional(),
  given: z.string().optional(),
  birthDate: z.string().optional(),
})

type CommentFormInputs = {
    family: string;
    given: string;
    birthDate: string;
};


const SarchSagefemme = () => {
  const dispatch = useAppDispatch()
  const toast = useToast()
  const { register, handleSubmit,formState: { errors, isSubmitting},} = useForm<CommentFormInputs>({
    resolver: zodResolver(formSchema),
    defaultValues:{
      family:"",
      given:"",
      birthDate:""
    }
  });


  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    const objectToQueryString = (obj:any) => {
        return Object.keys(obj)
          .filter(key => obj[key] !== undefined && obj[key] !== null && obj[key] !== '')
          .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]))
          .join('&');
      }
    const queryString = objectToQueryString(values);
    await filterSagefemme(queryString,dispatch,toast)
  }


  return (
    <form className='w-full' onSubmit={handleSubmit(onSubmit)} >
          <Grid w='100%' px={2} py={2} bg='white' rounded={10} boxShadow='sm' templateColumns={{base:'repeat(1,1fr)',md:'repeat(2,1fr)',lg:'repeat(4,1fr)'}} gap={4} >
            <FormControl isInvalid={!!errors.family}>
                <FormLabel>Le Nom</FormLabel>
                    <Input
                    disabled={isSubmitting}
                    rounded='10px'
                    size='md'
                    id='family'
                    placeholder='Le Nom'
                    {...register('family')}
                    />
                <FormErrorMessage>
                    {errors.family && errors.family.message}
                </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.given}>
                <FormLabel>Prénom</FormLabel>
                    <Input
                    disabled={isSubmitting}
                    rounded='10px'
                    size='md'
                    id='given'
                    placeholder='Prénom'
                    {...register('given')}
                    />
                <FormErrorMessage>
                    {errors.given && errors.given.message}
                </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.birthDate}>
                <FormLabel>Date de naissance</FormLabel>
                <Input
                disabled={isSubmitting}
                rounded='10px'
                size='md'
                type='date'
                id='birthDate'
                placeholder='Prénom'
                {...register('birthDate')}
                />
                <FormErrorMessage>
                    {errors.birthDate && errors.birthDate.message}
                </FormErrorMessage>
            </FormControl>
            <Flex alignItems='flex-end' w='100%' >    
                <Button w='100%' type='submit' isDisabled={isSubmitting} colorScheme='blue'>Rechercher</Button>
            </Flex>
          </Grid>
        
        </form>
  )
}

export default SarchSagefemme