import ActionTable from "./components/ActionTable";

export const colDefs = [
  { headerName:"Question",
    field: "question",
  },
  { 
    headerName:"Question à",
    field: "question_to",
  },
  { 
    headerName:"Type",
    field: "type",
  },
  { 
    headerName:"flag reponse",
    field: "flag_reponse",
    cellRenderer:(params : any)=>{
      return params.data.flag_reponse ? params.data.flag_reponse : "null"
    }
   },
   { 
    headerName:"bilan bilogique",
    field: "bilan_bilogique",
    cellRenderer:(params : any)=>{
      return params.data.bilan_bilogique ? params.data.bilan_bilogique : "null"
    }
   },
   {
    field:'Actions',
    cellRenderer:ActionTable,
    cellRendererParams: (params : any) => {
      const rowData = params.data;
      return {
        id: `${rowData.question_id}`,
        name:`${rowData.name}`
      };
    },
    filter:false,
  },
];