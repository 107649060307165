import { Box,Heading} from '@chakra-ui/layout'
import SeigeSidebar from './siegeFemme/SiegeSidebar'
import AdminSidebar from './admin/AdminSidebar'
import { useAppSelector } from 'src/redux/hooks'
import MedecinSideBars from './medecin/MedecinBars'

const Sidebar = () => {
  const {profile} = useAppSelector((state)=>state.profile)
  return (
    <Box p={4} position='sticky' bg='white' h='100vh' top={0} overflowY='auto' >
        <Heading as='h4' fontSize={30}>FOCUS</Heading>        
             { profile?.roles[0] === 'sage-femme' ? (
              <SeigeSidebar />      
              ) : profile?.roles[0] === 'medecin' ? (
                <MedecinSideBars />
              ) : (
                <AdminSidebar />
              )}
            
    </Box>
  )
}

export default Sidebar