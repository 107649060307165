import { Box, Container, Flex, Image, Text, VStack } from "@chakra-ui/react"
import doctor from '../../../../assets/doctor.webp'
import { motion} from "framer-motion";
import { useInView } from 'react-intersection-observer'
import { useEffect, useState } from 'react';
const MotionBox = motion(Box);

const Evaluation = () => {
    const { ref, inView } = useInView();
    const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (inView) {
      setIsVisible(true);
    }
  }, [inView]);
  
  return (
    <Box py={'4rem'}>
        <Container maxW={{base:'90%',lg:'80%'}} w={{ lg: '1200px' }}>
            <MotionBox
                ref={ref} 
                initial={{ x:-100 , opacity: 0 }}
                animate={{ x: inView ? 0 : -100, opacity: inView ? 1 : 0 }}
                transition={{ duration: 1 }}
            >
                <Flex flexDirection={{base:'column',lg:'row'}}  justifyContent={'space-between'} alignItems={'center'} gap={4}>
                    <Box flex={1}>
                        <Image  w={'100%'} src={doctor} /> 
                    </Box>  
                    <VStack flex={1} alignItems={'flex-start'} gap={4} >
                        <Text lineHeight={'50.14px'} fontSize={{base:'25px',sm:'30px',md:'35px','2xl':'49px'}} fontWeight={800} fontFamily={'jakarta'} color={'#182F45'} >Pré-évaluation anesthésique instantanée</Text>
                        <Text lineHeight={'35px'} fontSize={{base:'18px',sm:'22px',lg:'20px',xl:'22px'}} fontWeight={400}  fontFamily={'moneserrat'} color={'#555B64'}>D'un seul coup d'œil sur notre tableau de bord ergonomique et intuitif, le médecin anesthésiste évalue l’état de santé de sa patiente et obtient une vue d’ensemble exhaustive des éléments clés de toute évaluation anesthésique en obstétrique</Text>
                    </VStack>
                </Flex>
            </MotionBox>
        </Container>
    </Box>
  )
}

export default Evaluation