import {createSlice} from '@reduxjs/toolkit'


type StateType = {
    isOpenDelete : boolean
    organizationId:null | string
    organization : null | any
    organizations:any[]
    status:number
}

const initialState : StateType = {
    isOpenDelete:false,
    organizationId:null,
    organization:null,
    organizations:[],
    status:1
}


const organizationSlice = createSlice({
    name:"organization",
    initialState,
    reducers:{
        setOrganizations : (state,action) => {
            state.organizations = action.payload
        },
        setOrganization : (state,action) => {
            state.organization = action.payload
        },
        setStatus:(state,action)=>{
            state.status = action.payload
        },
        unsetStatus:(state,action)=>{
            state.status = action.payload
        },
        openDeleteModal : (state,action) => {
            state.isOpenDelete = true
            state.organizationId = action.payload
        },
        closeDeleteModal : (state) => {
            state.isOpenDelete = false
            state.organizationId = null
        },
    }

})

export const {openDeleteModal,closeDeleteModal,setOrganizations,setOrganization, setStatus,unsetStatus} = organizationSlice.actions

export default organizationSlice.reducer