import { Box,HStack,Text } from '@chakra-ui/react'

const Status = ({status,type}:{status:string,type:string}) => {
  return (
    <HStack h='100%' justifyContent='center' w='100%'>
        {status === '1' ? (
            <Box bg='#56E1C3' px={4}  rounded={6}>
                <Text fontWeight='semibold' color='white'>{type === 'questionnaire' ? 'Complété'  : 'Validée'}</Text>
            </Box>
        ) : (
            <Box bg='#EB5757' px={4}  rounded={6}>
                <Text fontWeight='semibold' color='white'>En attente</Text>
            </Box>
        )}
    </HStack>
  )
}

export default Status