import HttpClient from "src/services/httpclient"

export type RoleInfo = {
    Role:string,
    permissions:string[]
}


class Role {
    public static createRole = (RoleInfos:RoleInfo) => {
        return HttpClient.postWithLogin('/roles/store',RoleInfos)
    }
    public static updateRole = (id:string,RoleInfos:RoleInfo) => {
        return HttpClient.postWithLogin(`/roles/update/${id}`,RoleInfos)
    }
    public static getRoles = () => {
        return HttpClient.getWithLogin('/roles/list')
    }
    public static getPermissions = () => {
        return HttpClient.getWithLogin('/permission/list')
    }
    public static getRole = (id : string) => {
        return HttpClient.getWithLogin(`/roles/show/${id}`)
    }
    public static deleteRole = (id:string) => {
        return HttpClient.deleteWithLogin(`/roles/delete/${id}`)
    }
}

export default Role