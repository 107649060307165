import { Box } from '@chakra-ui/react'
import NewAdmForm from './components/NewAdmForm'

const NewAdmin = () => {
  return (
    <Box px={4} py={2} rounded={4} >
        <Box backgroundColor='white' p={8} boxShadow='md' >
          <NewAdmForm />
        </Box>
    </Box>
  )
}

export default NewAdmin