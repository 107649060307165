import { Navigate } from "react-router-dom"
import Root from "src/components/root/mdedecin/Root"
import ErrorPage from "src/pages/error/error-page"
import Dashboard from "src/pages/medecin/dashboard/Dashboard"
import Patients from "src/pages/medecin/patients/Patients"
import Profile from "src/pages/medecin/patients/profile/Profile"
import Settings from "src/pages/medecin/settings/Settings"

const refresh_token = localStorage.getItem("refresh_token")
const access_token = localStorage.getItem("access_token")

const isSignIn = refresh_token && access_token

const MedecinRoutes = {
    path: "/medecin",
    element: isSignIn ? <Root /> : <Navigate to='/auth/login' />,
    errorElement: <ErrorPage />,
    children:[
      {
        path:'/medecin/dashboard',
        element:<Dashboard />
      },
      {
        path:'/medecin/patients',
        element:<Patients />
      },
      {
        path:'/medecin/patients/:id',
        element:<Profile />
      },

      {
        path:'/medecin/settings',
        element:<Settings />
      },
        
    ]

  }

  export default MedecinRoutes