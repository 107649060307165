import { Box, Container, Flex, Img, Text } from "@chakra-ui/react"
import Contact from "./contact/Contact"
import FooterMenu from "./menu/FooterMenu"
import line from '../../../../assets/line.png'
import Wrapper from "./Wrapper"

const Footer = () => {
  return (
    <Box as='footer' py={10} bg={'#235EE8'} id="#contact">
        <Container maxW={{base:'90%',xl:'80%'}} w={{ lg: '1200px' }}>
            <Wrapper>
              <Contact />
              <Flex justifyContent={'center'} >
                  <Img src={line} w={{base:'100%',lg:'80%'}} mt={{base:8,lg:0}} mb={2} />
              </Flex>
              <FooterMenu />
              <Text fontFamily={'inter'} mt={8} fontSize={'18px'} fontWeight={500} color={'white'}>&copy; 2024 Focus Care. Copyright and rights reserved</Text>
            </Wrapper>
        </Container>
    </Box>
  )
}

export default Footer