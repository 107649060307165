import { Box, VStack } from '@chakra-ui/react'
import Categorie from './Categorie'

export const categories = [
  {
    id:0,
    categ:"Généralités"
  },
  {
    id:1,
    categ:"Concernant votre grossesse actuelle"
  },
  {
    id:2,
    categ:"Concernant votre santé en dehors de la grossesse"
  }
]

const Categories = () => {
  return (
    <Box display={{base:'none',lg:'block'}} flex={2} h='fit-content' position='sticky' top={0} left={0} >
        <Box p={4}>
          <VStack alignItems='flex-start' >
          {categories?.map((c,index)=>(
            <Categorie index={index} categorie={c} key={index} />
          ))}
          </VStack>
        </Box>
    </Box>
  )
}

export default Categories