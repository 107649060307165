import HttpClient from "src/services/httpclient"

export type QuestionInfo = {
    question :string,
    type :string,
    operator_flag_reponse? :string,
    condition_flag_reponse? : string,
    flag_reponse? : string,
    question_to? :string,
    category?:string,
    answers? : string[],
    type_flag? : any
}


class Question {
    public static createQuestion = (QuestionInfos:QuestionInfo) => {
        return HttpClient.postWithLogin('/question/store',QuestionInfos)
    }
    public static updateQuestion = (id:string,QuestionInfos:QuestionInfo) => {
        return HttpClient.postWithLogin(`/question/update/${id}`,QuestionInfos)
    }
    public static getQuestions = () => {
        return HttpClient.getWithLogin('/question/list')
    }
    public static getPatientQuestions = (id : string) => {
        return HttpClient.getWithLogin(`/${id}`)
    }
    public static getSeigeFemmeQuestions = (id : number) => {
        return HttpClient.getWithLogin(`/sage-femme/question/list?patient_id=${id}`)
    }
    public static getMedecinQuestions = (id : string) => {
        return HttpClient.getWithLogin(`/medecin/question/list?patient_id=${id}`)
    }
    public static getQuestion = (id : string) => {
        return HttpClient.getWithLogin(`/question/show/${id}`)
    }
    public static deleteQuestion = (id:string) => {
        return HttpClient.deleteWithLogin(`/question/delete/${id}`)
    }
    public static responsePatientQuestion = (body:any) => {
        return HttpClient.postWithLogin('/store-response',body)
    }
    public static responseSeigefemmeQuestion = (body:any) => {
        return HttpClient.postWithLogin('/sage-femme/question/store-response',body)
    }
    public static responseMedecinQuestion = (body:any) => {
        return HttpClient.postWithLogin('/medecin/question/store-response',body)
    }
    public static commenterQuestion = (body:any) => {
        return HttpClient.postWithLogin('/medecin/question/store-comments',body)
    }
    public static modefyMedecinQuestionsToPatientAndSagefemme = (body:any) => {
        return HttpClient.postWithLogin(`/medecin/question/update-response-patient`,body)
    }
}

export default Question