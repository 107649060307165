import { Button, HStack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useToast } from '@chakra-ui/react'
import  { useRef } from 'react'
import { closeDeleteModal } from 'src/redux/features/practitioner/practitionerSlice'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import { deletePractitioner } from 'src/view-models/Practitioner'

const DeleteModal = () => {
    const dispatch = useAppDispatch()
    const toast = useToast()
    const {isOpenDelete,practitionerId:doctorId} = useAppSelector((state)=>state.practitioner)
    const finalRef = useRef(null)

    const handleDelete = async () => {
        const response = await deletePractitioner(doctorId!,toast)
        if(response.status === true) {
          handleClose()
          window.location.reload()
        } 
      }

    const handleClose = () => {
        dispatch(closeDeleteModal())
    }

  return (
    <Modal finalFocusRef={finalRef} isOpen={isOpenDelete} onClose={handleClose}>
        <ModalOverlay 
        />
        <ModalContent>
          <ModalHeader>Suprimer Assistant</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Êtes-vous sûr de vouloir supprimer ceci Assistant?</Text>
          </ModalBody>

          <ModalFooter >
            <HStack gap={4} >
              <Button colorScheme='red' onClick={handleDelete}>Suprimer</Button>
              <Button colorScheme='blue' mr={3} onClick={handleClose}>
                Fermer
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
  )
}

export default DeleteModal