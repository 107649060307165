import { Box,Flex,Heading,Text } from "@chakra-ui/react"
import LoginForm from "src/pages/auth/login/components/LoginForm"

const Login = () => {
  return (
    <Flex minHeight='100vh' alignItems='center' justifyContent='center' >
      <Flex width={{base:'base',sm:'sm',md:'lg'}} flexDirection='column' alignItems='center' justifyContent='center' >
        <Heading fontWeight={700} >FOCUS CARE</Heading>
        <Box w='100%' p={4} my={4} className="login-shadow" >
          <Text fontSize={{base:16,sm:20}} mb={4} className="text-center" >Connectez-vous en utilisant votre email</Text>
          <LoginForm />
        </Box>
      </Flex>
    </Flex>
  )
}

export default Login