import { Box, Text, VStack } from "@chakra-ui/react"

type Props = {
    info1:string,
    info2:any
}

const BasicInfo = ({info1,info2} : Props) => {
  return (
    <Box>
        <Text fontWeight='bold' >{info1}</Text>
        {info1 === 'Réponses' && info2?.length > 0 ? (
          <VStack alignItems='start'>
              {info2?.map((i : any,index : number)=>(
              <Text  key={index} >&#x2022; {i?.answer}</Text>
            ))}
          </VStack>
        ) : 
          info1 === 'Réponses' && info2.length === 0 ? (
            <Text>Null</Text>
          ) : (
            (
              <Text>{info2 ? info2 : 'null'}</Text>
            )
          )
        }
    </Box>
  )
}

export default BasicInfo