import { ChevronDownIcon } from '@chakra-ui/icons'
import { HStack, Text } from '@chakra-ui/layout'
import { useState } from 'react'
import NavMenu from './NavMenu'
import { Avatar } from '@chakra-ui/react'
import { useAppSelector } from 'src/redux/hooks'

const ProfileNav = () => {
  const{profile:user} = useAppSelector((state)=>state.profile)
  const [isOpen,setIsOpen] = useState(false)

  const handleOpen = () => {
    setIsOpen((prev)=>!prev)
  }

  return (
    <HStack alignItems='center' className='group' position='relative'>
        <Avatar size='sm' bg='blue.300' color='white' name={`${user?.first_name} ${user?.last_name}`} />
        <Text fontWeight={600}>{user?.first_name?.charAt(0).toUpperCase()}{user?.first_name?.slice(1)} {user?.last_name?.charAt(0).toUpperCase()}{user?.last_name?.slice(1)}</Text>
        <ChevronDownIcon  cursor='pointer' fontSize={30} onClick={handleOpen} />
        {isOpen ? (
          <NavMenu onClose={handleOpen} user={user} />
        ) : null}
    </HStack>
  )
}

export default ProfileNav