import ActionTable from "./components/ActionTable";

export const colDefs = [
  { headerName:"Nom",
    field: "first_name",
  },
  { 
    headerName:"Prénom",
    field: "last_name",
  },
  { 
    headerName:"Téléphone",
    field: "phone"
   },
   { 
    headerName:"E-mail",
    field: "email"
   },
   {
    field:'Actions',
    cellRenderer:ActionTable,
    cellRendererParams: (params : any) => {
      const rowData = params.data;
      return {
        id: `${rowData.id}`,
        name:`${rowData.first_name}`
      };
    },
    filter:false,
  },
];