import { Box, Flex, HStack, Text } from '@chakra-ui/react'

const ConclusionCard = ({cpa}:{cpa:any}) => {
  return (
    <Box my={4}  className='flex flex-col gap-4' >
            <Flex alignItems='center' justifyContent='space-between' gap={6} flexWrap='wrap' >
                <HStack>
                    <Text fontSize={{base:'14',sm:'16'}} >Mode d'accouchement : </Text>
                    <Text fontSize={{base:'14',sm:'16'}} fontWeight='bold'  >{cpa?.mode_acouchement ? cpa?.mode_acouchement[0]?.answer : 'à renseigner'}</Text>
                </HStack>
            </Flex>

            <Flex alignItems='center' justifyContent='space-between' gap={6} flexWrap='wrap' >
                <HStack>
                    <Text fontSize={{base:'14',sm:'16'}}> Grossesse à risque : </Text>
                    <Text fontSize={{base:'14',sm:'16'}} fontWeight='bold' color={cpa?.grossess_risque_count === "Oui" || cpa?.grossess_risque_count === "oui" ? "red" : "green"} >{cpa?.grossess_risque_count}</Text>
                </HStack>
                <HStack>
                    <Text fontSize={{base:'14',sm:'16'}}>Score ASA : </Text>
                    <Text fontSize={{base:'14',sm:'16'}} fontWeight='bold' color={cpa?.score_asa_count  > 1 ? "red" : "green"} >{cpa?.asa_is_updated === 0 ? cpa?.score_asa_count > 1 ? '>1' : '=1' : cpa?.score_asa_count}</Text>
                </HStack>
                <HStack>
                    <Text fontSize={{base:'14',sm:'16'}} >Signe(s) de diathèse hémorragique : </Text>
                    <Text fontSize={{base:'14',sm:'16'}} fontWeight='bold' color={cpa?.risque_diahrse_count === "Oui" || cpa?.risque_diahrse_count === "oui" ? "red" : "green"} >{cpa?.risque_diahrse_count}</Text>
                </HStack>
            </Flex>

            <Flex alignItems='center' justifyContent='space-between' gap={6} flexWrap='wrap' >
                    <HStack>
                        <Text fontSize={{base:'14',sm:'16'}}>Allergies : </Text>
                        <Text fontSize={{base:'14',sm:'16'}} fontWeight='bold' >{cpa?.allergies ? cpa?.allergies : "Non"}</Text>
                    </HStack>
            </Flex>

            <Flex alignItems='center' justifyContent='space-between' gap={6} flexWrap='wrap' >
                    <HStack>
                        <Text fontSize={{base:'14',sm:'16'}}>Bilan Biologique : </Text>
                        {cpa?.billans_biologique?.length  > 0 ? (
                            <Flex flexWrap='wrap' gap={4} >
                                {cpa?.billans_biologique?.map((b:any,index:number) => (
                                    <Text key={index} fontSize={{base:'14',sm:'16'}} fontWeight='bold' >{b} {index < cpa?.billans_biologique?.length -1 && ","}</Text>
                                ))}
                            </Flex>
                        ) : (
                            <Text fontSize={{base:'14',sm:'16'}} fontWeight='bold'>Pas de bilan biologique recommandé</Text>
                        )}
                        
                    </HStack>
            </Flex>
        </Box>
  )
}

export default ConclusionCard