import { Button,FormControl, FormErrorMessage,FormLabel,Grid,HStack,Input,useToast } from "@chakra-ui/react"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"
import { useNavigate } from "react-router-dom"
import { updatePofile } from "src/view-models/Admin"
import { useAppDispatch } from "src/redux/hooks"

const formSchema = z.object({
    phone : z.string().min(1,{
        message:"Le téléphone est obligatoire",
    }),
    first_name : z.string().min(1,{
        message:"Le nom est obligatoire",
    }),
    last_name : z.string().min(1,{
        message:"Le prénom  est obligatoire",
    }),
    email: z.string()
    .refine((value) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value), {
      message: 'Adresse e-mail invalide',
    }),
  })
  
  type EditAdminInputs = {
    phone : string
    email : string
    first_name : string
    last_name: string
  };

const EditAdmin = ({setIsEdit,data}:any) => {
  const dispatch = useAppDispatch()
  const toast = useToast()
  const id = data?.id
  const { register,  handleSubmit,formState: { errors, isSubmitting}} = useForm<EditAdminInputs>({
    resolver: zodResolver(formSchema),
    defaultValues:{
      email:data?.email,
      first_name:data?.first_name,
      last_name:data?.last_name,
      phone:data?.phone,
    }
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    const theValues = {...values,active:1,roles:['admin']}
    const response : any = await updatePofile(id,theValues,toast,dispatch)
    if(response.status === true){
      setIsEdit(false)
    }else{
      return null
    }
  }

  return (
    <form  className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
      <Grid templateColumns={{base:'repeat(1,1fr)',md:'repeat(2,1fr)',lg:'repeat(3,1fr)'}} gap={4} >
            <FormControl isRequired isInvalid={!!errors.first_name}>
              <FormLabel>Nom</FormLabel>
              <Input
                boxShadow='sm'
                disabled={isSubmitting}
                rounded='5px'
                type='text'
                size='md'
                id='first_name'
                placeholder='first_name'
                {...register("first_name")}
              />
              <FormErrorMessage>
                {errors.first_name && errors.first_name.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!errors.last_name}>
              <FormLabel >Prénom </FormLabel>
              <Input
                boxShadow='sm'
                disabled={isSubmitting}
                rounded='5px'
                type='text'
                size='md'
                id='last_name'
                placeholder='last_name'
                {...register("last_name")}
              />
              <FormErrorMessage>
                {errors.last_name && errors.last_name.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!errors.phone}>
              <FormLabel>Téléphone</FormLabel>
              <Input
                boxShadow='sm'
                disabled={isSubmitting}
                rounded='5px'
                type='tel'
                size='md'
                id='phone'
                placeholder='Téléphone'
                {...register("phone")}
              />
              <FormErrorMessage>
                {errors.phone && errors.phone.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!errors.email}>
              <FormLabel>Email</FormLabel>
              <Input
                boxShadow='sm'
                disabled={isSubmitting}
                rounded='5px'
                type='email'
                size='md'
                id='email'
                placeholder='email'
                {...register("email")}
              />
              <FormErrorMessage>
                {errors.email && errors.email.message}
              </FormErrorMessage>
            </FormControl>
            </Grid>
            <HStack mt={10}>
          <Button
            type='submit'
            onClick={handleSubmit(onSubmit)}
            isLoading={isSubmitting}
            p='4'
            rounded='20px'
            mx='auto'    
            w='40%'
            colorScheme='blue'
            variant='solid'    
          >
            Modifier
            </Button>
        </HStack>                    
          </form>
  )
}

export default EditAdmin