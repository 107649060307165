import { Box, Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure } from "@chakra-ui/react"
import ConclusionForm from "../consultation/components/conclusion/ConclusionForm"
import { useEffect, useState } from "react"
import { getConclusionCPA } from "src/view-models/Patient"
import { useAppDispatch, useAppSelector } from "src/redux/hooks"
import { useParams } from "react-router-dom"
import { RiLoader2Fill } from "react-icons/ri"

const LoadingState = () => {
    return (
        <Box bg='white' rounded={10} boxShadow='sm' px={8} py={4}>
          <Text fontWeight='bold' fontSize={18} >Conclusion CPA</Text>
          <Flex w='100%' my={20}  justifyContent='center' alignItems='center' >
                <RiLoader2Fill size={30} className='animate-spin' />
          </Flex>
        </Box>
      )
}

const ErrorLoadingState = () => {
    return (
        <Box bg='white' rounded={10} boxShadow='sm' px={8} py={4}>
          <Text fontWeight='bold'  fontSize={18} >Conclusion CPA</Text>
          <Flex w='100%' my={20}  justifyContent='center' alignItems='center' >
                <Text>Rien à afficher!</Text>
          </Flex>
        </Box>
      )
}

const EditConclusion = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const dispatch = useAppDispatch()
    const {id} = useParams()
    const [isLoading,setIsLoading] = useState(true)
    const {conclusionCPA,isValidateCPA} = useAppSelector((state)=>state.question)

    useEffect(()=>{
        const fetchConclusionCPA = async () => {
          try {
             await getConclusionCPA(id!,dispatch)
          } catch (error) {
            
          }finally {
            setIsLoading(false)
          }
        }
        fetchConclusionCPA()
      },[])
    
    
      if(!isLoading && !conclusionCPA){
        
      }

  return (
    <Box px={4} pb={2}>
        <Flex justifyContent='flex-end' >
            <Button colorScheme="blue" onClick={onOpen}>Modifier</Button>
        </Flex>
        <Modal onClose={onClose} size={'full'} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Modifier la Conclusion CPA</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {isLoading ? (
                <LoadingState />
            ) : !isLoading && !conclusionCPA ? (
                <ErrorLoadingState />
            ) : (
                <Box pb={4}>
                  <ConclusionForm setIsEdit={onClose} cpa={conclusionCPA} />
                </Box>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default EditConclusion