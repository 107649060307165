import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { useMemo} from 'react';
import { Box } from '@chakra-ui/react';

type Props = {
  data:any,
  columns:any
}

const DataTable = ({data,columns}:Props) => {
  
  const defaultColDef = useMemo(() => ({
    resizable: true,
    sortable: true,
    filter: true,
    flex: 1,
  }),[])

  const autoSizeStrategy :any = {
    columnLimits: [
      {
        colId: 'Consultation',
        minWidth: 900,
      },
    ],
};


  return (
      <div className="ag-theme-quartz h-[27rem] w-full ">
        <AgGridReact
          defaultColDef={defaultColDef}
          autoSizeStrategy={autoSizeStrategy}
          gridOptions={{paginationPageSize:20,overlayLoadingTemplate:'Loading...'}}
          rowData={data}
          rowHeight={80}
          columnDefs={columns}
          pagination={true}
        />
    </div>
  );
}

export default DataTable