import {createSlice} from '@reduxjs/toolkit'


type StateType = {
    isOpenDelete : boolean
    adminId:null | string
    admin : null | any
    admins:any[]
}

const initialState : StateType = {
    isOpenDelete:false,
    adminId:null,
    admin:null,
    admins:[]
}


const adminSlice = createSlice({
    name:"admin",
    initialState,
    reducers:{
        setAdmins : (state,action) => {
            state.admins = action.payload
        },
        setAdmin : (state,action) => {
            state.admin = action.payload
        },
        openDeleteModal : (state,action) => {
            state.isOpenDelete = true
            state.adminId = action.payload
        },
        closeDeleteModal : (state) => {
            state.isOpenDelete = false
            state.adminId = null
        },
    }

})

export const {openDeleteModal,closeDeleteModal,setAdmins,setAdmin} = adminSlice.actions

export default adminSlice.reducer

