import HttpClient from "src/services/httpclient"
import { AdminInfo } from "./Admin"

export type ProfileInfo = {
    first_name : string
    last_name : string
    phone : string
    email : string
    password? : string
    c_password? :string
}

class Profile {
    public static getProfile = () => {
        return HttpClient.getWithLogin(`/profile`)
    }

    public static updatePassword = (role:string,passwordValues:AdminInfo) => {
        return HttpClient.postWithLogin(`/${role}/profile/edit_password`,passwordValues)
    }
}

export default Profile